export class EventDispatcher {
    // Create a dummy DOM element 
    private dummy = document.createTextNode('');

    // Create custom wrappers with nicer names
    public off = this.dummy.removeEventListener.bind(this.dummy);
    public on = this.dummy.addEventListener.bind(this.dummy);
    public trigger(eventName: string, data: any) {
        if (!eventName) return;
        const e = new CustomEvent(eventName, { 'detail': data });
        this.dummy.dispatchEvent(e);
    }
}


declare global {
    interface ObjectConstructor {
        resolve(path: string, obj: any): string;
    }
    var __webpack_public_path__: string;

    interface HTMLAudioElement {
        stop(): void;
    }

    interface HTMLVideoElement {
        stop(): void;
    }
}

Audio.prototype.stop = function (): void {
    this.pause();
    this.currentTime = 0;
}

HTMLVideoElement.prototype.stop = function (): void {
    this.pause();
    this.currentTime = 0;
}

let filterRecursive = function* (predicate: (any) => boolean, array: Array<any>, childrenPath: string, chilPredicate: (any) => boolean = null): Iterable<any> {
    if (predicate && Array.isArray(array)) {
        for (let i = 0; i < array.length; i++) {
            if (predicate(array[i])) {
                yield array[i];
            }

            if (childrenPath) {
                yield* filterRecursive(chilPredicate || predicate, array[i][childrenPath], childrenPath);
            }
        }
    }
}

Object.resolve = Object.resolve || function (path, obj) {
    return path.split('.').reduce(function (prev, curr) {
        return prev ? prev[curr] : undefined
    }, obj || self);
};

export {
    filterRecursive
}
