import { AssetFile } from 'Api/Contracts/Dtos';
import { FileTaskResult, WorkerTaskState } from 'Api/Dto/Drive';
import { DriveService } from 'Api/Services';
import { IPollingBehavior } from 'Framework/Services/PollingBehavior';
import { IPollingResult } from 'Framework/Services/PollingManager';
import { uniqBy } from 'lodash';

export class AssetItemStatePollingBehavior implements IPollingBehavior<AssetFile, FileTaskResult> {
    public constructor(
        driveService: DriveService,
        onPollingAsync?: (results: Array<IPollingResult<AssetFile, FileTaskResult>>) => Promise<void>,
        onEndedAsync?: (results: Array<IPollingResult<AssetFile, FileTaskResult>>) => Promise<void>
    ) {
        this.onEndedAsync = onEndedAsync;
        this._onPollingAsync = onPollingAsync;
        this._driveService = driveService;
    }

    public getItemId(item: AssetFile): string {
        return item.file.guid;
    }

    public getItemIdFromResult(result: FileTaskResult): string {
        return result.filePathGuid;
    }

    public pollingActionAsync(items: AssetFile[]): Promise<FileTaskResult[]> {
        const files = uniqBy(items.map(ai => ai.file), 'fileId');

        return this._driveService.getFilesProcessState(files);
    }

    public stopPollingItemWhen(result: IPollingResult<AssetFile, FileTaskResult>): boolean {
        return !this._isWorkerTaskPending(result.result);
    }

    public async onPollingAsync(results: Array<IPollingResult<AssetFile, FileTaskResult>>): Promise<void> {
        await this._onPollingAsync?.(results);

        results.forEach(r => {
            r.item.file.state = r.result;
        });
    }

    private _isWorkerTaskPending(state: FileTaskResult): boolean {
        return !state
            || state.state == WorkerTaskState.Created
            || state.state == WorkerTaskState.Running;
    }

    public readonly onEndedAsync?: (results: Array<IPollingResult<AssetFile, FileTaskResult>>) => Promise<void>;

    private readonly _onPollingAsync?: (results: Array<IPollingResult<AssetFile, FileTaskResult>>) => Promise<void>;
    private readonly _driveService: DriveService;
}
