export type Permission = DrivePermissions | UserPermissions | GroupPermissions | RolePermissions | PlatformPermissions | SubscriptionPermissions | ProjectPermissions | SettingPermissions | AnalyticPermissions | DomainNameSystemPermissions | SystemInformationPermissions | IdentityProviderPermissions | AuditLogPermissions;

export enum DrivePermissions {
    None = 0,
    CreateFolder = 1,
    EditFolderOrFile = 2,
    DeleteFolder = 4,
    ShareFolder = 8,
    MoveFolder = 16,
    DuplicateFolder = 32,
    UploadFile = 64,
    DownloadFile = 128,
    ViewFile = 256,
    DeleteFile = 512,
    ShareFile = 1024,
    DuplicateFile = 2048,
    MoveFile = 4096,
    HiddenFile = 8192,
    Allow = 16384,
    Deny = 32768,
    Absolute = 65536,

    Anonymous = None,
    Standard = None,
    Advanced = None,
    All = CreateFolder | EditFolderOrFile | DeleteFolder | ShareFolder | MoveFolder | DuplicateFolder | UploadFile | DownloadFile | ViewFile | DeleteFile | ShareFile | DuplicateFile | MoveFile | HiddenFile
}

export enum UserPermissions {
    None = 0,
    Create = 1,
    Edit = 2,
    Delete = 4,
    ChangeRole = 8,
    ChangeSubscription = 16,
    ChangeType = 32,
    Import = 64,
    Export = 128,
    List = 256,

    Anonymous = None,
    Standard = None,
    Advanced = None,
    All = Create | Edit | Delete | ChangeRole | ChangeSubscription | ChangeType | Import | Export | List
}

export enum GroupPermissions {
    None = 0,
    Create = 1,
    Edit = 2,
    Delete = 4,
    List = 8,

    Anonymous = None,
    Standard = None,
    Advanced = None,
    All = Create | Edit | Delete | List
}

export enum RolePermissions {
    None = 0,
    Create = 1,
    Edit = 2,
    Delete = 4,
    List = 8,

    Anonymous = None,
    Standard = None,
    Advanced = None,
    All = Create | Edit | Delete | List
}

export enum PlatformPermissions {
    None = 0,
    Create = 1,
    Edit = 2,
    Delete = 4,
    List = 8,

    Anonymous = None,
    Standard = None,
    Advanced = None,
    All = Create | Edit | Delete | List
}

export enum SubscriptionPermissions {
    None = 0,
    Create = 1,
    Edit = 2,
    Delete = 4,
    List = 8,

    Anonymous = None,
    Standard = None,
    Advanced = None,
    All = Create | Edit | Delete | List
}

export enum ProjectPermissions {
    None = 0,
    Create = 1 << 0,
    Edit  = 1 << 1,
    Delete = 1 << 2,
    Read  = 1 << 3,
    Publish = 1 << 4,
    Comment = 1 << 5,
    Copy = 1 << 6,
    List = 1 << 7,
    Standalone = 1 << 8,
    Contribute = 1 << 9,
    Import = 1 << 10,
    Export = 1 << 11,
    CreateTemplate = 1 << 12,
    EditTemplate = 1 << 13,
    DeleteTemplate = 1 << 14,
    ListTemplate = 1 << 15,
    ReadTemplate = 1 << 16,
    All = Create | Edit | Delete | Read | Publish | Comment | Copy | List | Standalone | Contribute | Import | Export | CreateTemplate | EditTemplate | DeleteTemplate | ListTemplate | ReadTemplate
}

export enum SettingPermissions {
    None = 0,
    Security = 1,
    Drive = 2,
    General = 4,
    Login = 8,
    Mail = 16,

    Anonymous = None,
    Standard = None,
    Advanced = None,
    All = Security | Drive | General | Login | Mail
}

export enum AnalyticPermissions {
    None = 0,
    ShowLineDataIn = 1,
    ShowLineDataOut = 2,
    ShowPieLogicalStorage = 4,
    ShowPiePhysicalStorage = 8,
    ShowLineLogicalFiles = 16,
    ShowLinePhysicalFiles = 32,
    ShowLineRequest = 64,
    ShowLineRequestError = 128,

    Anonymous = None,
    Standard = None,
    Advanced = None,
    All = ShowLineDataIn | ShowLineDataOut | ShowPieLogicalStorage | ShowPiePhysicalStorage | ShowLineLogicalFiles | ShowLinePhysicalFiles | ShowLineRequest | ShowLineRequestError
}

export enum DomainNameSystemPermissions {
    None = 0,
    Create = 1,
    Edit = 2,
    Delete = 4,
    List = 8,

    All = Create | Edit | Delete | List
}

export enum SystemInformationPermissions {
    None = 0,
    Create = 1,
    Edit = 2,
    Delete = 4,
    List = 8,

    Anonymous = None,
    Standard = None,
    Advanced = None,
    All = Create | Edit | Delete | List
}

export enum IdentityProviderPermissions {
    None = 0,
    Create = 1,
    List = 2,
    Edit = 4,
    Delete = 8,

    All = Create | List | Edit | Delete
}

export enum AuditLogPermissions {
    None = 0,
    List = 1
}

export enum TagPermissions {
    Create = 1 << 0,
    Update = 1 << 1,
    Delete = 1 << 2,
    List   = 1 << 3,

    All = (List << 1) - 1
}

export enum WorkerPermissions {
    None = 0,
    Create = 1,
    Edit = 2,
    Delete = 4,
    List = 8,
    RestartTask = 16,

    All = Create | Edit | Delete | List | RestartTask
}

export enum IconPermissions {
    None = 0,
    List = 1 << 0,
    Import = 1 << 1,
    Update = 1 << 2,
    Delete = 1 << 3,

    All = List | Import | Update | Delete
}

export enum SmartObjectPermissions {
    None = 0,
    List = 1 << 0,
    CreateCategory = 1 << 1,
    EditCategory = 1 << 2,
    DeleteCategory = 1 << 3,
    MoveCategory = 1 << 4,
    CreateSmartObject = 1 << 5,
    EditSmartObject = 1 << 6,
    DeleteSmartObject = 1 << 7,
    MoveSmartObject = 1 << 8,
    ReadSmartObject = 1 << 9,

    All = (ReadSmartObject << 1) - 1
}

