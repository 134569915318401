import Vue from 'vue'
import Vuetify, {
    VApp,
    VMain,
    VTreeview,
    VTabs,
    VTab,
    VTabsItems,
    VTabItem,
    VTabsSlider,
    VBtn,
    VIcon,
    VProgressLinear,
    VSlider,
    VMenu,
    VExpansionPanel,
    VTextField,
    VDataIterator,
    VCheckbox,
    VDataTable, VEditDialog, VTableOverflow,
    VPagination,
    VBreadcrumbs,
    VBreadcrumbsItem,
    VList,
    VListItem,
    VListItemGroup,
    VListItemTitle,
    VListItemContent,
    VListItemAction,
    VNavigationDrawer,
    VAppBar,
    VAppBarNavIcon,
    VListGroup,
    VListItemAvatar,
    VForm,
    VContainer,
    VRow,
    VCol,
    VDivider,
    VAlert,
    VDialog,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
    VToolbar,
    VToolbarTitle,
    VToolbarItems,
    VSwitch,
    VRating,
    VImg,
    VAvatar,
    VSelect,
    VTooltip,
    VSnackbar,
    VProgressCircular,
    VSpacer,
    VAutocomplete,
    VExpandTransition,
    VOverflowBtn,
    VOverlay,
    VSystemBar,
    VListItemIcon,
    VChip,
    VSimpleTable,
    VColorPicker,
    VSkeletonLoader,
    VBtnToggle,
    VCardSubtitle,
    VListItemSubtitle,
    VRadioGroup,
    VRadio,
    VExpansionPanels,
    VExpansionPanelHeader,
    VExpansionPanelContent,
    VFileInput,
    VTimeline,
    VTimelineItem,
    VTextarea,
    VCarousel,
    VCarouselItem,
    VFadeTransition,
    VSubheader,
    VHover
} from 'vuetify/lib';

Vue.use(Vuetify, {
    components: {
        VApp,
        VMain,
        VTreeview,
        VTabs,
        VTab,
        VTabsItems,
        VTabItem,
        VTabsSlider,
        VBtn,
        VIcon,
        VProgressLinear,
        VSlider,
        VMenu,
        VExpansionPanel,
        VTextField,
        VCheckbox,
        VDataIterator,
        VDataTable, VEditDialog, VTableOverflow,
        VPagination,
        VBreadcrumbs,
        VBreadcrumbsItem,
        VList,
        VListItem,
        VListItemGroup,
        VListItemTitle,
        VListItemContent,
        VListItemAction,
        VNavigationDrawer,
        VAppBar,
        VAppBarNavIcon,
        VListGroup,
        VListItemAvatar,
        VForm,
        VContainer,
        VRow,
        VCol,
        VDivider,
        VAlert,
        VDialog,
        VCard,
        VCardTitle,
        VCardText,
        VCardActions,
        VToolbar,
        VToolbarTitle,
        VToolbarItems,
        VSwitch,
        VRating,
        VHover,
        VImg,
        VAvatar,
        VSelect,
        VTooltip,
        VSnackbar,
        VProgressCircular,
        VSpacer,
        VAutocomplete,
        VExpandTransition,
        VOverflowBtn,
        VOverlay,
        VSystemBar,
        VListItemIcon,
        VChip,
        VSimpleTable,
        VColorPicker,
        VSkeletonLoader,
        VBtnToggle,
        VCardSubtitle,
        VListItemSubtitle,
        VRadioGroup,
        VRadio,
        VExpansionPanels,
        VExpansionPanelHeader,
        VExpansionPanelContent,
        VFileInput,
        VTimeline,
        VTimelineItem,
        VTextarea,
        VCarousel,
        VCarouselItem,
        VFadeTransition,
        VSubheader
    }
});


export default new Vuetify({
    lang: {
        locales: {},
        current: 'NotLoaded',
        t: function (key: string, ...params: Array<string | number>): string {
            if (this.current == 'NotLoaded') {
                return '';
            }

            if (!key.startsWith('$vuetify.')) {
                return (this as any).replace(key, params);
            }

            return (this as any).defaultTranslator(key, ...params)
        }
    },
    icons: {
        iconfont: 'fa',
        values: {
            search: 'fa-light fa-magnifying-glass',
            'expand': 'fa-light fa-sm fa-fw fa-chevron-down',
            waypoint: 'M9 9V15H15V9H9M11 11H13V13H11V11Z',
            waypointEdge: 'M15,3V7.59L7.59,15H3V21H9V16.42L16.42,9H21V3M17,5H19V7H17M5,17H7V19H5',
            waypointEdgeNoTarget: 'M15,7.59 L7.59,15H3V21H9V16.42L16.42,9M5,17H7V19H5'
        },
    },
    theme: {
        options: {
            customProperties: true,
        },
        dark: true,
        themes: {
            light: {
                primary: '#f18800',
                secondary: '#424242',
                accent: '#f18800',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                'text-primary': '#000000'
            },
            dark: {
                primary: '#f18800',
                secondary: '#424242',
                accent: '#f18800',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                'text-primary': '#FFFFFF'
            }
        },
    },
});
