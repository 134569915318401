export class UrlHelper {
    public static getUrlParameter(parameterName: string, url: string = window.location.href): string {
        let urlParameters = new URL(url).searchParams;

        return urlParameters.get(parameterName) 
            ?? urlParameters.get(parameterName.toLowerCase());
    }

    public static addUrlParameter(urlString: string, parameterName: string, parameterValue: string, addIfEmpty: boolean = false): string {
        let url = new URL(urlString);

        if (addIfEmpty || (parameterValue && parameterValue.length > 0)) {
            url.searchParams.append(parameterName, parameterValue);
        }

        return url.href;
    }

    public static addUrlParameters(url: URL, addIfEmpty: boolean = false, ...parameters: Record<string, string>[]): URL {
        if (parameters) {
            for (let param of parameters) {
                if (addIfEmpty || (param.Value && param.Value.length > 0)) {
                    url.searchParams.append(param.Key, param.Value);
                }
            }
        }

        return url;
    }

    public static getUrlHashParameter(parameterName: string): any {
        parameterName = parameterName.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        let regex = new RegExp('[\\?&]' + parameterName + '=([^&#]*)');
        let results = regex.exec(location.hash);

        return results === null
            ? null
            : decodeURIComponent(results[1]);
    };

    public static getUrlHashParameters(): { [key: string]: string } {
        let regex = /[#&](.*?)=([^&#]*)/gm;
        let m: RegExpExecArray;
        let results = {};

        while ((m = regex.exec(location.hash)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }

            results[m[1]] = decodeURIComponent(m[2]);
        }

        return results;
    }

    public static isValidUrl(url: string): boolean {
        try {
            const _ = new URL(url);

            return true;
        } 
        catch (error) {
            return false;
        }
    }

    public static isLocalUrl(url: string): boolean {
        try {
            const urlObj = new URL(url, window.location.origin);
            return urlObj.origin === window.location.origin;
        }
        catch (error) {
            return false;
        }
    }
}
