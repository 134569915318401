import { HorizontalAlignment, Orientation, VerticalAlignment } from 'Framework/Components/Panel.Commons';
import { Component, Prop, Vue } from 'Framework/Components/UIElement';

@Component({
    name: 'x-wrap-panel'
})
export default class WrapPanel extends Vue {
    @Prop({ type: Array, required: false, default: null })
    public itemSource: Array<any>;
    @Prop({ type: Number, required: false, default: HorizontalAlignment.Left })
    public horizontalContentAlignment: HorizontalAlignment;
    @Prop({ type: Number, required: false, default: VerticalAlignment.Top })
    public verticalContentAlignment: VerticalAlignment;
    @Prop({ type: Number, required: false, default: Orientation.Horizontal })
    public orientation: Orientation;

    public get computedStyle(): object {
        let verticalContentAlignmentValue = 'flex-start';
        let horizontalContentAlignmentValue = 'flex-start';
        let orientationValue = 'row';

        if (this.verticalContentAlignment == VerticalAlignment.Bottom) {
            verticalContentAlignmentValue = 'flex-end';
        } else if (this.verticalContentAlignment == VerticalAlignment.Center) {
            verticalContentAlignmentValue = 'center';
        } else if (this.verticalContentAlignment == VerticalAlignment.Stretch) {
            verticalContentAlignmentValue = 'stretch';
        }

        if (this.horizontalContentAlignment == HorizontalAlignment.Right) {
            horizontalContentAlignmentValue = 'flex-end';
        } else if (this.horizontalContentAlignment == HorizontalAlignment.Center) {
            horizontalContentAlignmentValue = 'center';
        } else if (this.horizontalContentAlignment == HorizontalAlignment.Stretch) {
            horizontalContentAlignmentValue = 'stretch';
        }

        if (this.orientation == Orientation.Vertical) {
            orientationValue = 'column';
        }

        return {
            'align-content': verticalContentAlignmentValue,
            'justify-content': horizontalContentAlignmentValue,
            'flex-direction': orientationValue
        };
    }
}
