export class Transform {
    public constructor(
        public rotation: { x: number, y: number, z: number, w: number },
        public translation: { x: number, y: number, z: number }
    ) {}
}

export class PointCloud {
    public constructor(
        public global: { x: number, y: number, z: number },
        public transform: Transform
    ) {}
}

export interface IScanPosition {
    tx: number;
    ty: number;
    tz: number;
}

export interface IScanOrientation {
    rx: number;
    ry: number;
    rz: number;
    theta: number;
}

export class ScanInfo {
    private constructor(
            rows: number,
            columns: number,
            scanPosition: IScanPosition,
            scanOrientation: IScanOrientation
        ) {
        this.rows = rows;
        this.columns = columns;
        this.totalPoints = this.rows * this.columns;
        this.scanPosition = scanPosition;
        this.scanOrientation = scanOrientation;

        this.panoramaWidth = this.columns;
        this.panoramaHeight = this.panoramaWidth / 2;

        this.widthRatio = (this.panoramaWidth - 1) / (Math.PI * 2);
        this.heightRatio = (this.panoramaHeight - 1) / Math.PI;
    }

    public static createfromJson(jsonValue: JSON): ScanInfo {
        const si = jsonValue as unknown as ScanInfo;

        return new ScanInfo(
            si.rows,
            si.columns,
            si.scanPosition,
            si.scanOrientation
        );
    }

    public readonly rows: number;
    public readonly columns: number;
    public readonly totalPoints: number;

    public readonly panoramaWidth: number;
    public readonly panoramaHeight: number;

    public readonly widthRatio: number;
    public readonly heightRatio: number;

    public readonly scanPosition: IScanPosition;
    public readonly scanOrientation: IScanOrientation;
}

export class BinaryHeader {
    public constructor(
        public version: number,
        public sizeOfHeader: number,
        public scanInfo: ScanInfo
    ) {}

    public get headerOffset(): number {
        return BinaryHeader.fileVersionByteSize
            + BinaryHeader.sizeOfHeaderByteSize
            + this.sizeOfHeader;
    }

    public static readonly fileVersionByteSize: number = 4
    public static readonly sizeOfHeaderByteSize: number = 4;
}