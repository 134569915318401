import { UriService } from 'Api/Services';
import { IReactive, IUriServiceReactive } from 'App/Services/UriServices/Core';
import { injectable } from 'inversify';
import { Vue } from 'vue-property-decorator';

/** Add support of Promise to vue binding */
@injectable()
export class UriServiceReactive extends UriService implements IUriServiceReactive {
    public getAbsoluteUriReactive(relativeUri: string, parameters?: Record<string, string>): IReactive {
        if (!this.reactiveUri[relativeUri]) {
            this.reactiveUri[relativeUri] = Vue.observable({
                result: null,
                isLoading: true
            });
        }

        if (this.reactiveUri[relativeUri].result == null) {
            const _ = this.getAbsoluteUriAsync(relativeUri, parameters)
                .then(r => {
                    this.reactiveUri[relativeUri].result = r;
                    this.reactiveUri[relativeUri].isLoading = false;
                });
        }

        return this.reactiveUri[relativeUri];
    }

    protected reactiveUri: Record<string, IReactive> = {};
}
