export const injectTypes = {
    IDialogService: Symbol.for('IDialogService'),
    IComponent: Symbol.for('IComponent'),
    IVisonUriService: Symbol.for('IVisonUriService'),
    IPlayerContext: Symbol.for('IPlayerContext'),
    IIconProvider: Symbol.for('IIconProvider'),
    ISmartObjectService: Symbol.for('ISmartObjectService'),
    IResponseHandler: Symbol.for('IResponseHandler'),
    TokenValidatorFactory: Symbol.for('TokenValidatorFactory'),
    IIconService: Symbol.for('IIconService'),
    IJsonSchemaService: Symbol.for('IJsonSchemaService'),
};
