import { Matrix, Vector3 } from '@babylonjs/core/Maths/math.vector';
import { BinaryHeader, PointCloud, ScanInfo, Transform } from 'Api/Dto/PointCloud';

export class BinaryScanReader {
    public constructor(buffer: ArrayBuffer) {
        this._buffer = new DataView(buffer);
        this._header = this._readHeader();
    }

    public readValueAt(phi: number, theta: number): PointCloud {
        //compute pixel position from spherical coordinates
        const row: number = Math.round((Math.PI / 2 - phi) * this._header.scanInfo.heightRatio);
        const col: number = Math.round((Math.PI - theta) * this._header.scanInfo.widthRatio);

        //compute offset position in binary buffer
        const offset: number = (row * this._header.scanInfo.columns + col) * BinaryScanReader._sizeOfInt32;
        this._bufferPosition = this._header.headerOffset + offset;

        let radius = this._readInt32();

        if (radius == 0) {
            return null;
        }

        //convert micrometers to meters
        radius /= 1_000_000;

        //convert spherical to cartesian
        let localPoint: Vector3 = new Vector3(
            radius * Math.cos(phi) * Math.cos(theta),
            radius * Math.cos(phi) * Math.sin(theta),
            radius * Math.sin(phi)
        );

        let axis: Vector3 = new Vector3(
            this._header.scanInfo.scanOrientation.rx,
            this._header.scanInfo.scanOrientation.ry,
            this._header.scanInfo.scanOrientation.rz
        );

        //apply transformations
        let globalRotation = Matrix.RotationAxis(axis, this._header.scanInfo.scanOrientation.theta);

        let globalTranslation = Matrix.Translation(
            this._header.scanInfo.scanPosition.tx,
            this._header.scanInfo.scanPosition.ty,
            this._header.scanInfo.scanPosition.tz
        );

        localPoint = Vector3.TransformCoordinates(localPoint, globalRotation);
        localPoint = Vector3.TransformCoordinates(localPoint, globalTranslation);

        return new PointCloud(
            {
                x: localPoint.x,
                y: localPoint.y,
                z: localPoint.z
            },
            new Transform(
                {
                    x: axis.x,
                    y: axis.y,
                    z: axis.z,
                    w: this._header.scanInfo.scanOrientation.theta
                },
                {
                    x: this._header.scanInfo.scanPosition.tx,
                    y: this._header.scanInfo.scanPosition.ty,
                    z: this._header.scanInfo.scanPosition.tz
                }
            )
        );
    }

    private _readHeader(): BinaryHeader {
        const fileVersion: number = this._readInt32();
        const sizeOfHeader: number = this._readInt32();
        const rawHeader: Int8Array = this._readBytes(sizeOfHeader);
        const jsonHeader: JSON = this._deserializeAsJson(rawHeader);

        const header: ScanInfo = ScanInfo.createfromJson(jsonHeader);

        return new BinaryHeader(
            fileVersion,
            sizeOfHeader,
            header
        );
    }

    private _readInt32(): number {
        let value = this._buffer.getInt32(this._bufferPosition, true);
        this._bufferPosition += BinaryScanReader._sizeOfInt32;

        return value;
    }

    private _readBytes(length: number): Int8Array {
        let endOfPosition = this._bufferPosition + length;

        let bytes = new Int8Array(this._buffer.buffer.slice(this._bufferPosition, endOfPosition));
        this._bufferPosition = endOfPosition;

        return bytes
    }

    private _convertBytesToString(bytes: Int8Array): string {
        return String.fromCharCode(...bytes);
    }

    private _deserializeAsJson(rawJson: Int8Array): JSON {
        const rawJsonStringified: string = this._convertBytesToString(rawJson);

        const toCamelCasePropertyName = (key: string, value: any) => {
            if (value && typeof value === 'object')
                for (let k in value) {
                    if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
                        value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
                        delete value[k];
                    }
                }

            return value;
        };

        return JSON.parse(rawJsonStringified, toCamelCasePropertyName);
    }

    private _bufferPosition: number = 0;

    private static readonly _sizeOfInt32: number = 4;
    private readonly _header: BinaryHeader;
    private readonly _buffer: DataView;
}