import { IQueryResult, IQueryResultBase, QueryException } from 'Api/Dto/QueryResult';
import HttpClient from 'Api/HttpClient';
import { inject, injectable } from 'inversify';

@injectable()
export class FileUploader {
    constructor(@inject(HttpClient) httpClient: HttpClient) {
        this._httpClient = httpClient;

        this.files = [];
    }

    public async uploadFilesAsync<T>(onUploadedFileAsync?: (chunkedFile: ChunkedFile) => Promise<T>): Promise<Array<T>> {
        this.isCanceled = false;
        let filesToUpload = this.files.filter(f => !f.hasError);

        let results = new Array<T>();

        for (let file of filesToUpload) {
            await this.uploadFileAsync(file);

            if (onUploadedFileAsync && !file.hasError && !file.isCanceled) {
                const result = await onUploadedFileAsync(file);
                
                results.push(result);
            }
        }

        return results;
    }

    public cancelUpload(): void {
        this.isCanceled = true;
        console.log('file uploader iscancelled');
        console.log(this.isCanceled);
    }

    public addFile(file: File): ChunkedFile {
        let chunkedFile = new ChunkedFile(file);
        this.files.push(chunkedFile);

        return chunkedFile;
    }

    public removeFile(file: ChunkedFile): void {
        let index = this.files.findIndex(cf => cf.fileToBeUploaded == file.fileToBeUploaded);

        if (index >= 0) {
            this.files.splice(index, 1);
        }
    }

    public clear(): void {
        this.files = [];
    }

    public async clearServerCacheAsync(guids: Array<string>): Promise<void> {
        const response = await this._httpClient.postFormDataAsync('/asset/clearcache', { fileGuids: guids }, null, true);

        if (response.status != 204) {
            const jsonResponse = await response.json() as IQueryResultBase;

            throw new QueryException(jsonResponse.error);
        }
    }

    private async uploadFileAsync(file: ChunkedFile): Promise<ChunkedFile> {
        while (!file.uploadCompleted && !file.isCanceled && !file.hasError) {
            await this.uploadChunckAsync(file);
        }
        return file;
    }

    private async uploadChunckAsync(file: ChunkedFile): Promise<ChunkedFile> {
        try {
            let status: string = null;
            if (this.isCanceled) {
                status = 'abort';
                file.status = 'abort';
            }

            let response = await this.uploadChunkInternalAsync(file.guid, file.currentChunk, file.fileChunck, status);

            if (response.status == 'abort') {
                return file;
            }

            file.guid = response.guid;
            file.currentChunk++;
            file.retryCount = 0;

            return file;
        }
        catch (ex) {
            if (ex instanceof QueryException) {
                if (file.status === 'abort') {
                    file.status = 'Aborted';
                }
                else {
                    file.status = ex.message;
                    file.hasError = true;
                }

                return file;
            }
            else {
                if (file.retryCount < this.maxRetries) {
                    file.status = 'Resuming upload';
                    ++file.retryCount;
                    await FileUploader.wait(this.retryAfterSeconds * 1000);

                    return await this.uploadChunckAsync(file);
                }
                else {
                    file.status = 'Upload timed out.';
                    file.hasError = true;

                    throw ex;
                }
            }
        }
    }

    private async uploadChunkInternalAsync(fileGuid: string, chunkIndex: number, chunk: Blob, status?: string): Promise<IFileChunk> {
        const url = '/asset/uploadchunk';
        const data = {
            fileChunk: chunk,
            chunkIndex: chunkIndex,
            fileGuid: fileGuid,
            status: status
        };

        let response = await this._httpClient.postFormDataAsync(url, data);
        let jsonResponse = await response.json();

        if (response.status == 400) {
            let queryError = jsonResponse as IQueryResultBase;
            throw new QueryException(queryError.error);
        }

        return (jsonResponse as IQueryResult<IFileChunk>).result;
    }

    protected _httpClient: HttpClient;
    private static wait = ms => new Promise(r => setTimeout(r, ms));
    private isCanceled: boolean;
    private files: Array<ChunkedFile>;
    private maxRetries = 3;
    private retryAfterSeconds = 3;
    static blockLength = 262144;//256Ko
}

export class ChunkedFile {
    private static _uniqueId = 0;

    public constructor(file: File) {
        this.uniqueId = ++ChunkedFile._uniqueId;
        this._fileToBeUploaded = file;
        this._name = file.name;
        this._size = file.size;
        this._guid = '00000000-0000-0000-0000-000000000000';
        this._numberOfBlocks = Math.ceil(this._size / FileUploader.blockLength);
        this._currentChunk = 0;
        this._status = '';
        this._retryCount = 0;
        this._hasError = false;
    }

    get hasError(): boolean {
        return this._hasError;
    }
    set hasError(value: boolean) {
        this._hasError = value;
        if (this.onHasErrorChanged) {
            this.onHasErrorChanged(this);
        }
    }

    get fileToBeUploaded(): File {
        return this._fileToBeUploaded;
    }

    get name(): string {
        return this._name;
    }

    get size(): number {
        return this._size;
    }

    get guid(): string {
        return this._guid;
    }
    set guid(value: string) {
        this._guid = value;
    }

    get numberOfBlocks(): number {
        return this._numberOfBlocks;
    }
    get isCanceled(): boolean {
        return this.status === 'Aborted' || this.status === 'abort' || this.status === 'Upload timed out.' || this.status === 'Resuming Upload';
    }

    get fileChunck(): Blob {
        return this._fileToBeUploaded.slice(this.start, this.end);
    }

    get currentChunk(): number {
        return this._currentChunk;
    }
    set currentChunk(value: number) {
        this._currentChunk = value;

        if (this.onProgressChanged) {
            this.onProgressChanged(this);
        }
    }
    get uploadCompleted(): boolean {
        return this._currentChunk == this._numberOfBlocks;
    }

    get progress(): number {
        const progress = this._currentChunk / this._numberOfBlocks * 100;
        if (progress < 0) {
            return 0;
        }
        else if (progress <= 100) {
            return progress;
        }
        else if (progress > 100) {
            return 100;
        }
        return 0;
    }

    get status(): string {
        return this._status;
    }
    set status(value: string) {
        this._status = value;
    }

    get start(): number {
        return (this._currentChunk) * FileUploader.blockLength;
    }

    get end(): number {
        return Math.min((this._currentChunk + 1) * FileUploader.blockLength, this._size);
    }

    get retryCount(): number {
        return this._retryCount;
    }
    set retryCount(value: number) {
        this._retryCount = value;
    }

    public onProgressChanged: (e: any) => void;
    public onHasErrorChanged: (e: any) => void;

    public errors: Array<string> = [];

    public uniqueId: number;
    private _hasError: boolean;
    private _fileToBeUploaded: File;
    private _name: string;
    private _size: number;
    private _guid: any;
    private _numberOfBlocks: number;
    private _currentChunk: number;
    private _status: string;
    private _retryCount: number;

}

export interface IFileChunk {
    status: string;
    chunkIndex: number;
    guid: string;
}
