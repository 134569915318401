import { HorizontalAlignment, VerticalAlignment, PropSyncWithInternal, editorBrowsable, Serializable, JsonProperty } from 'Framework/Components/Panel.Commons';
import { Component, Vue } from 'vue-property-decorator';

@Serializable()
@Component({
    name: 'GridItem'
})
export default class GridItem extends Vue {
    public gridItemStyle(): any {
        //if (this.$parent instanceof Grid) {
        //Impossible d'inclure Grid pour faire un instanceof
        //Sinon référence circulaire entre Grid et UIElment
        if (this.$parent.$options.name == 'x-grid') {
            let rowEnd = this._row + this._rowSpan;
            let columnEnd = this._column + this._columnSpan;

            let result = {
                'grid-row': `${this._row}`,
                'grid-row-span': `${rowEnd - this._row}`,
                'grid-column': `${this._column}`,
                'grid-column-span': `${columnEnd - this._column}`,
                'grid-area': `${this._row} / ${this._column} / ${rowEnd} / ${columnEnd}`
            };

            result['justify-self'] = GridItem._horizontalAlignmentToHtmlGrid(this._horizontalAlignment);
            result['align-self'] = GridItem._verticalAlignmentToHtmlGrid(this._verticalAlignment);

            if (this._horizontalAlignment == HorizontalAlignment.Stretch) {
                result['width'] = 'unset';
            }
            else {
                result['max-width'] = '100%';
            }

            if (this._verticalAlignment == VerticalAlignment.Stretch) {
                result['height'] = 'unset';
            }
            else {
                result['max-height'] = '100%';
            }

            return result;
        }
        else {
            return {};
        }
    }

    private static _horizontalAlignmentToHtmlGrid(horizontalAlignment: HorizontalAlignment): string {
        if (horizontalAlignment == HorizontalAlignment.Stretch) {
            return 'stretch';
        } 
        else if (horizontalAlignment == HorizontalAlignment.Left) {
            return 'self-start';
        } 
        else if (horizontalAlignment == HorizontalAlignment.Right) {
            return 'self-end';
        } 
        else if (horizontalAlignment == HorizontalAlignment.Center) {
            return 'center';
        }

        throw new TypeError('horizontalAlignment invalid');
    }

    private static _verticalAlignmentToHtmlGrid(verticalAlignment: VerticalAlignment): string {
        if (verticalAlignment == VerticalAlignment.Stretch) {
            return 'stretch';
        } 
        else if (verticalAlignment == VerticalAlignment.Top) {
            return 'self-start';
        } 
        else if (verticalAlignment == VerticalAlignment.Bottom) {
            return 'self-end';
        } 
        else if (verticalAlignment == VerticalAlignment.Center) {
            return 'center';
        }

        throw new TypeError('verticalAlignment invalid');
    }

    @JsonProperty({ className: 'GridItem', name: 'row', type: Number })
    @editorBrowsable({ groupName: 'Layout', template: 'Number'})
    @PropSyncWithInternal('row', { type: Number, required: false, default: 1 })
    public _row: number;

    @JsonProperty({ className: 'GridItem', name: 'column', type: Number })
    @editorBrowsable({ groupName: 'Layout', template: 'Number'})
    @PropSyncWithInternal('column', { type: Number, required: false, default: 1 })
    public _column: number;

    @JsonProperty({ className: 'GridItem', name: 'rowSpan', type: Number })
    @editorBrowsable({ groupName: 'Layout', template: 'Number'})
    @PropSyncWithInternal('rowSpan', { type: Number, required: false, default: 1 })
    public _rowSpan: number;

    @JsonProperty({ className: 'GridItem', name: 'columnSpan', type: Number })
    @editorBrowsable({ groupName: 'Layout', template: 'Number'})
    @PropSyncWithInternal('columnSpan', { type: Number, required: false, default: 1 })
    public _columnSpan: number;

    @JsonProperty({ className: 'GridItem', name: 'horizontalAlignment', type: Number })
    @editorBrowsable({ groupName: 'Layout', template: 'HorizontalAlignment'})
    @PropSyncWithInternal('horizontalAlignment', { type: Number, required: false, default: HorizontalAlignment.Stretch })
    public _horizontalAlignment: HorizontalAlignment;

    @JsonProperty({ className: 'GridItem', name: 'verticalAlignment', type: Number })
    @editorBrowsable({ groupName: 'Layout', template: 'VerticalAlignment'})
    @PropSyncWithInternal('verticalAlignment', { type: Number, required: false, default: VerticalAlignment.Stretch })
    public _verticalAlignment: VerticalAlignment;


    public get _gridItemMixin(): boolean {
        return true;
    }
}
