export class SearchFilesViewModel {
    public constructor(
        currentDirectoryGuid: string,
        token: string,
        searchText: string,
        filter: string,
        maxPageSize: number
    ) {
        this.currentDirectoryGuid = currentDirectoryGuid;
        this.token = token;
        this.searchText = searchText;
        this.filter = filter;
        this.maxPageSize = maxPageSize;
    }
    public currentDirectoryGuid: string;
    public token: string;
    public searchText: string;
    public filter: string;
    public maxPageSize: number;
}
