import { AppContext } from 'Api/AppContext';
import HttpClient from 'Api/HttpClient';
import { Routes } from 'Api/Routes';
import { inject, injectable } from 'inversify';
import { IMailProviderDetails, IMailSettings, MailSettingsValidationResult } from 'Api/Dto/Admin/MailSettings';
import { MailSettingsViewModel } from 'Api/Dto/Admin/ViewModels/MailSettings/MailSettingsViewModel';
import { IQueryResultBase, QueryException } from 'Api/Dto/QueryResult';

@injectable()
export class SettingService {
    constructor(
        @inject(AppContext) appContext: AppContext,
        @inject(HttpClient) httpClient: HttpClient
    ) {
        this._appContext = appContext;
        this._httpClient = httpClient;
    }

    public async getMailProvidersAsync(): Promise<Array<IMailProviderDetails>> {
        let response = await this._httpClient.getAsync(Routes.Api.List.MailProviders);
        let jsonResponse = await response.json();

        if (response.status == 400) {
            let queryError = jsonResponse as IQueryResultBase;
            throw new QueryException(queryError.error);
        }

        return jsonResponse.result as Array<IMailProviderDetails>;
    }

    public async getCurrentMailSettingsAsync(): Promise<IMailSettings> {
        let response = await this._httpClient.getAsync(Routes.Api.Settings.Mail.Base);
        let jsonResponse = await response.json();

        if (response.status == 400) {
            let queryError = jsonResponse as IQueryResultBase;
            throw new QueryException(queryError.error);
        }

        return jsonResponse.result as IMailSettings;
    }

    public async updateMailSettingsAsync(mailSettings: MailSettingsViewModel): Promise<void> {
        let response = await this._httpClient.postFormDataAsync(Routes.Api.Settings.Mail.Base, mailSettings);

        if (response.status == 400) {
            let queryError = await response.json() as IQueryResultBase;
            throw new QueryException(queryError.error);
        }
    }

    public async testMailSettingsAsync(mailSettings: MailSettingsViewModel): Promise<MailSettingsValidationResult> {
        let response = await this._httpClient.postFormDataAsync(Routes.Api.Settings.Mail.Test, mailSettings);
        let jsonResponse = await response.json();

        if (response.status == 400) {
            let queryError = jsonResponse as IQueryResultBase;
            throw new QueryException(queryError.error);
        }

        return jsonResponse.result as MailSettingsValidationResult;
    }

    protected readonly _appContext: AppContext;
    protected readonly _httpClient: HttpClient;
}
