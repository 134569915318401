import { AppContext } from 'Api/AppContext';
import { IMapProvider } from 'Api/MapService';
import { Project } from 'Api/Contracts/Dtos';
import { injectTypes } from 'App/injectTypes';
import { lazyInject } from 'App/Inversify.config';
import { IVisonUriService } from 'App/Services/UriServices/Core';
import { Component, Prop, UIElement } from 'Framework/Components/UIElement';
import { Feature, GeoJSON, Point } from 'geojson';
import L from 'leaflet';
import 'leaflet-geosearch/assets/css/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet/dist/leaflet.css';
import { Ref } from 'vue-property-decorator';
import { LControl, LGeoJson, LImageOverlay, LMap, LMarker, LTileLayer, LTooltip } from 'vue2-leaflet';
import LGeosearch from 'vue2-leaflet-geosearch/Vue2LeafletGeosearch.vue';

// import 'leaflet/dist/images/marker-shadow.png';
// import 'leaflet/dist/images/marker-icon-2x.png';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

@Component({
    name: 'x-explore-map',
    components: {
        LMap,
        LImageOverlay,
        LMarker,
        LGeoJson,
        LTileLayer,
        LControl,
        LTooltip,
        LGeosearch,
    }
})
export default class ExploreMap extends UIElement {
    private _onLocationClick(e: L.LeafletMouseEvent): void {
        this._emitLocationChanged(e.latlng.lat, e.latlng.lng);
    }

    private _emitLocationChanged(latitude: number, longitude: number): void {
        let location: Point = {
            type: 'Point',
            //Don't change the order see https://macwright.org/lonlat/
            coordinates: [
                longitude,
                latitude
            ]
        };

        let geoJsonPoint: GeoJSON = L.GeoJSON.asFeature(location);

        this.$emit('location-changed', geoJsonPoint);
    }

    private _onNavigateTo(project: Project): void {
        if (this.readonly) {
            //need to verif publishing status ?
            window.location.href = this.uriService.getAbsoluteUri(
                `${this._appContext.codeCulture}/explore/watch/?pId=${project.projectId || (project as any).id}`
            );
        }
        else {
            let point: L.LatLng = this._invertLngLat(project.location);

            this.leafletMap.mapObject.fitBounds(new L.LatLngBounds([[point.lat, point.lng]]));
        }
    }

    private _invertLngLat(location: GeoJSON): L.LatLng {
        let featurePoint = location as Feature<Point>;
        let geoJsonPoint = featurePoint.geometry.coordinates as [number, number];

        return L.GeoJSON.coordsToLatLng(geoJsonPoint);
    }

    private _onMouseMove(e: L.LeafletMouseEvent): void {
        this.currentCoordinates = e.latlng;
    }

    private _mapReady(): void {
        const ro = new ResizeObserver(_ => {
            this.leafletMap?.mapObject?.invalidateSize();
        });

        ro.observe(this.leafletMap.$el);

        this.leafletMap.mapObject.on(
            'geosearch/showlocation',
            (e: any) => this._emitLocationChanged(e.location.y, e.location.x),
            this
        );
    }

    public get center(): L.LatLng {
        if (this.centerOnProject && this.itemsSource.filter(p => p.location !== null).length > 0) {
            const [project] = this.itemsSource;
            let point: L.LatLng = this._invertLngLat(project.location);

            return new L.LatLngBounds([[point.lat, point.lng]]).getCenter();
        }

        if (this.leafletMap) {
            const zoom = this.leafletMap.mapObject.getBoundsZoom(this.mapProvider.maxBounds, false);

            this.mapProvider.zoom = zoom;
        }

        return this.mapProvider.maxBounds.getCenter();
    }

    public currentCoordinates: L.LatLng = new L.LatLng(0, 0);

    public readonly translationPath: string = 'JS.MapExplorer';

    @Ref('leafletMap')
    public leafletMap: LMap;

    @Prop({ type: Array, default: () => [] })
    public itemsSource: Array<Project>;

    @Prop({ type: String, default: '100%' })
    public height: String;

    @Prop({ type: String, default: '100%' })
    public width: String;

    @Prop({ type: Boolean, default: false })
    public displayCoordinates: boolean;

    @Prop({ type: Boolean, default: false })
    public readonly: boolean;

    @Prop({ type: Object, default: null })
    public mapProvider: IMapProvider;

    @Prop({ type: Boolean, default: false })
    public centerOnProject: boolean;

    @lazyInject(AppContext)
    private _appContext: AppContext;

    @lazyInject(injectTypes.IVisonUriService)
    public uriService: IVisonUriService;
}
