import { AppContext } from 'Api/AppContext';
import { inject, injectable } from 'inversify';
import moment from 'moment';
import HttpClient from 'Api/HttpClient';
import { JSONPath } from 'jsonpath-plus';
import { String } from 'typescript-string-operations';

@injectable()
export default class LocalizerService {
    constructor(@inject(AppContext) appContext: AppContext, @inject(HttpClient) httpClient: HttpClient) {
        this._appContext = appContext;
        this._httpClient = httpClient;
    }

    public async loadTranslationAsync(codeCulture: string): Promise<void> {
        if (this.locale?.[codeCulture]) {
            return;
        }

        let r = await this._httpClient.getAsync(`locale/i18n`);
        this.locale = {};
        this.locale[codeCulture] = await r.json();
    }

    public async getTermsOfServicesAsync(codeCulture: string): Promise<string> {
        return await (await this._httpClient.getAsync(`i18n/tos/${codeCulture}.md`, '/'))
            .text();
    }

    public get codeCulture(): string {
        return this._appContext.codeCulture;
    }

    public getTemplateString(key: string): string {
        if (!this.locale?.[this.codeCulture]) {
            return key;
        }

        return JSONPath({ path: key, json: this.locale[this.codeCulture], preventEval: true, wrap: false });
    }

    public localizeDate(date: Date): moment.Moment {
        return moment(date).locale(this._appContext.codeCulture);
    }

    public localize(key: string, ...params: Array<string | number>): string {
        const rawString = this.getTemplateString(key);
        return String.Format(rawString, ...params);
    }

    public getISODate(date: Date): string {
        return moment(date).toISOString(true).substr(0, 10);
    }

    public getISOTime(date: Date): string {
        return moment(date).toISOString(true).substr(11, 5);
    }

    public get utcOffset(): number {
        return moment().utcOffset();
    }

    public locale: any;
    protected readonly _appContext: AppContext;
    protected readonly _httpClient: HttpClient;
}

@injectable()
export class LocalizerServiceStandalone extends LocalizerService {
    public async loadTranslationAsync(codeCulture: string): Promise<void> {
        let r = await this._httpClient.getAsync(`local/i18n`);

        this.locale = {};
        this.locale[codeCulture] = await r.json();
    }
}
