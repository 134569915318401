import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-dialog',{attrs:{"buttons":_vm.buttons},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.localizer((_vm.baseTranslationPath + ".Title")))+" ")]},proxy:true},{key:"default",fn:function(ref){
var onOk = ref.onOk;
return [_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,[_c('vo-errors-list',{attrs:{"errors":_vm.errors}}),_c('p',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.localizer((_vm.baseTranslationPath + ".PasswordNeeded")))+" ")]),_c(VTextField,{attrs:{"label":_vm.localizer((_vm.baseTranslationPath + ".Password")),"autofocus":"","outlined":"","type":"password"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return onOk.apply(null, arguments)}},model:{value:(_vm.dataContext.password),callback:function ($$v) {_vm.$set(_vm.dataContext, "password", $$v)},expression:"dataContext.password"}})],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }