import { CancellationToken, CancellationTokenSource, delayAsync } from 'Framework/Extensions/CoreJsExtensions';
import { IPollingBehavior } from 'Framework/Services/PollingBehavior';
import { chunk } from 'lodash';

export interface IPollingResult<TItem, TPollingResult> {
    item: TItem;
    result: TPollingResult
}

export class PollingManager<TItem, TPollingResult> {
    public constructor(delay: number, pollingBehavior: IPollingBehavior<TItem, TPollingResult>) {
        this._delay = delay;
        this._behavior = pollingBehavior;
        this._cancellationTokenSource = null;
        this._items = {};
    }

    public addItems(items: Array<TItem>): void {
        items.forEach(i => {
            const itemId: string = this._behavior.getItemId(i);
            this._items[itemId] = [...(this._items[itemId] ?? []), i]
        });
    }

    public stop(): void {
        if (this.isPolling) {
            this._cancellationTokenSource.cancel();
            this._cancellationTokenSource = null;
            this._items = {};
        }
    }

    public async startAsync(): Promise<void> {
        if (this.isPolling) {
            return;
        }

        const cts = new CancellationTokenSource();
        this._cancellationTokenSource = cts;

        //Mettre dans requestFrame ?
        //https://medium.com/trabe/implementing-settimeout-using-requestanimationframe-20cc2f6e6b5d
        await this._pollingAsync(cts.token);
    }

    private async _pollingAsync(cancellationToken: CancellationToken): Promise<void> {
        while (!cancellationToken.isCancellationRequested) {
            if (Object.keys(this._items).length > 0) {
                const itemsValues = Object.values(this._items).flatMap(v => v);
                const chunkedItemsValues: Array<Array<any>> = chunk(itemsValues, 500);

                for (const chunkItemsValues of chunkedItemsValues) {
                    const results = await this._behavior.pollingActionAsync(chunkItemsValues);

                    const mapResults: Array<IPollingResult<TItem, TPollingResult>> = results
                        .flatMap(r => {
                            const key = this._behavior.getItemIdFromResult(r);

                            return this._items[key]
                                .map((value, _, array) => ({
                                    result: r,
                                    item: value,
                                    _clear: () => {
                                        array.splice(array.indexOf(value), 1);

                                        if (array.length == 0) {
                                            delete this._items[key];
                                        }
                                    }
                                }));
                        });

                    await this._behavior.onPollingAsync?.(mapResults);

                    const itemsStopPolling = mapResults.filter(result => this._behavior.stopPollingItemWhen(result));

                    if (itemsStopPolling.length > 0) {
                        await this._behavior.onEndedAsync?.(itemsStopPolling);

                        itemsStopPolling.forEach((i: IPollingResult<TItem, TPollingResult> & { _clear: () => void }) => {
                            i._clear();
                        });
                    }

                    await delayAsync(100);
                }
            }

            await delayAsync(this._delay);
        }
    }

    public get isPolling(): boolean {
        return this._cancellationTokenSource != null;
    }

    private _items: { [id: string]: Array<TItem> };
    private _cancellationTokenSource: CancellationTokenSource;

    private readonly _behavior: IPollingBehavior<TItem, TPollingResult>;
    private readonly _delay: number;
}
