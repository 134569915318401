import { IDialogBehavior } from 'Framework/Components/ModalDialog/DialogBehavior';
import { DialogResult } from 'Framework/Services/IDialogService';
import { Component, Prop, Vue } from 'vue-property-decorator';

export interface IDialogTemplateSlotContext {
    onOk: () => Promise<void>;
    onCancel: () => Promise<void>;
    onYes: () => Promise<void>;
    onNo: () => Promise<void>;
    onClose: () => Promise<void>

    isOkBusy: boolean;
    isCancelBusy: boolean;
    isYesBusy: boolean;
    isNoBusy: boolean;
    isCloseBusy: boolean;
    isBusy: boolean;
}

@Component
export default class DialogTemplateRenderless extends Vue {
    public render() {
        const slotContext: IDialogTemplateSlotContext = {
            onOk: this._onOkAsync,
            onCancel: this._onCancelAsync,
            onYes: this._onYesAsync,
            onNo: this._onNoAsync,
            onClose: this._onCloseAsync,
            isBusy: this.isBusy,
            isOkBusy: this.isOkBusy,
            isCancelBusy: this.isCancelBusy,
            isYesBusy: this.isYesBusy,
            isNoBusy: this.isNoBusy,
            isCloseBusy: this.isCloseBusy
        };

        return this.$scopedSlots.default(slotContext);
    }

    private async _onOkAsync(): Promise<void> {
        if (this.isBusy) {
            return;
        }

        try {
            this.isOkBusy = true;

            if (await this.behavior.beforeOkAsync()) {
                this._close(DialogResult.OK);
            }
        }
        finally {
            this.isOkBusy = false;
        }
    }

    private async _onCancelAsync(): Promise<void> {
        if (this.isBusy) {
            return;
        }

        try {
            this.isCancelBusy = true;

            if (await this.behavior.beforeCancelAsync()) {
                this._close(DialogResult.Cancel);
            }
        }
        finally {
            this.isCancelBusy = false;
        }
    }

    private async _onYesAsync(): Promise<void> {
        if (this.isBusy) {
            return;
        }

        try {
            this.isYesBusy = true;

            if (await this.behavior.beforeYesAsync()) {

                this._close(DialogResult.Yes);
            }
        }
        finally {
            this.isYesBusy = false;
        }
    }

    private async _onNoAsync(): Promise<void> {
        if (this.isBusy) {
            return;
        }

        try {
            this.isNoBusy = true;

            if (await this.behavior.beforeNoAsync()) {
                this._close(DialogResult.No);
            }
        }
        finally {
            this.isNoBusy = false;
        }
    }

    private async _onCloseAsync(): Promise<void> {
        if (this.isBusy) {
            return;
        }

        try {
            this.isCloseBusy = true;

            if (await this.behavior.beforeCloseAsync()) {
                this._close(DialogResult.None);
            }
        }
        finally {
            this.isCloseBusy = false;
        }

    }

    private _close(result: DialogResult): void {
        this.$emit('on-close', result);
    }

    public get isBusy(): boolean {
        return this.isOkBusy
            || this.isCancelBusy
            || this.isYesBusy
            || this.isNoBusy
            || this.isCloseBusy;
    }

    public isOkBusy: boolean = false;
    public isCancelBusy: boolean = false;
    public isYesBusy: boolean = false;
    public isNoBusy: boolean = false;
    public isCloseBusy: boolean = false;

    @Prop({ type: Object, default: () => new EmptyBehavior() })
    public behavior: IDialogBehavior;
}

class EmptyBehavior implements IDialogBehavior {
    public beforeOkAsync: () => Promise<boolean> = () => Promise.resolve(true);
    public beforeCancelAsync: () => Promise<boolean> = () => Promise.resolve(true);
    public beforeYesAsync: () => Promise<boolean> = () => Promise.resolve(true);
    public beforeNoAsync: () => Promise<boolean> = () => Promise.resolve(true);
    public beforeCloseAsync: () => Promise<boolean> = () => Promise.resolve(true);
}
