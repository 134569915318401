import { IAbsoluteUriBehavior, IUriService } from 'Api/Contracts/Interfaces';
import { ArgumentNullException } from 'Api/Dto/Exception';
import { injectable } from 'inversify';

@injectable()
export class UriService implements IUriService {
    public constructor(baseUri: string | (() => string), behavior: IAbsoluteUriBehavior) {
        if (!behavior) {
            throw new ArgumentNullException('behavior');
        }

        this.baseUri = baseUri;
        this._absoluteUriBehavior = behavior;
    }

    public getAbsoluteUri(relativeUri: string, parameters?: Record<string, string>): string {
        return this._absoluteUriBehavior.getAbsoluteUri(this.getBaseUri(), relativeUri, parameters);
    }

    public getAbsoluteUriAsync(relativeUri: string, parameters?: Record<string, string>): Promise<string> {
        return this._absoluteUriBehavior.getAbsoluteUriAsync(this.getBaseUri(), relativeUri, parameters);
    }

    public getBaseUri(): string {
        return typeof this.baseUri === 'string'
            ? this.baseUri
            : this.baseUri();
    }

    public baseUri: string | (() => string);
    private readonly _absoluteUriBehavior: IAbsoluteUriBehavior;
}
