export enum ViewerStateType {
    Normal = 1,
    Wide = 2,
    FullPage = 3,
    FullScreen = 4,
    normal = Normal,
    wide = Wide,
    fullpage = FullPage,
    fullscreen = FullScreen
}
