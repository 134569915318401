import { ApiException, IError } from 'Api/Dto/QueryResult';
import { IPasswordDialogContext } from 'App/Areas/Shares/Dialogs/IPasswordDialogContext';
import VoErrorsList from 'App/Areas/Shares/VoErrorsList.vue';
import BaseDialogBehavior from 'App/Services/DialogService/BaseDialogBehavior';
import { DialogButton } from 'App/Services/DialogService/Components/DefaultDialog.vue';
import { Component } from 'vue-property-decorator';

@Component({
    name: 'VoPasswordDialog',
    components: {
        VoErrorsList
    }
})
export default class VoPasswordDialog extends BaseDialogBehavior<IPasswordDialogContext> {
    public async beforeOkAsync(): Promise<boolean> {
        try {
            this.errors = [];

            this.dataContext.validatedToken = await this._dataContext.authenticateForShareAsync(
                this.dataContext.token,
                this.dataContext.password
            );

            return true;
        }
        catch (ex) {
            if (ex instanceof ApiException) {
                this.errors.push(ex.error);
            }

            return false;
        }
    }

    public errors: Array<IError> = [];

    public readonly buttons: DialogButton = DialogButton.Ok;
    public readonly baseTranslationPath: string = 'Areas.Shared.VoPasswordDialog';
}
