import { Component, UIElement } from 'Framework/Components/UIElement';
import { CreateElement, VNode } from 'vue';

@Component({
    name: 'x-grid-item'
})
export default class GridItem extends UIElement {
    public render(createElement: CreateElement, context: any): VNode {
        let result = createElement(
            'div', // nom de balise
            {
                class: 'x-grid-item',
                style: this.computedStyle
            },
            this.$slots.default
        );
        return result;
    }
}
