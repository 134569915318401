import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VBreadcrumbs,{staticClass:"pa-2",style:([{'background-color':'rgba(0,0,0,0.4)',position:'relative'},_vm.computedStyle]),attrs:{"items":_vm.breadcrumbItems},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c(VIcon,{attrs:{"color":"white"}},[_vm._v("fa-light fa-chevron-right")])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [(item.type == _vm.BreadcrumbItemType.Home)?_c(VBreadcrumbsItem,[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("fa-solid fa-house")])],1):(item.type == _vm.BreadcrumbItemType.Standard)?_c(VBreadcrumbsItem,{staticClass:"white--text"},[_vm._v(" "+_vm._s(item.text)+" ")]):_c(VBreadcrumbsItem,[_c(VSelect,{attrs:{"outlined":"","dense":"","dark":"","hide-details":"","return-object":"","item-value":"assetItemId","item-text":"name","items":_vm.currentPanoramaGroup.children,"value":_vm._currentPanorama},on:{"change":_vm._onSelectedPanoramaVersionChange},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VListItemAvatar,[_c('img',{attrs:{"src":_vm._uriService.getAbsoluteUri(item.file.links.preview)}})]),_c(VListItemContent,[_vm._v(_vm._s(item.name))])]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }