import { IMapSubrouteDto } from 'Api/Contracts/Dtos/GeoRouting/IMapSubrouteDto';

export enum CoordinateSystem {
    Cartesian,
    Geographic
}

export interface IGeoRoutingResultDto {
    isRouteFound: boolean;
    distance: number;
    route: Array<IMapSubrouteDto>;
    coordinateSystem: CoordinateSystem;
}
