import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class BaseButton extends Vue {
    protected _onClick(event: Event): void {
        this.$emit('click', event);
    }

    @Prop({ type: Boolean, default: false })
    public loading: boolean;

    @Prop({ type: Boolean, default: false })
    public disabled: boolean;
}
