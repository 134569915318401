import { DrivePermissions } from 'Api/Enums/Permissions';

export enum FileType { Unknow, Image, Video, Pdf, Directory, Office, Audio, PointCloud, Symlink }
export enum Permission { Read, Write }
export enum ShareType { None, Read, Write }

export type FileLinkKey =
    | 'preview'
    | 'download'
    | 'panorama'
    | 'image'
    | 'optimizedImage'
    | 'deepzoom'
    | 'video'
    | 'pdf'
    | 'audio'
    | 'pointcloud';

export type FileLinks = Partial<Record<FileLinkKey, string>>;

export class Guid {
    public static readonly emptyGuid: string = '00000000-0000-0000-0000-000000000000';

    public static isNullOrEmpty(guid: string): boolean {
        return !guid || guid == Guid.emptyGuid;
    }

    public static isEmpty(guid: string): boolean {
        return guid == Guid.emptyGuid;
    }
}

export interface File {
    fileId: number;
    ownerId: number;
    guid: string;
    nameWithoutExtension: string;
    extension: string;
    isSymlink: boolean;
    isDirectory: boolean;
    fileType: FileType;
    shareType: ShareType;
    isShare: boolean;
    creationTime: Date;
    updatedTime?: Date;
    thumbnailGuid: string;
    size: number;
    state: FileTaskResult;
    target?: File;
    links: FileLinks;
}
interface MoveFileViewModel {
    files: number[];
    destination: number;
}

interface GetFilesResult {
    breadcrumb: File[],
    files: File[],
    totalFilesCount: number,
    resultPerPage: number,
    pageNumber: number
}

export interface IFilePermission {
    id: number;
    permission: DrivePermissions;
    creationTime: Date;
    updatedTime: Date;
    identityId: number;
    fileId: number;
    fileName: string;
}

export interface IFileShare {
    fileShareId: number;
    name: string;
    creationTime: Date;
    picture: string;
    pictureUrl: string;
    isGroup: boolean;
    permission: Permission;
    token: string;
    identityId: number;
}

export enum WorkerTaskState {
    Created = 0,
    Running = 1,
    Completed = 2,
    Canceled = 3,
    Faulted = 4
}

export interface FileTaskResult {
    taskId: number;
    resultId: number;
    state: WorkerTaskState;
    description: string;
    typeOfBody: string;
    typeOfHeader: string;
    filePathGuid: string;
    creationTimeUtc: Date;
    lastExecutedTimeUtc?: Date;
}

export interface IFileValidationResult {
    fileName: string;
    errors: Array<string>;
}

export class DriveHelper {
    public static isFileShare(fileShare: any): fileShare is IFileShare {
        return fileShare
            && (fileShare as IFileShare).fileShareId !== undefined;
    }
}
