import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,[_c(VListItemAvatar,[_c(VIcon,{staticClass:"primary",domProps:{"textContent":_vm._s(_vm.fileIcon)}})],1),_c(VListItemContent,[_c(VListItemTitle,[_c('span',[_vm._v(_vm._s(_vm.file.name))]),_c(VChip,{class:("float-right " + _vm.onStatusChanged),attrs:{"x-small":""}},[_vm._v(_vm._s(_vm.localizer(("JS.Drive.UploadFileEntries.States." + (_vm.uploadStatusEnumeration[_vm.uploadStatus])))))])],1),(!_vm.file.hasError)?_c(VListItemSubtitle,[_c('span',[_vm._v(_vm._s(_vm._formatFileEntrySize(_vm.file.progress, _vm.file.size))+" / "+_vm._s(_vm._formatTotalSize(_vm.file.size)))]),_c('span',{staticClass:"float-right"},[_vm._v(_vm._s(Math.round(_vm.file.progress))+" %")])]):_vm._e(),_c(VListItemSubtitle,[(!_vm.file.hasError)?_c(VProgressLinear,{attrs:{"primary":"","value":Math.round(_vm.file.progress)}}):_vm._l((_vm.file.errors),function(error,index){return _c('span',{key:index,staticClass:"error--text"},[_vm._v(_vm._s(error))])})],2)],1),_c(VListItemAction,[(!_vm.isUploading)?_c(VBtn,{attrs:{"text":"","x-small":"","icon":""},on:{"click":function($event){return _vm.$emit('on-remove-file-entry', _vm.file)}}},[_c(VIcon,[_vm._v("fa-light fa-xmark")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }