import { IProblemDetails } from 'Api/Dto/QueryResult';
import { ApiExceptionFactory } from 'Api/Infrastructure/ApiExceptionFactory';
import { IResponseHandler } from 'Api/Infrastructure/Interfaces';
import { inject, injectable } from 'inversify';

@injectable()
export class ResponseHandler implements IResponseHandler {
    public constructor(
        @inject(ApiExceptionFactory) apiExceptionFactory: ApiExceptionFactory
    ) {
        this._apiExceptionFactory = apiExceptionFactory;
    }

    public async handleResponseAsync<T>(response: Response): Promise<T> {
        await this.handleErrorsAsync(response);

        return await response.json() as T;
    }

    public async handleErrorsAsync(response: Response): Promise<void> {
        if (response.status >= 400) {
            const problemDetails = await response.json() as IProblemDetails;

            throw this._apiExceptionFactory.fromProblemDetails(problemDetails);
        }
    }

    private readonly _apiExceptionFactory: ApiExceptionFactory;
}

