import { ICartesianCoordinates, ICoordinates, IPlanCoordinates, ISphericalCoordinates } from 'Api/Dto/Project';
import { MathHelper } from 'Framework/Helpers/MathHelper';

export class CoordinatesHelper {
    public static isCartesianCoordinates(coordinates: ICoordinates): coordinates is ICartesianCoordinates {
        return coordinates
            && (coordinates as ICartesianCoordinates).x !== undefined
            && (coordinates as ICartesianCoordinates).y !== undefined
            && (coordinates as ICartesianCoordinates).z !== undefined;
    }

    public static isSphericalCoordinates(coordinates: ICoordinates): coordinates is ISphericalCoordinates {
        return coordinates
            && (coordinates as ISphericalCoordinates).phi !== undefined
            && (coordinates as ISphericalCoordinates).theta !== undefined;
    }

    public static isPlanCoordinates(coordinates: ICoordinates): coordinates is IPlanCoordinates {
        return coordinates
            && (coordinates as IPlanCoordinates).x !== undefined
            && (coordinates as IPlanCoordinates).y !== undefined;
    }

    public static planToSpherical(coordinates: IPlanCoordinates, width: number, height: number): ISphericalCoordinates {
        return {
            theta: -((coordinates.x / width) - 0.5) * 2 * Math.PI,
            phi: (1 - ((2 * coordinates.y) / height)) * (Math.PI / 2)
        };
    }

    public static sphericalToPlan(coordinates: ISphericalCoordinates, width: number, height: number): IPlanCoordinates {
        return {
            x: ((-coordinates.theta / (2 * Math.PI)) + 0.5) * width,
            y: (((-2 * coordinates.phi) / Math.PI) + 1) * (height / 2)
        };
    }

    public static changeAzimuthBase(coordinates: ISphericalCoordinates, oldOrigin: number, newOrigin: number): ISphericalCoordinates {
        return {
            phi: coordinates.phi,
            theta: MathHelper.mod(coordinates.theta - oldOrigin + newOrigin + Math.PI, 2 * Math.PI) - Math.PI
        };
    }
}
