import UserPermissions from 'Api/UserPermissions';
import { ILoginResponse } from 'Api/Dto/Authentication';

export default class User {
    static fromLoginResponse(login: ILoginResponse): User {
        let user = new User();

        Object.assign(user, login.user);
        Object.assign(user.permissions, login.role);

        return user;
    }

    constructor() {
        this.permissions = new UserPermissions();
    }

    public get displayName(): string {
        if (this.firstName && this.lastName) {
            return `${this.firstName} ${this.lastName}`;
        }
        else if (this.firstName) {
            return this.firstName;
        }
        else if (this.lastName) {
            return this.lastName;
        }
        return this.name;
    }

    public get isSignedIn(): boolean {
        return this.id > 0;
    }

    public id: number;

    public firstName: string;
    public lastName: string;
    public name: string;
    public email: string;

    public avatarGuid: string;

    public resetPasswordNeeded: boolean;

    public permissions: UserPermissions;
}
