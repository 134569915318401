import { IError } from 'Api/Dto/QueryResult';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'VoErrorsList'
})
export default class VoErrorsList extends Vue {
    public get displayedErrors(): Array<IError> {
        const flattenExpression:(value: IError, index: number, array: IError[]) => IError | ReadonlyArray<IError> = 
            e => e.details?.flatMap(flattenExpression) ?? e;

        return this.errors.flatMap(flattenExpression);
    }

    @Prop({type: Array, default: () => []})
    public errors: Array<IError>;
}
