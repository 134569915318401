import { AppContext, IAppLogin } from 'Api/AppContext';
import { ForbiddenException, IError, QueryException } from 'Api/Dto/QueryResult';
import { AuthenticationService } from 'Api/Services';
import VoErrorsList from 'App/Areas/Shares/VoErrorsList.vue';
import { injectTypes } from 'App/injectTypes';
import { lazyInject, lazyInjectStandaloneOnline } from 'App/Inversify.config';
import BaseDialogBehavior from 'App/Services/DialogService/BaseDialogBehavior';
import { IVisonUriService } from 'App/Services/UriServices/Core';
import { UrlHelper } from 'Framework/Helpers/UrlHelper';
import { Component } from 'vue-property-decorator';

export interface ISignInDialogContext {
    allowPasswordReset: boolean,
    allowRegister: boolean,
    loginProviders: Array<any>
}

@Component({
    name: 'VoSignInDialog',
    components: {
        VoErrorsList
    }
})
export default class VoSignInDialog extends BaseDialogBehavior<ISignInDialogContext> {
    public async beforeOkAsync(): Promise<boolean> {
        this.errors = [];

        this._appContext.apiUri = this.apiUri;

        try {
            const result = await this._authenticationService.signInAsync({
                userName: this.userName,
                password: this.password
            });

            const login = result as IAppLogin;
            login.apiUri = this._appContext.apiUri;

            this._appContext.login = login;

            return true;
        }
        catch (ex) {
            if (ex instanceof QueryException) {
                this.errors.push(ex.error);
            }
        }

        return false;
    }

    public async tryAutoSignInAsync(uri: string): Promise<void> {
        this.errors = [];

        try {
            this._appContext.apiUri = uri;

            const user = await this._authenticationService.singleSignOnAsync(() => {
                this.isAutoSignInFail = true;
            });

            if (user) {
                const login = user as IAppLogin;
                login.apiUri = this._appContext.apiUri;
                this._appContext.login = login;

                this.onClose();
            }
        }
        catch (ex) {
            if (ex instanceof ForbiddenException) {
                this.errors.push({
                    message: this.localizer(`${this.baseTranslationPath}.InvalidCertificate`),
                    code: null,
                    innerError: null,
                    target: null,
                    details: null
                });
            }
            else {
                this.isAutoSignInFail = true;
            }
        }
    }

    public get platformRules(): Array<(input: string) => boolean | string> {
        return [
            (input: string) => this.isHttpApiUri
                ? UrlHelper.isValidUrl(this.apiUri)
                    || this.localizer(`${this.baseTranslationPath}.ValidationRules.NotWellFormattedUrl`)
                : !!input
                    || this.localizer(`${this.baseTranslationPath}.ValidationRules.EmptyUriOrPlatformName`)
        ];
    }

    public get isHttpApiUri(): boolean {
        return this.platformName?.startsWith('http') ?? false;
    }

    public get apiUri(): string {
        return this.platformName
            ? this.isHttpApiUri
                ? this.platformName
                : `https://${this.platformName}.vis-on.fr`
            : null;
    }

    public onTryAutoSignIn: (uri: string) => Promise<void> = this.$debounce((uri: string) =>
        this.isFormPropertyValid && this.tryAutoSignInAsync(uri),
        500
    );
    public isAutoSignInFail: boolean = false;
    public isFormPropertyValid: boolean = true;
    public userName: string = null;
    public password: string = null;
    public platformName: string = null;
    public errors: Array<IError> = [];

    public readonly baseTranslationPath: string = 'Areas.Shared.VoSignInDialog';

    @lazyInject(AppContext)
    private readonly _appContext: AppContext;

    @lazyInjectStandaloneOnline(AuthenticationService)
    private readonly _authenticationService: AuthenticationService;

    @lazyInject(injectTypes.IVisonUriService)
    private readonly _uriService: IVisonUriService;
}
