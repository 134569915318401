import UIElement from 'Framework/Components/UIElement';
import XGrid from 'Framework/Components/Controls/XGrid.vue';
import GridItem from 'Framework/Components/GridItem.vue';


import DefaultDialog from 'App/Services/DialogService/Components/DefaultDialog.vue';
import XMediaPlayer from 'Framework/Components/Controls/XMediaPlayer.vue';
import Vue from 'vue';
import Tagger from 'App/Areas/Shares/Tagger.vue';
import { IRouter } from 'App/Areas/Shares/IRouter';

import Toggle from 'Framework/Components/Controls/Toggle.vue';
import ItemPresenter from 'Framework/Components/Controls/ItemPresenter.vue';
import PanoramaPreview from 'App/Areas/Explore/Components/PanoramaPreview.vue';
import WrapPanel from 'Framework/Components/Controls/WrapPanel.vue';
import ExploreMap from 'App/Areas/Shares/ExploreMap.vue';
import UploadFileEntry from 'App/Areas/Drive/DataTemplates/UploadFileEntry.vue';
import FilePreview from 'App/Areas/Shares/FilePreview.vue';
import VoPanoramaBreadcrumb from 'App/Areas/Explore/Components/VoPanoramaBreadcrumb.vue';
import Compass from 'App/Areas/Project/Components/Compass.vue';
import SbsDrawer from 'App/Areas/Shares/SbsDrawer.vue';
import CookieConsent from 'App/Areas/Public/Components/CookieConsent.vue';

import '@/scss/main.scss';
import '@/scss/CustomHotspotsIcon.scss';
import '@/scss/vuetify.override.scss';
import '@/scss/dockspawn-ts.override.scss';

declare module 'vue/types/vue' {
    interface Vue {
        $router: IRouter;
    }
}

Object.defineProperty(Vue.prototype, '$router', {
    get() { return this.$root }
})

export default {
    components: {
        UIElement,
        XGrid,
        GridItem,
        DefaultDialog,
        XMediaPlayer,
        Tagger,
        CookieConsent,
        WrapPanel,
        ItemPresenter,
        PanoramaPreview,
        Toggle,
        ExploreMap,
        UploadFileEntry,
        FilePreview,
        VoPanoramaBreadcrumb,
        Compass,
        SbsDrawer,
    },
    install: function (vue, options) {
        for (const componentKey in this.components) {
            const component = this.components[componentKey];

            if (component.extendOptions) {
                Vue.component(component.extendOptions.name, component);
            } else {
                Vue.component(component.name, component);
            }
        }
    }
}
