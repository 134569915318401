import { MenuStateType } from 'Api/Enums/MenuStateType';
import { FetchFileReader } from 'App/Areas/Standalone/FetchFileReader';
import { FileStorageHelper } from 'App/Areas/Standalone/FileStorageHelper';

declare global {
    const standaloneWrapper: StandaloneWrapper;
    const cordova: any;

    interface Entry {
        fileHelper: FileStorageHelper;
    }
}

export class StandaloneWrapper {
    public initializeAsync(): Promise<void> {
        return new Promise((resolve, reject) => {
            document.addEventListener('deviceready', async (e) => {
                try {
                    await this._onDeviceReadyAsync(e);
                    resolve();
                }
                catch (error) {
                    reject(error as Error);
                }
            }, false);
        });
    }

    public baseUriResolver() {
        return `${cordova.file.dataDirectory}${this._appContext.subUriPath}`
    }

    public appContextBuilder(appContext: any): void {
        this._appContext = appContext;

        appContext.subUriPath = '';
        appContext.baseUri = this.baseUriResolver.bind(this);
        appContext.codeCulture = 'fr-fr';

        appContext.homePage = 'index.html';
        appContext.menuState = MenuStateType.Closed;
        appContext.isMobile = true;
        appContext.theme = 'dark-orange';

        appContext.isDedicatedPlatform = false;
    }

    private async _onDeviceReadyAsync(ev: Event): Promise<void> {
        document.addEventListener('pause', (e) => this._onPause(e), false);
        document.addEventListener('resume', (e) => this._onResume(e), false);

        await this._filePluginIsReadyAsync();

        if (!await this.hasStoragePermissionAsync()) {
            await this._requestStoragePermissionAsync();
        }

        this._persistentFolder = new FileStorageHelper(LocalFileSystem.PERSISTENT);
        this._temporaryFolder = new FileStorageHelper(LocalFileSystem.TEMPORARY);
        this._fetchFileReader = new FetchFileReader(this._persistentFolder);

        await this._persistentFolder.initializeAsync();
        await this._temporaryFolder.initializeAsync();
        await this._fetchFileReader.polyfillFetchAsync();

        await this._copyDefaultFilesAsync();
    }

    private async _copyDefaultFilesAsync(): Promise<void> {
        await Promise.all([
            this._copyBundleFileToStorageFileAsync('/i18n/en-us.json', 'en-us/local/i18n'),
            this._copyBundleFileToStorageFileAsync('/i18n/fr-fr.json', 'fr-fr/local/i18n'),
            this._copyBundleFileToStorageFileAsync('/assets/Logo-vison.svg', 'assets/Logo-Vison.svg'),
            this._copyBundleFileToStorageFileAsync('/assets/no-thumb.jpg', 'fr-fr/asset/preview/00000000-0000-0000-0000-000000000000'),
            this._copyBundleFileToStorageFileAsync('/assets/no-thumb.jpg', 'fr-fr/asset/avatar/00000000-0000-0000-0000-000000000000/small'),
            this._copyBundleFileToStorageFileAsync('/assets/no-thumb.jpg', 'fr-fr/asset/avatar/00000000-0000-0000-0000-000000000000/medium'),
            this._copyBundleFileToStorageFileAsync('/assets/no-thumb.jpg', 'fr-fr/asset/avatar/00000000-0000-0000-0000-000000000000/large')
        ]);
    }

    private async _copyBundleFileToStorageFileAsync(source: string, destination: string): Promise<void> {
        const sourceFile = await fetch(`${cordova.file.applicationDirectory}${source}`);

        let indexOfSlash = destination.lastIndexOf('/');
        let assetDirectory: DirectoryEntry = null;
        if (indexOfSlash > 0) {
            let directoryPath = destination.slice(0, indexOfSlash);
            assetDirectory = await this._persistentFolder.createDirectoryAsync(this._persistentFolder.Root, directoryPath);
        }

        let destinationFile = await this._persistentFolder.createFileAsync(assetDirectory, destination.slice(indexOfSlash + 1));

        await FileStorageHelper.writeFileAsync(destinationFile, await sourceFile.blob());
    }

    private _onResume(e: Event): void {
        // throw new Error('Method not implemented.');
    }

    private _onPause(e: Event): void {
        //throw new Error('Method not implemented.');
    }

    private _filePluginIsReadyAsync(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (cordova.platformId == 'android') {
                resolve();
            }
            else if ((window as any).isFilePluginReadyRaised) {
                console.log('File plugin is ready');
                resolve();
            }
            else {
                window.addEventListener('filePluginIsReady', (e) => {
                    console.log('File plugin is ready');
                    resolve();
                }, false);
            }
        });

    }

    public hasStoragePermissionAsync(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const permissions = cordova.plugins.permissions;

            permissions.checkPermission(
                permissions.WRITE_EXTERNAL_STORAGE,
                (status) => resolve(status.hasPermission),
                reject
            );
        });
    }

    private _requestStoragePermissionAsync(): Promise<void> {
        return new Promise((resolve, reject) => {
            const permissions = cordova.plugins.permissions;

            permissions.requestPermissions(
                [permissions.READ_EXTERNAL_STORAGE, permissions.WRITE_EXTERNAL_STORAGE],
                resolve,
                reject
            );
        });
    }

    public get persistentFolder(): FileStorageHelper {
        return this._persistentFolder;
    }

    public get temporaryFolder(): FileStorageHelper {
        return this._temporaryFolder;
    }

    public get fetchFileReader(): FetchFileReader {
        return this._fetchFileReader;
    }

    private _appContext: any = null;
    private _persistentFolder: FileStorageHelper = null;
    private _temporaryFolder: FileStorageHelper = null;
    private _fetchFileReader: FetchFileReader = null;
}
