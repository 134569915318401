import GridItem from 'Framework/Components/GridItem.vue';
import { PropSyncWithInternal, Serializable } from 'Framework/Components/Panel.Commons';
import { Component, UIElement } from 'Framework/Components/UIElement';
import { CreateElement, VNode } from 'vue';

@Serializable()
@Component({
    name: 'x-grid',
    components: {
        GridItem
    }
})
export default class XGrid extends UIElement {
    public render(createElement: CreateElement, context: any): VNode {
        let children = null;
        if (this.$slots.default) {
            children = this.$slots.default.map(vnode => {
                if ((vnode.componentOptions && vnode.componentOptions.Ctor.prototype.hasOwnProperty('_gridItemMixin')) || !vnode.tag) {
                    return vnode;
                }
                else {
                    return createElement(GridItem, {
                        props: vnode.data.attrs
                    }, [vnode]);
                }
            });
        }
        let result = createElement(
            'div', // nom de balise
            {
                class: 'x-grid',
                style: this.computedGridStyle
            },
            //children
            children
        );
        return result;
    }

    public get computedGridStyle(): any {
        return {
            'grid-columns': this.columnsDefinition(),
            'grid-template-columns': this.columnsDefinition(),
            'grid-rows': this.rowsDefinition(),
            'grid-template-rows': this.rowsDefinition(),
            'grid-column-gap': this._columnGap + 'px',
            'grid-row-gap': this._rowGap + 'px',
            ...this.computedStyle
        };
    }

    public get children(): Array<Vue> {
        return this.$children;
    }

    private measurementVerification(value) {
        let measurementVerifRegex = XGrid.MeasurementVerifRegex;
        if (measurementVerifRegex.test(value)) {
            // TODO : Reactiver quand le probleme de split (ci-dessous) sera réglé (ou pas suivant la décision prise)
            // return `${value.replace(this.getRegex, '$2$4')} `;
            return value;
        }
        return null;
    }

    private columnsDefinition(): string {
        let columnDefinitionResult = '';

        if (this._columnDefinitions) {
            let def = this._columnDefinitions.split(' ');
            for (let i = 0; i < def.length; i++) {
                switch (def[i]) {
                    case '*': {
                        columnDefinitionResult += 'minmax(0px, 1fr) ';//'1fr ';
                        break;
                    }
                    case 'auto': {
                        columnDefinitionResult += 'auto ';
                        break;
                    }
                    default: {
                        let verifiedMeasurement = this.measurementVerification(def[i]);
                        if (verifiedMeasurement) {
                            columnDefinitionResult += verifiedMeasurement;
                            break;
                        } else {
                            throw new Error(
                                `Error : wrong value on columnDefinition at parameter ${i + 1}`
                            );
                        }
                    }
                }
            }
        } else {
            for (let i = 0; i < this._columnsCount; i++) {
                columnDefinitionResult += 'minmax(0px, 1fr) ';//'1fr ';
            }
        }
        return columnDefinitionResult.trim();
    }

    private rowsDefinition(): string {
        let rowDefinitionResult = '';

        if (this._rowDefinitions) {
            //TODO : Split seulement this.rowDefinitions sur les espaces qui ne sont pas suivis par 'px', '%' ou 'em'. Piste : 'new RegExp('\s*(?!((px)|(\%)|(em)))'
            let def = this._rowDefinitions.split(' ');
            for (let i = 0; i < def.length; i++) {
                switch (def[i]) {
                    case '*': {
                        rowDefinitionResult += 'minmax(0px, 1fr) ';//'1fr ';
                        break;
                    }
                    case 'auto': {
                        rowDefinitionResult += 'auto ';
                        break;
                    }
                    default: {
                        let verifiedMeasurement = this.measurementVerification(def[i]);
                        if (verifiedMeasurement) {
                            rowDefinitionResult += verifiedMeasurement;
                            break;
                        } else {
                            throw new Error(
                                `Error : wrong value on rowDefinition at parameter ${i + 1}`
                            );
                        }
                    }
                }
            }
        } else {
            for (let i = 0; i < this._rowsCount; i++) {
                rowDefinitionResult += 'minmax(0px, 1fr) ';//'1fr ';
            }
        }
        return rowDefinitionResult.trim();
    }

    private static MeasurementVerifRegex: RegExp = new RegExp(
        '(([0-9]+)( )?((px)|(%)|(em)))'
    );

    @PropSyncWithInternal('rowDefinitions', { type: String, required: false, default: null })
    public _rowDefinitions: string;
    @PropSyncWithInternal('columnDefinitions', { type: String, required: false, default: null })
    public _columnDefinitions: string;
    @PropSyncWithInternal('rowsCount', { type: Number, required: false, default: 1 })
    public _rowsCount: number;
    @PropSyncWithInternal('columnsCount', { type: Number, required: false, default: 1 })
    public _columnsCount: number;
    @PropSyncWithInternal('columnGap', { type: Number, required: false, default: 0 })
    public _columnGap: number;
    @PropSyncWithInternal('rowGap', { type: Number, required: false, default: 0 })
    public _rowGap: number;
}
