//load polyfills before all
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'pepjs';
//end of polyfill
import ts from 'ts-nameof';
import { serviceProvider } from 'App/Inversify.config';

import '@fortawesome/fontawesome-pro/css/all.css';

import '@fortawesome/fontawesome-pro/sprites/brands.svg';
import '@fortawesome/fontawesome-pro/sprites/duotone.svg';
import '@fortawesome/fontawesome-pro/sprites/light.svg';
import '@fortawesome/fontawesome-pro/sprites/regular.svg';
import '@fortawesome/fontawesome-pro/sprites/solid.svg';
import '@fortawesome/fontawesome-pro/sprites/thin.svg';

import Vue from 'vue';
import vuetify from 'App/Plugins/vuetify';
import Sbs from 'App/Plugins/sbs';
import { defineCustomElements } from '@peculiar/certificates-viewer/loader';
import { debounce, throttle } from 'lodash';
import 'moment/locale/fr';

import { LocalizerService } from 'Api/LocalizerService';
import { BasePage } from 'App/Areas/BasePage';
import { StandaloneWrapper } from 'App/Areas/Standalone/StandaloneWrapper';

declare module 'vue/types/vue' {
    interface Vue {
        localizer: (key: string, ...params: Array<string | number>) => string;
        $debounce(func: Function, wait: number, options?: { leading: boolean, trailing: boolean, maxWait: number });
        $throttle(func: Function, wait: number, options?: { leading: boolean, trailing: boolean });
    }
}
const baseElement = document.getElementById('base') as HTMLBaseElement;

if (baseElement) {
    __webpack_public_path__ = baseElement.href;
}

import OpenSeadragon from 'openseadragon';
import { dockSpawnPlugin } from 'App/Plugins/dockspawn';

declare module 'openseadragon' {
    interface Viewport {
        viewer: OpenSeadragon.Viewer;
        viewportToSvgCoordinates(point: OpenSeadragon.Point): OpenSeadragon.Point;
        svgToViewportCoordinates(point: OpenSeadragon.Point, svg: any): OpenSeadragon.Point;
    }
}

OpenSeadragon.Viewport.prototype.viewportToSvgCoordinates = function (point: OpenSeadragon.Point): OpenSeadragon.Point {
    const source: OpenSeadragon.TileSource = this.viewer.world.getItemAt(0).source;
    const dimensions: OpenSeadragon.Point = source.dimensions;
    const width = dimensions.x;
    const height = dimensions.y;

    point = this.viewportToImageCoordinates(point);
    let pX = point.x / width * this.viewer.container.clientWidth;
    let pY = point.y / height * this.viewer.container.clientWidth * (height / width);

    return new OpenSeadragon.Point(pX, pY);
};

OpenSeadragon.Viewport.prototype.svgToViewportCoordinates = function (point: OpenSeadragon.Point, svg: any): OpenSeadragon.Point {
    let width: number = svg
        ? svg.width()
        : this.viewer.container.clientWidth;

    const source: OpenSeadragon.TileSource = this.viewer.world.getItemAt(0).source;
    const dimensions: OpenSeadragon.Point = source.dimensions;
    const viewerWidth = dimensions.x;
    const viewerheight = dimensions.y;

    let pX = point.x / width * viewerWidth;
    let pY = point.y / (width * (viewerheight / viewerWidth)) * viewerheight;

    return this.imageToViewportCoordinates(new OpenSeadragon.Point(pX, pY));
};

Vue.prototype.localizer = function (traductionKey: string, ...params) {
    traductionKey = (traductionKey.charAt(0).toUpperCase() + traductionKey.slice(1))
        .split('.')
        .reduce((prev, curr) => prev + '.' + curr.charAt(0).toUpperCase() + curr.slice(1));

    return this.$vuetify.lang.t(`$vuetify.${traductionKey}`, ...params);
}

Vue.prototype.$debounce = debounce;
Vue.prototype.$throttle = throttle;
Vue.config.ignoredElements = [
    'peculiar-certificate-viewer'
];

defineCustomElements();

async function main() {
    if (window['cordova']) {
        //Temporary Fix : platform support Electron
        if (cordova.platformId == 'electron') {
            cordova.platformId = 'browser';
        }
        const standaloneWrapper = new StandaloneWrapper();

        await standaloneWrapper.initializeAsync();
        window['standaloneWrapper'] = standaloneWrapper;
        window['appContextBuilder'] = standaloneWrapper.appContextBuilder.bind(standaloneWrapper);
    }

    const localizerService = serviceProvider.get<LocalizerService>(LocalizerService);
    const localizerPromise = localizerService.loadTranslationAsync(localizerService.codeCulture);

    Vue.use(Sbs);

    Vue.use(dockSpawnPlugin, {
        localizer: key =>
            localizerService.getTemplateString(`JS.Framework.Controls.XDockSpawn.${key}`)
    });

    BasePage.RegisterPage('AccountLoginPage', () => import(/* webpackChunkName: "account" */ 'App/Areas/Account/LoginPage.vue'));
    BasePage.RegisterPage('AccountRegisterPage', () => import(/* webpackChunkName: "account" */ 'App/Areas/Account/RegisterPage.vue'));
    BasePage.RegisterPage('AccountForceResetPasswordPage', () => import(/* webpackChunkName: "account" */ 'App/Areas/Account/ForceResetPasswordPage.vue'));
    BasePage.RegisterPage('AccountResetPasswordPage', () => import(/* webpackChunkName: "account" */ 'App/Areas/Account/ResetPasswordPage.vue'));
    BasePage.RegisterPage('AccountForgotPasswordPage', () => import(/* webpackChunkName: "account" */ 'App/Areas/Account/ForgotPasswordPage.vue'));

    BasePage.RegisterPage('PublicCookiesPage', () => import(/* webpackChunkName: "public" */ 'App/Areas/Public/CookiesPage.vue'));
    BasePage.RegisterPage('PublicTermsAndConditionsPage', () => import(/* webpackChunkName: "public" */ 'App/Areas/Public/TermsAndConditionsPage.vue'));
    BasePage.RegisterPage('HomeErrorPage', () => import(/* webpackChunkName: "public" */ 'App/Areas/Home/ErrorPage.vue'));
    BasePage.RegisterPage('HomeStatusCodePagePage', () => import(/* webpackChunkName: "public" */ 'App/Areas/Home/StatusCodePage.vue'));

    BasePage.RegisterPage('Spa', () => import(/* webpackChunkName: "spa" */ 'App/Areas/Shares/SpaIndexPage.vue'));
    BasePage.RegisterPage('StandaloneHomeIndexPage', () => import(/* webpackChunkName: "standalone" */ 'App/Areas/Standalone/IndexPage.vue'));
    BasePage.RegisterPage('StandaloneHomeWatchPage', () => import(/* webpackChunkName: "standalone" */ 'App/Areas/Standalone/WatchPage.vue'));

    BasePage.RegisterPage('ProfileProfileIndexPage', () => import(/* webpackChunkName: "profile" */ 'App/Areas/Profile/IndexProfilePage.vue'));
    BasePage.RegisterPage('ProfileProfilePreferencesPage', () => import(/* webpackChunkName: "profile" */ 'App/Areas/Profile/PreferencesPage.vue'));
    BasePage.RegisterPage('ProfileProfileAccountPage', () => import(/* webpackChunkName: "profile" */ 'App/Areas/Profile/AccountPage.vue'));
    BasePage.RegisterPage('ProfileProfileTokensPage', () => import(/* webpackChunkName: "profile" */ 'App/Areas/Profile/TokensPage.vue'));
    BasePage.RegisterPage('ProfileProfileEmailPage', () => import(/* webpackChunkName: "profile" */ 'App/Areas/Profile/EmailPage.vue'));
    BasePage.RegisterPage('ProfileProfileExperimentalFeaturesPage', () => import(/* webpackChunkName: "profile" */ 'App/Areas/Profile/ExperimentalFeaturesPage.vue'));
    BasePage.RegisterPage('ProfilePublicIndexPage', () => import(/* webpackChunkName: "profile" */ 'App/Areas/Profile/Public/IndexPage.vue'));

    BasePage.RegisterPage('ExploreHomeDetailEmbedPage', () => import(/* webpackChunkName: "embed" */ 'App/Areas/Embed/IndexPage.vue'));
    BasePage.RegisterPage('ProjectHomeIndexPage', () => import(/* webpackChunkName: "project" */ 'App/Areas/Project/IndexPage.vue'));
    BasePage.RegisterPage('ProjectHomeEditPage', () => import(/* webpackChunkName: "project" */ 'App/Areas/Project/EditProject.vue'));

    BasePage.RegisterPage('ExploreHomeIndexPage', () => import(/* webpackChunkName: "explore" */ 'App/Areas/Explore/IndexPage.vue'));
    BasePage.RegisterPage('ExploreHomeDetailExplorePage', () => import(/* webpackChunkName: "explore" */ 'App/Areas/Explore/DetailsExplore.vue'));
    BasePage.RegisterPage('ExploreHomePreviewPage', () => import(/* webpackChunkName: "explore" */ 'App/Areas/Explore/DetailsExplore.vue'));

    BasePage.RegisterPage('DriveHomeIndexPage', () => import(/* webpackChunkName: "drive" */ 'App/Areas/Drive/IndexPage.vue'));

    BasePage.RegisterPage('AdministratorPortalGeneralSettingsIndexPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/GeneralSettings/IndexPage.vue'));
    BasePage.RegisterPage('AdministratorPortalMailSettingsIndexPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/MailSettings/IndexPage.vue'));
    BasePage.RegisterPage('AdministratorPortalDriveSettingsIndexPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/DriveSettings/IndexPage.vue'));
    BasePage.RegisterPage('AdministratorPortalSecuritySettingsIndexPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/SecuritySettings/IndexPage.vue'));


    BasePage.RegisterPage('AdministratorPortalPlatformIndexPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/Platforms/IndexPage.vue'));
    BasePage.RegisterPage('AdministratorPortalPlatformAddPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/Platforms/AddPage.vue'));
    BasePage.RegisterPage('AdministratorPortalPlatformEditPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/Platforms/EditPage.vue'));
    BasePage.RegisterPage('AdministratorPortalDomainNameSystemIndexPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/DomainNameSystem/IndexPage.vue'));
    BasePage.RegisterPage('AdministratorPortalGroupsIndexPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/Groups/IndexPage.vue'));
    BasePage.RegisterPage('AdministratorPortalUsersIndexPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/Users/IndexPage.vue'));
    BasePage.RegisterPage('AdministratorPortalUsersAddPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/Users/AddPage.vue'));
    BasePage.RegisterPage('AdministratorPortalUsersEditPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/Users/EditPage.vue'));
    BasePage.RegisterPage('AdministratorPortalSubscriptionIndexPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/Subscription/IndexPage.vue'));
    BasePage.RegisterPage('AdministratorPortalSubscriptionAddPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/Subscription/AddPage.vue'));
    BasePage.RegisterPage('AdministratorPortalSubscriptionEditPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/Subscription/EditPage.vue'));
    BasePage.RegisterPage('AdministratorPortalRolesIndexPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/Roles/IndexPage.vue'));
    BasePage.RegisterPage('AdministratorPortalRolesAddPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/Roles/AddPage.vue'));
    BasePage.RegisterPage('AdministratorPortalRolesEditPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/Roles/EditPage.vue'));
    BasePage.RegisterPage('AdministratorPortalIdentityProvidersIndexPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/IdentityProviders/IndexPage.vue'));
    BasePage.RegisterPage('AdministratorPortalLoginSettingsIndexPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/LoginSettings/IndexPage.vue'));
    BasePage.RegisterPage('AdministratorPortalStatisticsRequestsPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/Statistics/RequestPage.vue'));
    BasePage.RegisterPage('AdministratorPortalTagsIndexPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/Tags/IndexPage.vue'));
    BasePage.RegisterPage('AdministratorPortalAuditLogsIndexPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/AuditLogs/IndexPage.vue'));
    BasePage.RegisterPage('AdministratorPortalWorkersTasksPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/Workers/TasksPage.vue'));
    BasePage.RegisterPage('AdministratorPortalWorkersWorkersPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/Workers/WorkersPage.vue'));
    BasePage.RegisterPage('AdministratorPortalWorkersConfigurationPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/Workers/ConfigurationPage.vue'));
    BasePage.RegisterPage('AdministratorPortalProjectsTemplatesPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/Projects/TemplatesPage.vue'));
    BasePage.RegisterPage('AdministratorPortalProjectsJsonSchemasPage', () => import(/* webpackChunkName: "administratorPortal" */ 'App/Areas/AdministratorPortal/Projects/JsonSchemasPage.vue'));

    BasePage.RegisterPage('VideoHomeListPage', () => import(/* webpackChunkName: "video" */ 'App/Areas/Video/ListPage.vue'));
    BasePage.RegisterPage('VideoHomeEditPage', () => import(/* webpackChunkName: "video" */ 'App/Areas/Video/EditPage.vue'));
    BasePage.RegisterPage('VideoExploreIndexPage', () => import(/* webpackChunkName: "video" */ 'App/Areas/Video/ExplorePage.vue'));
    BasePage.RegisterPage('VideoExploreWatchPage', () => import(/* webpackChunkName: "video" */ 'App/Areas/Video/WatchPage.vue'));

    await localizerPromise;

    const app = await BasePage.InitializeFromBody(vuetify);

    BasePage.ConfigureVuetifyLocalizer(app.$vuetify, localizerService);
}
main().catch((e) => console.error(e));


