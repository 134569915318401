import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.mapProvider)?_c(VCard,{staticClass:"fill-height",attrs:{"tile":""}},[_c('x-grid',{attrs:{"row-definitions":"* auto"}},[_c('div',{attrs:{"row":1}},[_c('l-map',{ref:"leafletMap",style:({
                        height: _vm.height,
                        width: _vm.width,
                        cursor: _vm.readonly ? 'grab' : 'crosshair',
                        zIndex: 0
                    }),attrs:{"options":{zoomSnap: 0.1},"zoom":_vm.mapProvider.zoom,"center":_vm.center,"min-bounds":_vm.mapProvider.maxBounds,"max-zoom":_vm.mapProvider.maxZoom,"min-zoom":_vm.mapProvider.minZoom,"crs":_vm.mapProvider.crs},on:{"click":function($event){!_vm.readonly && _vm._onLocationClick($event)},"mousemove":_vm._onMouseMove,"ready":_vm._mapReady}},[_c(_vm.mapProvider.contentTemplate,_vm._b({tag:"component",on:{"load":function($event){_vm.mapProvider.onLoaded && _vm.mapProvider.onLoaded($event)}}},'component',_vm.mapProvider.dataContext,false)),(!_vm.readonly && _vm.mapProvider.geoSearchOptions)?_c('l-geosearch',{attrs:{"options":Object.assign(_vm.mapProvider.geoSearchOptions, { showMarker: false })}}):_vm._e(),_vm._l((_vm.itemsSource.filter(function (p) { return p.location !== null; })),function(project){return _c('l-marker',{key:project.projectId,attrs:{"lat-lng":_vm._invertLngLat(project.location)},on:{"click":function($event){return _vm._onNavigateTo(project)}}},[_c('l-tooltip',{attrs:{"options":{direction: 'top'}}},[_c(VCard,{attrs:{"flat":"","hover":"","tile":""}},[_c(VImg,{attrs:{"aspect-ratio":"16/9","height":"132px","width":"234px","lazy-src":_vm.uriService.getAbsoluteUri(("/" + (_vm._appContext.codeCulture) + "/asset/preview/00000000-0000-0000-0000-000000000000")),"src":_vm.uriService.getAbsoluteUri(("/" + (_vm._appContext.codeCulture) + "/asset/preview/" + ((project.thumbnail && project.thumbnail.guid)? project.thumbnail.guid : project.thumbnail)))}}),_c(VCardTitle,[_vm._v(_vm._s(project.name || project.assetProject.name))])],1)],1)],1)})],2)],1),(_vm.displayCoordinates)?_c(VCardText,{staticClass:"py-0 text-overline",attrs:{"row":2}},[_vm._v(" "+_vm._s(_vm.mapProvider.formatCurrentCoordinates(_vm.currentCoordinates))+" ")]):_vm._e()],1)],1):_c(VContainer,[_c(VRow,[_c(VCol,[_c(VAlert,{attrs:{"type":"warning","text":""}},[_vm._v(" "+_vm._s(_vm.localizer((_vm.translationPath + ".NoProvider")))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }