import { ICartesianCoordinates, ICoordinates } from 'Framework/Components/Controls/XView/Core';
import { Component, Prop, UIElement, Watch } from 'Framework/Components/UIElement';
import { MathHelper } from 'Framework/Helpers/MathHelper';

@Component({
    name: 'x-compass'
})
export default class Compass extends UIElement {
    @Prop({ type: Number, default: 1 })
    public scale: number;
    @Prop({ type: Number, default: null })
    public north: number;
    @Prop({ type: Object, default: null })
    public currentOrientation: ICoordinates;
    @Prop({ type: Object, default: null })
    public coordinates: any;
    @Prop({ type: String, default: null })
    public configuration: string;
    @Prop({ type: Number, default: null })
    public currentFocale: number;

    public currentNorth: number = null;

    @Watch('currentOrientation')
    private _onCurrentOrientationChanged(newValue: ICoordinates): void {
        if (!newValue) {
            return;
        }

        if (MathHelper.isSphericalCoordinates(newValue)) {
            newValue = MathHelper.toCartesianCoordinates(newValue);
        }

        const coordinates = newValue as ICartesianCoordinates;
        this.currentNorth = Math.atan2(coordinates.y, coordinates.x);
    }

    public get transformScale(): string {
        return `scale(${this.scale})`;
    }

    public get transformRotate(): string {
        if (this.compassX && this.compassY) {
            let rotation = this.north - this.currentNorth;
            rotation = rotation / Math.PI * 180;
            //Rotation svg en ° par défaut
            //Sens anti trigonometrique
            return `rotate(${rotation}, ${this.compassX}, ${this.compassY})`;
        }
        return '';

    }

    public get compassX(): number {
        return this.coordinates?.x;
    }

    public get compassY(): number {
        return this.coordinates?.y;
    }

    public get fovPath(): string {
        if (this.compassX && this.compassY) {
            let anglePoint = this._radianToCartesian((Math.PI / 2) + this.currentFocale / 2);
            let refPoint = this._radianToCartesian((Math.PI / 2) - this.currentFocale / 2);

            return `M ${refPoint.x} ${refPoint.y}
                A ${this.radius} ${this.radius}, 0, 0, 0, ${anglePoint.x} ${anglePoint.y}
                L ${this.compassX} ${this.compassY}
                Z`;
        }
        return '';
    }

    public get radius(): number {
        return 25 / this.scale;
    }

    public get parsedConfiguration(): object {
        if (this.configuration) {
            return JSON.parse(this.configuration);
        }
        return {};
    }

    private _radianToCartesian(angle: number): { x: number, y: number } {
        return {
            x: this.compassX + (this.radius * Math.cos(angle)),
            y: this.compassY - (this.radius * Math.sin(angle))
        };
    }
}
