import { ILoginResponse } from 'Api/Dto/Authentication';
import { IPlatformSettings } from 'Api/Dto/PlatformSettings.public';
import { MenuStateType } from 'Api/Enums/MenuStateType';
import { SocialNetworks } from 'Api/Enums/SocialNetworks';
import { ViewerStateType } from 'Api/Enums/ViewerStateType';
import User from 'Api/User';
import { injectable } from 'inversify';
import 'reflect-metadata';
import { Vue } from 'vue-property-decorator';

export interface IAppInformation {
    informationalVersion: string;
    copyright: string;
    version: string;
}

export interface IAppLogin extends ILoginResponse {
    apiUri: string;
}

export enum ExperimentalFeature {
    BabylonProjectMapEditor = 'BabylonProjectMapEditor'
}

export class ExperimentalFeatures {
    public hasFlag(feature: ExperimentalFeature): boolean {
        this._loadFeatures();

        return this._features.has(feature);
    }

    public setFlag(feature: ExperimentalFeature, value: boolean): void {
        this._loadFeatures();

        if (value) {
            this._features.add(feature);
        }
        else {
            this._features.delete(feature);
        }

        this._save();
    }

    private _loadFeatures(): void {
        if (!this._isLoaded) {
            const features: Array<ExperimentalFeature> = JSON.parse(localStorage.getItem(ExperimentalFeatures._key)) ?? [];
            this._features = new Set<ExperimentalFeature>(features);

            this._isLoaded = true;
        }
    }

    private _save(): void {
        const features = Array.from(this._features);
        localStorage.setItem(ExperimentalFeatures._key, JSON.stringify(features));
    }

    private _features: Set<ExperimentalFeature>;
    private _isLoaded: boolean = false;

    private static readonly _key = 'Sbs.Vison.Experimental';
}

@injectable()
export class AppContext {
    constructor() {
        this.user = new User();

        if (this.login) {
            this.user = User.fromLoginResponse(this.login);
            this.apiUri = this.login.apiUri;
        }

        this.settings = {
            general: {
                availableSocialNetworks: SocialNetworks.None,
                isMenuStateOverridable: false,
                isThemeOverridable: false
            },
            explore: {
                defaultViewerState: ViewerStateType.Normal,
                isMapVisible: false,
                isDefaultViewerStateOverridable: false
            },
            drive: {
                forbiddenExtensions: [],
                maxFileSize: 0
            },
            login: {
                allowCreateAccount: false,
                allowPasswordReset: false,
                allowSelfDelete: false
            },
            mapProvider: null,
            dialogs: {
                small: {
                    width: '35vw',
                    height: '35vh'
                },
                medium: {
                    width: '50vw',
                    height: '50vh'
                },
                large: {
                    width: '80vw',
                    height: '80vh'
                },
                auto: {
                    width: 'auto',
                    height: 'auto'
                }
            }
        };
    }

    public get isStandalone(): boolean {
        return this.standaloneWrapper != null;
    }

    public get standaloneWrapper(): any {
        return window['standaloneWrapper'] ?? null;
    }

    public get login(): IAppLogin {
        let login = localStorage.getItem('login')

        if (!login) {
            return null;
        }

        const result = JSON.parse(login) as IAppLogin;

        let dateNow = new Date();
        if (!result || dateNow > new Date(result.expiration)) {
            return null;
        }

        return result;
    }

    public set login(value: IAppLogin) {
        if (value) {
            localStorage.setItem('login', JSON.stringify(value));
            this.user = User.fromLoginResponse(value);
        }
        else {
            localStorage.removeItem('login');
            this.user = Vue.observable(new User());
        }
    }

    public get isDarkTheme(): boolean {
        return this.theme.startsWith('dark-');
    }

    public codeCulture: string = null;

    public baseUri: string | (() => string) = '';

    public apiUri: string;

    public homePage: string = null;

    public platformId?: number = null;

    public subscriptionId?: number = null;

    public information: IAppInformation = null;


    public user: User = null;

    public isMobile: boolean = false;

    public theme: string = null;

    public antiForgeryToken: string = null;

    public menuState: MenuStateType = MenuStateType.Open;

    public settings: IPlatformSettings;

    public subscriptionPlugins: { [name: string]: { isEnabled: boolean, options: string } } = {};

    public isDedicatedPlatform: boolean;

    public organizationName: string;

    public experimentalFeatures: ExperimentalFeatures = new ExperimentalFeatures();
}
