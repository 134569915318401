import { AppContext } from 'Api/AppContext';
import { HttpClient, HttpVerb } from 'Api/HttpClient';
import { IVisonUriService } from 'App/Services/UriServices/Core';
import { injectTypes } from 'App/injectTypes';
import { inject, injectable } from 'inversify';
import urlJoin from 'url-join';

@injectable()
export class VisonHttpClient extends HttpClient {
    public constructor(@inject(AppContext) appContext: AppContext, @inject(injectTypes.IVisonUriService) uriService: IVisonUriService) {
        super(uriService);

        this._appContext = appContext;
        this.antiForgeryToken = this._appContext.antiForgeryToken;
    }

    protected buildUri(baseUri: string, relativeUri: string): string {
        if (baseUri) {
            return urlJoin(baseUri, relativeUri);
        }

        return /^\/?api\//.exec(relativeUri)
            ? relativeUri
            : urlJoin(this._appContext.codeCulture, relativeUri);
    }

    protected _initializeInitRequest(method: HttpVerb, body: any = null, headers: Headers = null): RequestInit {
        headers = headers ?? new Headers();

        if (!headers.has('Accept-Language')) {
            headers.set('Accept-Language', this._appContext.codeCulture);
        }

        this.bearerToken = this._appContext.login?.token ?? null;

        return super._initializeInitRequest(method, body, headers);
    }

    protected readonly _appContext: AppContext;
}
