import { Tag } from 'Api/Contracts/Dtos';
import { Component, Prop, UIElement } from 'Framework/Components/UIElement';
import { VCombobox, VList, VChip, VIcon } from 'vuetify/lib';

//import { VCombobox } from 'vuetify/lib';

@Component({
    name: 'x-tagger',
    components: {
        VCombobox,
        VList,
        VChip,
        VIcon
    }
})
export default class Tagger extends UIElement {
    public onSearchTag(search: string): void {        
        this.searchTag = search;

        this.$emit('text-changed', search);
    }

    private _onSelectionChanged(newValue: Array<string | Tag>): void {
        const oldValue = this.selectedItems;

        if (newValue.length <= oldValue.length) {
            return;
        }

        let newSelection: Array<Tag> = newValue.map(v => {
            if (v instanceof Tag) {
                return v;
            }
            else if (typeof v === 'string') {
                let tag: Tag = new Tag();
                tag.name = v;

                return tag;
            }
            else if(typeof v === 'object') {
                let tag: Tag = v as Tag;

                return tag;
            }
            else {
                throw Error('Not Supported Type');
            }
        });

        newSelection = newSelection.filter((v, i, array) => 
            array.findIndex((v1, i1) => this._tagComparator(array[i], array[i1])) == i
        );

        newSelection.forEach(element => {
            let found = this.selectedItems.find(i => i == element);

            if(!found) {
                this.selectedItems.push(element);
            }
        });

        this.searchTag = null;
        (this.$refs.comboBox as any).internalSearch = '';
        this.$emit('selection-changed', this.selectedItems);
    }

    private _onRemoveTag(item: Tag): void {
        let index = this.selectedItems.findIndex(v => v == item);
        this.selectedItems.splice(index, 1);

        this.$emit('selection-changed', this.selectedItems);
        this.$emit('tag-removed', item);
    }

    private _tagComparator(tagA: Tag | string, tagB: Tag | string): boolean {
        let tagNameA = null;
        let tagNameB = null;

        if (tagA instanceof Tag) {
            tagNameA = tagA.name.toLowerCase();
        }
        else {
            tagNameA = tagA;
        }

        if (tagB instanceof Tag) {
            tagNameB = tagB.name.toLowerCase();
        }
        else {
            tagNameB = tagB;
        }

        return tagNameA == tagNameB;
    }

    public get hasSearchResults(): boolean {
        return this.searchResults && this.searchResults.length > 0;
    }

    public onDebounceSearchTag: any 
        = this.$debounce((search: string) => this.onSearchTag(search), 500);

    public searchTag: string = null;

    @Prop({ type: Array, required: false, default: () => [] })
    public selectedItems: Array<Tag>;

    @Prop({ type: Boolean, required: false, default: false })
    public isBusy: boolean;

    @Prop({ type: Array, required: false, default: () => [] })
    public searchResults: Array<Tag>;
}
