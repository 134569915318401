import { injectable } from 'inversify';

@injectable()
export class PasswordValidationService {
    public isValidLength(password: string): boolean {
        return (password && password.length >= 8);
    }

    public isValidNumber(password: string): boolean {
        return (/[0-9]/g).test(password);
    }

    public isValidCaps(password: string): boolean {
        return (/[A-Z]/g).test(password);
    }

    public isSamePassword(password: string, confirmPassword: string): boolean {
        return password === confirmPassword;
    }
}
