/// <reference path="../../../../../node_modules/cordova-plugin-file/types/index.d.ts" />

export const isDirectoryEntry = (entry: Entry): entry is DirectoryEntry => entry?.isDirectory === true;

export class CordovaFileError extends Error {
    constructor(error: FileError) {
        super(`Cordova file error with code ${error.code}`, { cause: error });
    }
}

export class FileStorageHelper {
    constructor(fileSystem: LocalFileSystem) {
        this._fileSystemEntryPoint = fileSystem;
    }

    public async initializeAsync(): Promise<void> {
        if (!this._fs) {
            this._fs = await this._getFileSystemAsync(this._fileSystemEntryPoint);

            if (this._fileSystemEntryPoint == LocalFileSystem.PERSISTENT) {
                cordova.file.dataDirectory = (this._fs.root as any).toURL();
            }
            else {
                cordova.file.cacheDirectory = (this._fs.root as any).toURL();
            }
            if (cordova.file.applicationDirectory.startsWith('file:')) {
                let applicationDirectory = `${window.location.origin}${window.location.pathname}`;

                let index = applicationDirectory.lastIndexOf('/');
                cordova.file.applicationDirectory = applicationDirectory.substring(0, index);
            }

            console.log('file system open: ' + this._fs.name);
        }
    }

    public isFileExistsAsync(path: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            window.resolveLocalFileSystemURL(
                path,
                entry => {
                    resolve(true);
                },
                error => {
                    resolve(false);
                }
            );
        });
    }

    public async deleteAsync(entry: Entry): Promise<void> {
        if (entry.isDirectory) {
            const entries = await this.getEntriesAsync(entry as DirectoryEntry);

            for (const subEntry of entries) {
                await this.deleteAsync(subEntry);
            }
        }

        return new Promise((resolve, reject) => {
            entry.remove(
                () => resolve(),
                error => reject(new CordovaFileError(error))
            );
        });
    }

    public async getEntriesAsync(directory: DirectoryEntry): Promise<Array<Entry>> {
        let reader = directory.createReader();
        let results = new Array<Entry>();
        let done = false;

        do {
            const entries = await this._getEntriesAsync(reader);
            done = entries.length == 0;
            results.push(...entries);
        }
        while (!done);

        results.forEach(entry => entry.fileHelper = this);
        return results;
    }

    private _getEntriesAsync(directoryReader: DirectoryReader): Promise<Array<Entry>> {
        return new Promise((resolve, reject) => {
            directoryReader.readEntries(
                entries => {
                    resolve(entries);
                },
                error => {
                    reject(new CordovaFileError(error));
                }
            );
        });
    }

    public getFileEntryAsync(path: string): Promise<Entry> {
        return new Promise((resolve, reject) => {
            window.resolveLocalFileSystemURL(
                path,
                entry => {
                    entry.fileHelper = this;
                    resolve(entry);
                },
                error => {
                    if (error.code === FileError.SYNTAX_ERR
                        || error.code === FileError.NOT_FOUND_ERR) {
                        resolve(null);
                    }
                    else {
                        reject(new CordovaFileError(error));
                    }
                }
            );
        });
    }

    public async createDirectoryAsync(destination: DirectoryEntry, directoryPath: string): Promise<DirectoryEntry> {
        let directories = directoryPath.split('/');
        let currentDirectory = destination;

        if (directories.length > 1) {
            for (let directoryName of directories) {
                currentDirectory = await this.createDirectoryAsync(currentDirectory, directoryName);
            }
            return currentDirectory;
        }
        else {
            return new Promise((resolve, reject) => {
                currentDirectory.getDirectory(
                    directories[0],
                    { create: true, exclusive: false },
                    directoryEntry => {
                        directoryEntry.fileHelper = this;
                        resolve(directoryEntry);
                    },
                    error => {
                        reject(new CordovaFileError(error));
                    }
                );
            });
        }
    }

    /**
     * Creates a new file or returns the file if it already exists.
     * @param folder 
     * @param fileName 
     * @param override override file if exists
     */
    public async createFileAsync(folder: DirectoryEntry, fileName: string, override: boolean = true): Promise<FileEntry> {
        if (override) {
            const filePath: string = `${cordova.file.dataDirectory}${folder.fullPath}/${fileName}`;
            const fileEntry: Entry = await this.getFileEntryAsync(filePath);

            if (fileEntry) {
                await this.deleteAsync(fileEntry);
            }
        }

        return new Promise((resolve, reject) => {
            folder.getFile(
                fileName,
                { create: true, exclusive: override },
                fileEntry => {
                    fileEntry.fileHelper = this;
                    resolve(fileEntry);
                },
                error => {
                    reject(new CordovaFileError(error));
                }
            );
        });
    }

    public async readFileAsync(file: FileEntry | string, mode: 'string' | 'object' | 'Array'): Promise<string | any | ArrayBuffer> {
        if (typeof (file) === 'string') {
            let fileEntry = await this.getFileEntryAsync(file) as FileEntry;
            return await this._readFileEntryAsync(fileEntry, mode);
        }
        else {
            return await this._readFileEntryAsync(file, mode);
        }
    }

    private _readFileEntryAsync(fileEntry: FileEntry, mode: 'string' | 'object' | 'Array'): Promise<string | any | ArrayBuffer> {
        return new Promise((resolve, reject) => {
            fileEntry.file(
                file => {
                    let reader = new FileReader();
                    reader.onloadend = () => {
                        if (mode === 'object') {
                            resolve(JSON.parse(reader.result as string));
                        }
                        else {
                            resolve(reader.result);
                        }
                    };

                    if (mode === 'string' || mode === 'object') {
                        reader.readAsText(file);
                    }
                    else {
                        reader.readAsArrayBuffer(file);
                    }
                },
                error => {
                    reject(new CordovaFileError(error));
                }
            );
        });
    }

    public get Root(): DirectoryEntry {
        return this._fs
            ? this._fs.root as any
            : null;
    }

    private _getFileSystemAsync(type: LocalFileSystem): Promise<FileSystem> {
        return new Promise((resolve, reject) => {
            let w: any = window;

            if (w.initPersistentFileSystem) {
                w.initPersistentFileSystem(
                    FileStorageHelper._persistentFileQuotaByteSize,
                    () => {
                        console.log('Persistent fs quota granted');
                    },
                    error => {
                        console.error('Error occured while trying to request Persistent fs quota: ' + JSON.stringify(error));
                    }
                );
            }

            window.requestFileSystem(
                type,
                FileStorageHelper._persistentFileQuotaByteSize,
                fs => {
                    (fs.root as any).fileHelper = this;
                    resolve(fs);
                },
                error => {
                    reject(new CordovaFileError(error));
                }
            );
        });
    }

    public static async writeFileAsync(file: FileEntry, data: Blob | ReadableStream | ReadableStreamDefaultReader): Promise<void> {
        if (!data) {
            throw new Error('No data argument');
        }

        if (data instanceof Blob) {
            await FileStorageHelper._writeFileFromBlobAsync(file, data);
        }
        else if (data instanceof ReadableStream) {
            await FileStorageHelper._writeFileFromReadableStreamAsync(file, data);
        }
        else if (data instanceof ReadableStreamDefaultReader) {
            await FileStorageHelper._writeFileFromReadableStreamReaderAsync(file, data);
        }
    }

    private static async _writeFileFromReadableStreamAsync(file: FileEntry, data: ReadableStream): Promise<void> {
        let reader = data.getReader();

        await FileStorageHelper._writeFileFromReadableStreamReaderAsync(file, reader);
    }

    private static async _writeFileFromReadableStreamReaderAsync(file: FileEntry, reader: ReadableStreamDefaultReader): Promise<void> {
        let nextChunk: ReadableStreamReadResult<Uint8Array> = await reader.read();

        let writer: FileWriter = await FileStorageHelper._getFileWriterAsync(file);
        while (!nextChunk.done) {
            const value = nextChunk.value;

            await FileStorageHelper._writeAsync(writer, new Blob([value.buffer.slice(value.byteOffset, value.byteLength)]));
            nextChunk = await reader.read();
        }
    }

    private static _writeFileFromBlobAsync(file: FileEntry, data: Blob, offset: number = 0): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!data) {
                reject(new Error('No data argument'));
            }

            file.createWriter(
                fw => {
                    fw.seek(offset);
                    fw.onerror = (event) => reject(new Error('Error while writing', { cause: event }));
                    fw.onwriteend = (ev) => resolve();
                    fw.write(data);
                },
                error => {
                    reject(new CordovaFileError(error));
                }
            )
        });
    }

    private static _writeAsync(writer: FileWriter, data: Blob): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!data) {
                reject(new Error('No data argument'));
            }

            writer.onerror = (event) => reject(new Error('Error while writing', { cause: event }));
            writer.onwriteend = (ev) => resolve();
            writer.write(data);
        });
    }

    private static _getFileWriterAsync(file: FileEntry): Promise<FileWriter> {
        return new Promise((resolve, reject) => {
            file.createWriter(
                fw => {
                    resolve(fw);
                },
                error => {
                    reject(new CordovaFileError(error));
                }
            )
        });
    }

    private readonly _fileSystemEntryPoint: LocalFileSystem;
    private _fs: FileSystem = null;
    private static readonly _persistentFileQuotaByteSize: number = 1024 * 1024 * 1024 * 10;
}
