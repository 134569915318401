import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCombobox,{ref:"comboBox",attrs:{"value":_vm.selectedItems,"items":_vm.searchResults,"loading":_vm.isBusy,"hide-no-data":_vm.hasSearchResults,"item-text":"name","item-value":"tagId","hide-selected":"","no-filter":"","multiple":"","small-chips":""},on:{"input":_vm._onSelectionChanged,"update:search-input":_vm.onDebounceSearchTag},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c(VListItem,[_c('span',{staticClass:"subheading mr-1"},[_vm._t("no-data")],2),_c(VChip,{attrs:{"label":"","small":""}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("fa-solid fa-tag")]),_vm._v(_vm._s(_vm.searchTag)+" ")],1)],1)]},proxy:true},{key:"label",fn:function(){return [_vm._t("label")]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [(item === Object(item))?_c(VChip,_vm._b({attrs:{"label":"","small":"","close":"","close-icon":"fa-light fa-xmark"},on:{"click:close":function($event){return _vm._onRemoveTag(item)}}},'v-chip',item,false),[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("fa-solid fa-tag")]),_vm._v(_vm._s(item.name)+" ")],1):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VChip,_vm._b({attrs:{"label":"","small":""}},'v-chip',item,false),[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("fa-solid fa-tag")]),_vm._v(_vm._s(item.name)+" ")],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }