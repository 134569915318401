import { IIconService } from 'Api/Contracts/Interfaces';
import { injectable } from 'inversify';

@injectable()
export class IconService implements IIconService {
    public getRecentlyUsedIcons(): Array<string> {
        const json = localStorage.getItem(IconService.recentlyUsedLocalStorageKey);

        return json == null
            ? []
            : JSON.parse(json) as Array<string>;
    }

    public addRecentlyUsedIcon(icon: string): void {
        const icons = this.getRecentlyUsedIcons();
        const index = icons.indexOf(icon);

        if (index >= 0) {
            icons.splice(index, 1);
        }

        if (icons.unshift(icon) > IconService.recentlyUsedMaxSize) {
            icons.pop();
        }

        localStorage.setItem(
            IconService.recentlyUsedLocalStorageKey,
            JSON.stringify(icons)
        );
    }

    private static readonly recentlyUsedLocalStorageKey = 'Sbs.Vison.RecentlyUsedIcons';
    private static readonly recentlyUsedMaxSize = 20;
}
