import {
    AnalyticPermissions,
    AuditLogPermissions,
    DomainNameSystemPermissions,
    DrivePermissions,
    UserPermissions as EnumUserPermissions,
    GroupPermissions,
    IconPermissions,
    IdentityProviderPermissions,
    PlatformPermissions,
    ProjectPermissions,
    RolePermissions,
    SettingPermissions,
    SmartObjectPermissions,
    SubscriptionPermissions,
    SystemInformationPermissions,
    TagPermissions,
    WorkerPermissions
} from 'Api/Enums/Permissions';

export class UserPermissions {
    public platform: PlatformPermissions;
    public subscription: SubscriptionPermissions;
    public role: RolePermissions;
    public drive: DrivePermissions;
    public user: EnumUserPermissions;
    public group: GroupPermissions;
    public project: ProjectPermissions;
    public setting: SettingPermissions;
    public analytic: AnalyticPermissions;
    public domainNameSystem: DomainNameSystemPermissions;
    public systemInformation: SystemInformationPermissions;
    public identityProvider: IdentityProviderPermissions;
    public auditLog: AuditLogPermissions;
    public tag: TagPermissions;
    public worker: WorkerPermissions;
    public icon: IconPermissions;
    public smartObject: SmartObjectPermissions;
}
