import { IAbsoluteUriBehavior } from 'Api/Contracts/Interfaces';
import { ArgumentNullException } from 'Api/Dto/Exception';
import FileStorageHelper from 'App/Areas/Standalone/FileStorageHelper';
import urlJoin from 'url-join';

export abstract class AbsoluteUriBehavior implements IAbsoluteUriBehavior {
    public getAbsoluteUri(baseUri: string, relativeUri: string, parameters?: Record<string, string>): string {
        if (!relativeUri) {
            throw new ArgumentNullException('relativeUri');
        }

        let url: URL = null;

        try {
            const urlString = relativeUri.startsWith('http')
                ? relativeUri
                : this._urlJoin(baseUri, relativeUri);

            url = new URL(urlString);
        }
        catch {
            return null;
        }

        if (parameters) {
            for (let [key, value] of Object.entries(parameters)) {
                if (value && value.length > 0) {
                    url.searchParams.append(key, value);
                }
            }
        }

        return url.href;
    }

    public abstract getAbsoluteUriAsync(baseUri: string, relativeUri: string, parameters?: Record<string, string>): Promise<string>;

    protected abstract _urlJoin(baseUri: string, relativeUri: string): string;
}

export class OnlineAbsoluteUriBehavior extends AbsoluteUriBehavior {
    public override getAbsoluteUriAsync(baseUri: string, relativeUri: string, parameters?: Record<string, string>): Promise<string> {
        return Promise.resolve(this.getAbsoluteUri(baseUri, relativeUri, parameters));
    }

    protected override _urlJoin(baseUri: string, relativeUri: string): string {
        return urlJoin(baseUri, relativeUri);
    }
}

export class OfflineAbsoluteUriBehavior extends AbsoluteUriBehavior {
    public constructor(persistentFolder: FileStorageHelper) {
        super();

        this._persistentFolder = persistentFolder;
    }

    public override async getAbsoluteUriAsync(baseUri: string, relativeUri: string, parameters?: Record<string, string>): Promise<string> {
        let urlString: string = this.getAbsoluteUri(baseUri, relativeUri, parameters);

        if (!await this._persistentFolder.isFileExistsAsync(urlString)) {
            console.debug('Uri Service isFileExistsAsync: ' + urlString);

            if (!relativeUri.startsWith('../../../')) {
                return this.getAbsoluteUriAsync(baseUri, `../${relativeUri}`, parameters);
            }
        }

        return urlString;
    }

    protected override _urlJoin(baseUri: string, relativeUri: string): string {
        return urlJoin(baseUri, relativeUri).replace('filesystem://', 'filesystem:');
    }

    private readonly _persistentFolder: FileStorageHelper;
}
