const Routes = {
    Api: {
        Platforms: {
            /** api/platforms */
            Base: 'api/platforms',
            /** api/platforms/run */
            Run: 'api/platforms/run',
            /** api/platforms/stop */
            Stop: 'api/platforms/stop',
            /** api/platforms/{platformId}/logo */
            Logo: 'api/platforms/{0}/logo',
            /** api/platforms/{platformId} */
            Get: 'api/platforms/{0}',
            Settings: {
                /** api/platforms/settings/drive */
                Drive: 'api/platforms/settings/drive',
                /** api/platforms/settings/security */
                Security: 'api/platforms/settings/security',
                /** api/platforms/settings/login */
                Login: 'api/platforms/settings/login',
                Dns: {
                    /** api/platforms/settings/dns */
                    Base: 'api/platforms/settings/dns',
                    /** api/platforms/settings/dns/{dnsId} */
                    Get: 'api/platforms/settings/dns/{0}',
                    /** api/platforms/settings/dns/{dnsId} */
                    Edit: 'api/platforms/settings/dns/{0}'
                }
            },
            Subscriptions: {
                /** api/platforms/{platformId}/subscriptions */
                Base: 'api/platforms/{0}/subscriptions',
                /** api/platforms/{platformId}/subscriptions/{subscriptionId} */
                Get: 'api/platforms/{0}/subscriptions/{1}',
                /** api/platforms/{platformId}/subscriptions/delete */
                Delete: 'api/platforms/{0}/subscriptions/delete'
            },
            IdentityProviders: {
                /** api/identityproviders */
                Base: 'api/identityproviders',
                /** api/platforms/{platformId}/identityproviders/{identityProviderId} */
                Get: 'api/platforms/{0}/identityproviders/{1}',
                /** api/platforms/{platformId}/identityproviders */
                Create: 'api/platforms/{0}/identityproviders',
                /** api/platforms/{platformId}/identityproviders/testldap */
                TestLdap: 'api/platforms/{0}/identityproviders/testldap',
                /** api/platforms/{platformId}/identityproviders/delete */
                Delete: 'api/platforms/{0}/identityproviders/delete',
                /** api/platforms/{platformId}/identityproviders/{identityProviderId}/synchronizeldap */
                SynchronizeLdap: 'api/platforms/{0}/identityproviders/{1}/synchronizeldap'
            }
        },
        Subscriptions: {
            /** api/subscriptions */
            Base: 'api/subscriptions',
            /** api/subscriptions/{subscriptionId} */
            Get: 'api/subscriptions/{0}'
        },
        Agents: {
            /** api/agents */
            Base: 'api/agents',
            /** api/agents/{agentId} */
            Agent: 'api/agents/{0}',
        },
        Workers: {
            /** api/workers */
            Base: 'api/workers',
            /** api/workers/{workerId} */
            Worker: 'api/workers/{0}',
            /** api/workers/{workerId}/tasks */
            Tasks: 'api/workers/{0}/tasks',
            /** api/workers/{workerId}/state */
            WorkerState: 'api/workers/{0}/state',
            /** /api/workers/{workerId}/services/state*/
            ServicesState: '/api/workers/{0}/services/state',
            /** /api/workers/{workerId}/services/{serviceId}/state */
            ServiceState: '/api/workers/{0}/services/{1}/state'
        },
        Tasks: {
            /** /api/tasks */
            Base: 'api/tasks',
            /** api/tasks/{taskId}/restart */
            Restart: 'api/tasks/{0}/restart'
        },
        Services: {
            /** api/services */
            Base: 'api/services',
        },
        Settings: {
            Mail: {
                /** api/platforms/settings/mail */
                Base: 'api/platforms/settings/mail',
                /** api/platforms/settings/mail/test */
                Test: 'api/platforms/settings/mail/test'
            }
        },
        List: {
            /** api/list/mailproviders */
            MailProviders: 'api/list/mailproviders',
            /** api/list/countries */
            Countries: 'api/list/countries',
            /** api/list/genders */
            Genders: 'api/list/genders',
            /** api/list/languages */
            Languages: 'api/list/languages',
            /** api/list/themes */
            Themes: 'api/list/themes',
            /** api/list/notificationusertypes */
            Notifications: 'api/list/notificationusertypes'
        },
        Roles: {
            /** api/platforms/{platformId}/roles/delete */
            Delete: 'api/platforms/{0}/roles/delete'
        },
        Tags: {
            /** api/tags */
            Base: 'api/tags',
            /** api/tags/list */
            List: 'api/tags/list',
            /** api/tags/search */
            Search: 'api/tags/search'
        },
        Drive: {
            /** api/drive */
            Base: 'api/drive',
            Items: {
                /** api/drive/items */
                Base: 'api/drive/items',
                /** 'api/drive/items/{fileGuid}' */
                Item: 'api/drive/items/{0}',
                /** api/drive/items/{fileGuid}/permission */
                Permission: 'api/drive/items/{0}/permission'
            },
            /** api/drive/symlinks */
            Symlinks: 'api/drive/symlinks'
        },
        Projects: {
            /** api/projects */
            Base: 'api/projects',
            Assets: {
                /** api/projects/{projectId}/assets */
                Base: 'api/projects/{0}/assets',
                /** api/projects/{projectId}/items/{itemId} */
                Asset: 'api/projects/{0}/assets/{1}',
                /** api/projects/assets/{itemId}/children */
                Children: 'api/projects/assets/{0}/children'
            },
            Templates: {
                /** api/projects/templates */
                Base: 'api/projects/templates',
                /** api/projects/templates/{templateId} */
                Template: 'api/projects/templates/{0}',
                /** api/projects/templates/{templateId}/thumbnail */
                Thumbnail: 'api/projects/templates/{0}/thumbnail'
            },
            Types: {
                /** api/projects/types */
                Base: 'api/projects/types'
            },
            Imports: {
                /** api/projects/imports */
                Base: 'api/projects/imports'
            },
            /** api/projects/{projectId}/assets/{assetItemId}/scanexist */
            ScanExist: 'api/projects/{0}/assets/{1}/scanexist',
            /** api/projects/{projectId}/assets/{assetItemId}/readscanat/{theta}/{phi} */
            ReadScanAt: 'api/projects/{0}/assets/{1}/readscanat/{2}/{3}',
            /**
             * override route {culture}/areas/project/home/downloadstandalone
             * by api/projects/{projectId:int}/downloadstandalone?culture={culture}
             */
            Standalone: 'api/projects/{0}/downloadstandalone',
            /** api/projects/{projectId}/tags */
            Tags: 'api/projects/{0}/tags',
            /** api/projects/{projectId}/export */
            Export: 'api/projects/{0}/export',
            /** api/projects/{projectId} */
            Project: 'api/projects/{0}',
            /** api/projects/{projectId}/published */
            Published: 'api/projects/{0}/published',
            /** api/projects/{projectId}/changeowner */
            ChangeOwner: 'api/projects/{0}/changeowner',
            Graph: {
                /** api/projects/{projectId}/graph/waypointgraph.gexf */
                WaypointGraph: 'api/projects/{0}/graph/waypointgraph.gexf',
                Waypoints: {
                    /** api/projects/{projectId}/graph/waypoints */
                    Base: 'api/projects/{0}/graph/waypoints',
                    Waypoint: {
                        /** api/projects/{projectId}/graph/waypoints/{waypointId} */
                        Base: 'api/projects/{0}/graph/waypoints/{1}',
                        /** api/projects/{projectId}/graph/waypoints/{waypointId}/assets */
                        Assets: 'api/projects/{0}/graph/waypoints/{1}/assets',
                        /** api/projects/{projectId}/graph/waypoints/{waypointId}/assets/{assetId} */
                        Asset: 'api/projects/{0}/graph/waypoints/{1}/assets/{2}'
                    }
                },
                Edges: {
                    /** api/projects/{projectId}/graph/edges */
                    Base: 'api/projects/{0}/graph/edges',
                    /** api/projects/{projectId}/graph/edges/{sourceId}/{targetId} */
                    Edge: 'api/projects/{0}/graph/edges/{1}/{2}'
                }
            }
        },
        Audits: {
            Logs: {
                /** administratorportal/auditlogs/getdata */
                Base: 'administratorportal/auditlogs/getdata',
            }
        },
        Users: {
            /** api/users */
            Base: 'api/users',
            /** api/users/{userGuid} */
            User: 'api/users/{0}',
            /** api/users/{userGuid}/avatar */
            Avatar: 'api/users/{0}/avatar',
            /** api/users/{userId}/notifications */
            Notifications: 'api/users/{0}/notifications',
            /** api/users/{userId}/profile */
            Profile: 'api/users/{0}/profile',
            /** api/users/{userId}/projects */
            Projects: 'api/users/{0}/projects',
            /** api/users/{userGuid}/groups */
            Groups: 'api/users/{0}/groups',
            /** api/users/{userGuid}/filepermissions */
            FilePermissions: 'api/users/{0}/filepermissions',
            /** api/users/{userGuid}/shares */
            shares: 'api/users/{0}/shares',
            /** api/users/export.csv */
            Export: 'api/users/export.csv',
            Drive: {
                Search: {
                    /** api/users/{userId}/drive/search/import */
                    Import: 'api/users/{0}/drive/search/import'
                }
            },
            /** api/public/users */
            Public: 'api/public/users'
        },
        Groups: {
            /** api/groups */
            Base: 'api/groups',
            /** api/groups/{groupId} */
            Group: 'api/groups/{0}',
            /** api/groups/{groupId}/members */
            Members: 'api/groups/{0}/members',
            /** api/groups/{groupId}/shares */
            Shares: 'api/groups/{0}/shares'
        },
        Explore: {
            /** api/explore/search */
            Search: 'api/explore/search'
        },
        Authentication: {
            /** api/authentication */
            Base: 'api/authentication',
            /** api/authentication/login */
            Login: 'api/authentication/login',
            /** api/authentication/sso */
            SingleSignOn: 'api/authentication/sso',
            /** api/authentication/share */
            Share: 'api/authentication/share'
        },
        Account: {
            /** api/account */
            Base: 'api/account',
            Me: {
                /** api/account/me/tokens/{0} */
                Token: 'api/account/me/tokens/{0}',
                /** api/account/me/tokens */
                Tokens: 'api/account/me/tokens'
            }
        },
        Videos: {
            /** api/videos */
            Base: 'api/videos',
            /** api/videos/{videoId} */
            Video: 'api/videos/{0}',
            /** api/videos/{videoId}/tags */
            Tags: 'api/videos/{0}/tags',
            /** api/videos/{videoId}/shares */
            Shares: 'api/videos/{0}/shares',
            /** api/videos/{videoId}/thumbnail */
            Thumbnail: 'api/videos/{0}/thumbnail',
            /** api/videos/{videoId}/publicationstatus */
            PublicationStatus: 'api/videos/{0}/publicationstatus',
            Published: {
                /** api/videos/published */
                Base: 'api/videos/published',
                /** api/videos/published/{videoId} */
                Video: 'api/videos/published/{0}',
                /** api/videos/published/{videoId}/addview */
                AddView: 'api/videos/published/{0}/addview',
                /** api/videos/published/search */
                Search: 'api/videos/published/search',
                /** api/videos/published/{videoId}/comments*/
                Comments: 'api/videos/published/{0}/comments'
            }
        },
        SmartObjects: {
            /** api/smart-objects */
            Base: 'api/smart-objects',
            /** api/smart-objects/{objectId} */
            SmartObject: 'api/smart-objects/{0}',
            /** api/smart-objects/{objectId}/properties/{propertyId} */
            LookupProperty: 'api/smart-objects/{0}/properties/{1}'
        },
        SmartObjectDefinitions: {
            /** api/smart-object-definitions */
            Base: 'api/smart-object-definitions',
            /** api/smart-object-definitions/{objectId} */
            SmartObjectDefinition: 'api/smart-object-definitions/{0}',
            /** api/smart-object-definitions:/root */
            RootCategory: 'api/smart-object-definitions:/root',
            /** api/smart-object-definitions:/root/{categoryId} */
            Category: 'api/smart-object-definitions/{0}',
            /** api/smart-object-definitions:/root/{categoryId}/children */
            Categories: 'api/smart-object-definitions:/root/{0}/children',
        },
        GeoRouting: {
            /** api/georouting/routes */
            Routes: 'api/georouting/routes',
        },
        JsonSchema: {
            /** api/json-schemas */
            Base: 'api/json-schemas',
            /** api/json-schemas/{objectId} */
            JsonSchema: 'api/json-schemas/{0}',
        },
    }
};

export { Routes };
