import { Component, Prop, UIElement } from 'Framework/Components/UIElement';

@Component({
    name: 'x-item-presenter'
})
export default class ItemPresenter extends UIElement {

    @Prop({ type: Array, required: false, default: null })
    public items: Array<object>;
    @Prop({ type: Boolean, required: false, default: false })
    public verticalScroll: boolean;
    @Prop({ type: Boolean, required: false, default: true })
    public horizontalScroll: boolean;

    public get itemsContainerStyle(): object {
        let overflowY;
        let flexWrap;
        let overflowX = this.horizontalScroll ? 'auto' : 'hidden';
        if (this.verticalScroll) {
            overflowY = 'auto';
            flexWrap = 'wrap';
        }
        else {
            overflowY = 'hidden';
            flexWrap = 'nowrap';
        }
        return {
            'overflow-y': overflowY,
            'overflow-x': overflowX,
            'flex-wrap': flexWrap
        }
    }
}
