import CancelButton from 'App/Services/DialogService/Components/Buttons/CancelButton.vue';
import NoButton from 'App/Services/DialogService/Components/Buttons/NoButton.vue';
import OkButton from 'App/Services/DialogService/Components/Buttons/OkButton.vue';
import YesButton from 'App/Services/DialogService/Components/Buttons/YesButton.vue';
import VoDialogBase from 'App/Services/DialogService/Components/_VoDialogBase';
import DialogTemplateRenderless from 'Framework/Components/ModalDialog/DialogTemplateRenderless';
import { Component, Prop } from 'vue-property-decorator';

export enum DialogButton {
    Custom,
    Ok,
    OkCancel,
    YesNoCancel,
    YesNo
}

@Component({
    name: 'default-dialog',
    components: {
        OkButton,
        CancelButton,
        YesButton,
        NoButton,
        DialogTemplateRenderless
    }
})
export default class DefaultDialog extends VoDialogBase {
    //Use a function because $scopedSlots isn't reactive
    public isActionsSlotDisplayed(): boolean {
        return (this.$scopedSlots['actions'] != null) || (this.buttons != DialogButton.Custom);
    }

    @Prop({ type: Number, default: DialogButton.Custom })
    public buttons: DialogButton;

    public DialogButton: any = DialogButton;
}
