import { AppContext } from 'Api/AppContext';
import { AssetItemType, IGeoRoutingResultDto, IMapLocation, IRouteFilterInputDto, SmartObjectDto } from 'Api/Contracts/Dtos';
import { File } from 'Api/Dto/Drive';
import { PointCloud } from 'Api/Dto/PointCloud';
import { CoordinateSystem, ICartesianCoordinates, IHotspot, IPlanCoordinates, ISphericalCoordinates } from 'Api/Dto/Project';
import { IPaginationResult, IQueryResultBase, IServerPaginationResult, QueryException } from 'Api/Dto/QueryResult';
import { ISearchResult } from 'Api/Dto/SearchResult';
import { IIdentity } from 'Api/Dto/Shares';
import { HttpClient } from 'Api/HttpClient';
import { IResponseHandler } from 'Api/Infrastructure/Interfaces';
import { Routes } from 'Api/Routes';
import { injectTypes } from 'App/injectTypes';
import { RenderType } from 'Framework/Components/Controls/XView/Core';
import { GeoJSON } from 'geojson';
import { inject, injectable } from 'inversify';
import { String } from 'typescript-string-operations';

export enum NavigationType {
    None = 0, List = 1, Map = 2
}
export enum MapSize {
    Hidden = 1, Small = 2, Medium = 4, Large = 8
}
export enum StreetMapType {
    GoogleMaps = 1, OpenStreetMap = 2, BingMap = 4
}
export enum BindingMode { OneWay = 1, TwoWay = 2 }

export class Datasource {
    name: string;
    type: string;
    of: string;
    value: any;

    static fromJson(json: any) {
        let datasource: Datasource = new Datasource();
        datasource.name = json.name;
        datasource.type = json.type;
        datasource.of = json.of;
        if (Array.isArray(json.value)) {
            let array = new Array<any>();
            for (let value of json.value) {
                array.push(jsonToObject(value));
            }
            datasource.value = array;
        } else {
            datasource.value = json.value;
        }

        return datasource;
    }
}

function jsonToObject(value: any): any {
    if (Array.isArray(value)) {
        let array = new Array<any>();
        for (let v of value) {
            array.push(jsonToObject(v));
        }
        return array;
    }

    switch (value?.type) {
        case AssetItemType.StreetMap:
            return StreetMap.fromJson(value);
        case AssetItemType.PanoramaItemLink:
            return OrderedPanorama.fromJson(value);
        case AssetItemType.FileMap:
            return FileMap.fromJson(value);
        case AssetItemType.Navigation:
            return Navigation.fromJson(value);
        case AssetItemType.PanoramaVersion:
            return AssetPanoramaVersion.fromJson(value);
        case AssetItemType.PanoramaGroup:
            return AssetPanoramaGroup.fromJson(value);
        case AssetItemType.Project:
            return Project.fromJson(value);
        case AssetItemType.Link:
            return AssetLink.fromJson(value);
        case AssetItemType.Hotspot:
            return AssetHotspot.fromJson(value);
        case AssetItemType.MapArea:
            return AssetMapArea.fromJson(value);
        case AssetItemType.File:
            return AssetFile.fromJson(value);
        case AssetItemType.Directory:
            return AssetDirectory.fromJson(value);
        case AssetItemType.SmartObject:
            return SmartObjectDto.fromJson(value);
        default:
            return value;
    }
}

export class Author {
    id: number;
    name: string;
    picture: string;
    pictureUrl: string;

    static fromJson(json: any) {
        let author: Author = new Author();
        author.id = json.id;
        author.name = json.name;
        author.picture = json.picture;
        author.pictureUrl = json.pictureUrl;

        return author;
    }
}

export interface IComment {
    id: number;
    text: string;
    creationTime: Date;
    updateTime?: Date;
    author: IIdentity;
}

export class Project {
    id: number;
    name: string;
    rating: number;
    description: string;
    thumbnail: string;
    thumbnailUrl: string;
    publicationTimeUtc?: Date;
    location: GeoJSON;
    author: Author;
    token: string;
    validatedToken: string;
    views: number;
    layout: any;
    datasources: Array<Datasource>;
    basePath?: string;
    events: string;
    tags: Array<string>;

    public findPanoramaFileFromAssetId(assetId: number): AssetPanoramaVersion {
        return (this.datasources?.[0].value as Array<AssetPanoramaVersion>)
            ?.find(item => item.assetItemId == assetId) ?? null;
    }

    public findAssetFileFromAssetId(assetId: number): AssetFile {
        return (this.datasources?.[2].value as Array<AssetFile>)
            ?.find(item => item.assetItemId == assetId) ?? null;
    }

    public findAssetFileFromAssetFolderId(assetId: number): Array<AssetFile> {
        return (this.datasources?.[2].value as Array<AssetFile>)
            ?.filter(item => item.parentId == assetId) ?? null;
    }

    static fromJson(json: Project): Project {
        let project: Project = new Project();
        project.basePath = json.basePath || null;
        project.id = Number(json.id);
        project.name = json.name;
        project.token = json.token;
        project.rating = Number(json.rating) || 0;
        project.description = json.description || '';
        project.thumbnail = json.thumbnail;
        project.location = json.location || null;
        project.thumbnailUrl = json.thumbnailUrl;
        project.publicationTimeUtc = json.publicationTimeUtc ? new Date(json.publicationTimeUtc) : null;
        project.author = Author.fromJson(json.author);
        project.views = Number(json.views) || 0;
        project.events = json.events;
        project.layout = json.layout;
        project.tags = json.tags;

        project.datasources = new Array<Datasource>();
        if (Array.isArray(json.datasources)) {
            for (let datasource of json.datasources) {
                project.datasources.push(Datasource.fromJson(datasource));
            }
        }

        return project;
    }
}

export class AssetItem {
    public static fromJson(json: any): AssetItem {
        let assetItem: AssetItem = new AssetItem();
        assetItem.assetItemId = json.assetItemId;
        assetItem.name = json.name;
        assetItem.type = json.type;
        assetItem.parentId = json.parentId;
        assetItem.events = json.events || {};
        assetItem.waypointIds = json.waypointIds;

        return assetItem;
    }

    public static jsonToArray<T>(value: any): Array<T> {
        if (Array.isArray(value)) {
            let array = new Array<T>();
            for (let v of value) {
                array.push(AssetItem.jsonToObject<T>(v));
            }
            return array;
        }
        return [];
    }

    public static jsonToObject<T>(value: any): T {
        switch (value?.type) {
            case AssetItemType.StreetMap:
                return StreetMap.fromJson(value) as unknown as T;
            case AssetItemType.PanoramaItemLink:
                return OrderedPanorama.fromJson(value) as unknown as T;
            case AssetItemType.FileMap:
                return FileMap.fromJson(value) as unknown as T;
            case AssetItemType.Navigation:
                return Navigation.fromJson(value) as unknown as T;
            case AssetItemType.PanoramaVersion:
                return AssetPanoramaVersion.fromJson(value) as unknown as T;
            case AssetItemType.PanoramaGroup:
                return AssetPanoramaGroup.fromJson(value) as unknown as T;
            case AssetItemType.Project:
                return Project.fromJson(value) as unknown as T;
            case AssetItemType.Link:
                return AssetLink.fromJson(value) as unknown as T;
            case AssetItemType.Hotspot:
                return AssetHotspot.fromJson(value) as unknown as T;
            case AssetItemType.MapArea:
                return AssetMapArea.fromJson(value) as unknown as T;
            case AssetItemType.File:
                return AssetFile.fromJson(value) as unknown as T;
            case AssetItemType.Directory:
                return AssetDirectory.fromJson(value) as unknown as T;
            case AssetItemType.SmartObject:
                return SmartObjectDto.fromJson(value) as unknown as T;
            default:
                return value;
        }
    }

    public name: string;
    public assetItemId: number;
    public parentId?: number;
    public parent?: AssetDirectory;
    public type: AssetItemType;
    public events: { [key: string]: string; };
    public waypointIds: Array<number>;
}

export class AssetFile extends AssetItem {
    public static fromJson(json: any): AssetFile {
        let assetFile: AssetFile = Object.assign(new AssetFile(), AssetItem.fromJson(json));
        assetFile.file = json.file;

        return assetFile;
    }

    public file: File;
}

export class AssetDirectory extends AssetFile {
    public static fromJson(json: any): AssetDirectory {
        let assetDirectory: AssetDirectory = Object.assign(new AssetDirectory(), AssetFile.fromJson(json));
        assetDirectory.children = AssetItem.jsonToArray<AssetItem>(json.children);

        assetDirectory.children.forEach(c => {
            c.parent = assetDirectory;
        });

        return assetDirectory;
    }

    public addChildren(...children: Array<AssetItem>): void {
        this.children.push(...children);
    }

    public removeChildren(...children: Array<AssetItem>): void {
        this.children = this.children.filter(c => !children.includes(c));
    }

    public children: Array<AssetItem>;
}

export class AssetPanoramaVersion extends AssetDirectory {
    public static fromJson(json: any): AssetPanoramaVersion {
        let panorama: AssetPanoramaVersion = Object.assign(new AssetPanoramaVersion(), AssetDirectory.fromJson(json));
        panorama.renderType = json.renderType;
        panorama.level = json.level;
        panorama.northOrientation = json.northOrientation;
        panorama.hotspots = AssetItem.jsonToArray<AssetHotspot & IHotspot>(json.hotspots);
        panorama.order = json.order;
        panorama.offset = json.offset;

        if (json.startOrientation) {
            panorama.startOrientation = JSON.parse(json.startOrientation);
        }

        panorama.children.forEach(c => {
            c.parent = panorama;
        });

        panorama.hotspots.forEach(h => {
            h.parent = panorama;
        });

        return panorama;
    }

    public addChildren(...children: Array<AssetItem>): void {
        const hotspots = children.filter(c => c instanceof AssetHotspot) as Array<AssetHotspot & IHotspot>;
        this.hotspots.push(...hotspots);
    }

    public removeChildren(...children: Array<AssetItem>): void {
        this.hotspots = this.hotspots.filter(h => !children.includes(h));
    }

    public renderType: RenderType;
    public level: number;
    public hotspots: Array<AssetHotspot & IHotspot>;
    public startOrientation: ICartesianCoordinates;
    public northOrientation: number;
    public offset: number;
    public order: number;
}

export class AssetPanoramaGroup extends AssetDirectory {
    public static fromJson(json: any): AssetPanoramaGroup {
        let panoGroup: AssetPanoramaGroup = Object.assign(new AssetPanoramaGroup(), AssetDirectory.fromJson(json));
        panoGroup.defaultVersionId = json.defaultVersionId;
        panoGroup.hotspots = AssetItem.jsonToArray<AssetHotspot & IHotspot>(json.hotspots);

        panoGroup.children.forEach(c => {
            c.parent = panoGroup;
        });

        panoGroup.hotspots.forEach(h => {
            h.parent = panoGroup;
        });

        return panoGroup;
    }

    public getDefaultPanoramaVersion(currentVersion?: string): AssetPanoramaVersion {
        const panoramaVersions = this.children as Array<AssetPanoramaVersion>;

        return panoramaVersions.find(pv => pv.name == currentVersion)
            ?? panoramaVersions.find(pv => pv.assetItemId == this.defaultVersionId)
            ?? null;
    }

    public addChildren(...children: Array<AssetItem>): void {
        const panoramaVersions = children.filter(c => c instanceof AssetPanoramaVersion);
        const hotspots = children.filter(c => c instanceof AssetHotspot) as Array<AssetHotspot & IHotspot>;

        this.children.push(...panoramaVersions);
        this.hotspots.push(...hotspots);
    }

    public removeChildren(...children: Array<AssetItem>): void {
        this.hotspots = this.hotspots.filter(h => !children.includes(h));
        this.children = this.children.filter(c => !children.includes(c));
    }

    public defaultVersionId?: number;
    public hotspots: Array<AssetHotspot & IHotspot>;
}

export class Navigation extends AssetDirectory {
    public static fromJson(json: any): Navigation {
        let navigation: Navigation = Object.assign(new Navigation(), AssetDirectory.fromJson(json));
        navigation.navigationType = json.navigationType;
        navigation.compassConfiguration = json.compassConfiguration;
        navigation.maps = AssetItem.jsonToArray<ProjectMap>(json.maps);

        navigation.children.forEach(c => {
            c.parent = navigation;
        });

        navigation.maps.forEach(m => {
            m.parent = navigation;
        });

        return navigation;
    }

    public addChildren(...children: Array<AssetItem>): void {
        const maps: Array<ProjectMap | OrderedPanorama> = children.filter(
            (c: AssetItem): c is ProjectMap | OrderedPanorama =>
                c instanceof ProjectMap || c instanceof OrderedPanorama);

        this.maps.push(...maps);
    }

    public removeChildren(...children: Array<AssetItem>): void {
        this.maps = this.maps.filter(m => !children.includes(m));
    }

    public navigationType: NavigationType;
    public maps: Array<ProjectMap | OrderedPanorama>;
    public compassConfiguration: string;
}

export class ProjectMap extends AssetDirectory {
    public static fromJson(json: any): ProjectMap {
        let projectMap: ProjectMap = Object.assign(new ProjectMap(), AssetDirectory.fromJson(json));

        projectMap.children.forEach(c => {
            c.parent = projectMap;
        });

        return projectMap;
    }
}

export class AssetLink extends AssetItem {
    public static fromJson(json: any): AssetLink {
        let assetLink: AssetLink = Object.assign(new AssetLink(), AssetItem.fromJson(json));
        assetLink.assetItemLinkedId = json.assetItemLinkedId ?? null;

        return assetLink;
    }

    public assetItemLinkedId?: number;
}

export class AssetHotspot extends AssetLink {
    public static fromJson(json: any): AssetHotspot {
        let assetHotspot: AssetHotspot = Object.assign(new AssetHotspot(), AssetLink.fromJson(json));
        assetHotspot.coordinateSystem = json.coordinateSystem;
        assetHotspot.coordinates = json.coordinates;
        assetHotspot.content = json.content;

        return assetHotspot;
    }

    public coordinateSystem: CoordinateSystem;
    public coordinates: Array<IPlanCoordinates | ISphericalCoordinates | ICartesianCoordinates>;
    public content: any;
}

export class AssetMapArea extends AssetHotspot {
    public static fromJson(json: any): AssetMapArea {
        let assetMapArea: AssetMapArea = Object.assign(new AssetMapArea(), AssetHotspot.fromJson(json));
        assetMapArea.areaCoordinates = json.areaCoordinates;

        return assetMapArea;
    }

    public areaCoordinates: Array<IPlanCoordinates | ISphericalCoordinates | ICartesianCoordinates>;
}

export class FileMap extends ProjectMap {
    public static fromJson(json: any): FileMap {
        let fileMap: FileMap = Object.assign(new FileMap(), ProjectMap.fromJson(json));
        fileMap.isDefault = json.isDefault;
        fileMap.mapLocation = json.mapLocation;

        fileMap.children.forEach(c => {
            c.parent = fileMap;
        });

        return fileMap;
    }

    public isDefault: boolean;
    public mapLocation: IMapLocation;
}

export class StreetMap extends ProjectMap {
    public static fromJson(json: any): StreetMap {
        let streetMap: StreetMap = Object.assign(new StreetMap(), ProjectMap.fromJson(json));
        streetMap.coordinates = json.coordinates;
        streetMap.provider = json.provider;

        streetMap.children.forEach(c => {
            c.parent = streetMap;
        });

        return streetMap;
    }

    public coordinates: string;
    public provider: StreetMapType;
}

export class OrderedPanorama extends AssetLink {
    public static fromJson(json: any): OrderedPanorama {
        let orderedPanorama: OrderedPanorama = Object.assign(new OrderedPanorama(), AssetLink.fromJson(json));
        orderedPanorama.order = json.order;

        return orderedPanorama;
    }

    public order: number;
}

@injectable()
export class ExploreService {
    constructor(
        @inject(AppContext) appContext: AppContext,
        @inject(HttpClient) httpClient: HttpClient,
        @inject(injectTypes.IResponseHandler) responseHandler: IResponseHandler
    ) {
        this._httpClient = httpClient;
        this._responseHandler = responseHandler;
    }

    private static responseToProjectMap(item: any): Promise<Array<ProjectMap>> {
        if (item) {
            let projectMap = Array<ProjectMap>();
            for (const i of item) {
                let map = null;
                switch (i.type) {
                    case AssetItemType.StreetMap:
                        map = (Object.assign(new StreetMap(), i));
                        break;
                    case AssetItemType.PanoramaItemLink:
                        map = (Object.assign(new OrderedPanorama(), i));
                        break;
                    case AssetItemType.FileMap:
                        map = (Object.assign(new FileMap(), i));
                        break;
                    default:
                        break;
                }
                if (map) {
                    projectMap.push(map);
                }
            }
            return Promise.resolve(projectMap);
        }
        return null;
    }


    public async loadProjectAsync(idProject: number, token: string = null, basePath: string = null): Promise<Project> {
        const url = String.Format(Routes.Api.Projects.Published, idProject);

        const result = await this._responseHandler.handleResponseAsync<Project>(
            await this._httpClient.getAsync(url, null, { token: token }));

        const project: Project = Project.fromJson(result);
        project.basePath = basePath;
        project.validatedToken = token;

        return project;
    }

    public async rateProjectAsync(idProject: number, rating: number): Promise<void> {
        // /fr-fr/explore/rate/{idProject}/{rating}
        let url = `/explore/rate/${idProject}/${rating}`;

        let response = await this._httpClient.getAsync(url);

        if (!response.ok) {
            let jsonResult = await response.json();

            throw new Error(jsonResult.error.code);
        }
    }

    public async getRelatedProjectAsync(idProject: number): Promise<Array<Project>> {
        // /fr-fr/explore/related/{idProject}
        let url = `/explore/related/${idProject}`;

        let response = await this._httpClient.getAsync(url);
        let jsonResult = await response.json();

        return jsonToObject(jsonResult) as Array<Project>;
    }

    public async getCommentsAsync(idProject: number): Promise<Array<IComment>> {
        // /fr-fr/explore/comments/{idProject}
        let url = `/explore/comments/${idProject}`;

        let response = await this._httpClient.getAsync(url);
        let jsonResult = await response.json();

        return jsonToObject(jsonResult) as Array<IComment>;
    }

    public async scanExistAsync(projectId: number, assetItemId: number): Promise<boolean> {
        const url = String.Format(
            Routes.Api.Projects.ScanExist,
            projectId,
            assetItemId
        );

        const response = await this._httpClient.getAsync(url);
        const jsonResult = await response.json();

        if (!response.ok) {
            const queryError = jsonResult as IQueryResultBase;

            throw new QueryException(queryError.error);
        }

        return jsonResult as boolean;
    }

    public async readScanAtAsync(projectId: number, assetItemId: number, theta: number, phi: number): Promise<PointCloud> {
        const url = String.Format(
            Routes.Api.Projects.ReadScanAt,
            projectId,
            assetItemId,
            theta,
            phi
        );

        const response = await this._httpClient.getAsync(url);
        const jsonResult = await response.json();

        if (!response.ok) {
            const queryError = jsonResult as IQueryResultBase;

            throw new QueryException(queryError.error);
        }

        return jsonResult as PointCloud;
    }

    public async getRoutesBetweenAssetsAsync(
        projectId: number,
        fromAssetItemId: number,
        toAssetItemId: number,
        filters: IRouteFilterInputDto,
        token: string
    ): Promise<Array<IGeoRoutingResultDto>> {
        const response = await this._httpClient.getAsync(
            Routes.Api.GeoRouting.Routes,
            null,
            {
                token: token,
                from: fromAssetItemId,
                to: toAssetItemId,
                projectId: projectId,
                reference: 'asset',
                ...filters
            });

        return await this._responseHandler
            .handleResponseAsync<Array<IGeoRoutingResultDto>>(response);
    }

    public async addCommentAsync(idProject: number, text: string): Promise<IComment> {
        // /fr-fr/explore/comments/{idProject}
        let url = `/explore/addcomment`;

        let postData = {
            'idProject': idProject,
            'text': text
        };

        let response = await this._httpClient.postAsync(url, postData);
        let jsonResult = await response.json();

        return jsonToObject(jsonResult) as IComment;
    }

    public async addViewProjectAsync(idProject: number): Promise<void> {
        // /fr-fr/explore/comments/{idProject}
        let url = `/explore/addview/${idProject}`;

        let response = await this._httpClient.getAsync(url);

        if (!response.ok) {
            throw Error(response.statusText);
        }
    }

    public async getPublishedProjectsAsync(page: number, pageSize: number, sortBy: string, type: string = null): Promise<IPaginationResult<Project>> {
        let response: Response = await this._httpClient.getAsync(
            `/api/explore/${sortBy.toLowerCase()}/${type.toLowerCase()}`,
            null,
            {
                page,
                pageSize
            }
        );

        const result: IPaginationResult<Project> = await this._responseHandler
            .handleResponseAsync<IPaginationResult<Project>>(response);

        result.items = jsonToObject(result.items);

        return result;
    }

    public async getPublishedProjectsByUserAsync(userId: number, page: number, pageSize: number = 50): Promise<IPaginationResult<Project>> {
        const response: Response = await this._httpClient.getAsync(
            String.Format(Routes.Api.Users.Projects, userId),
            null,
            {
                page,
                pageSize
            }
        );

        const result: IPaginationResult<Project> = await this._responseHandler
            .handleResponseAsync<IPaginationResult<Project>>(response);

        result.items = jsonToObject(result.items);

        return result;
    }

    public async searchAsync(searchText: string, filterType: string, maxPageSize: number): Promise<ISearchResult<Project>> {
        const queryData = {
            searchText: searchText,
            type: filterType,
            maxPageSize: maxPageSize
        };

        const response = await this._httpClient.getAsync(Routes.Api.Explore.Search, null, queryData);

        return await this._searchResponseAsync(response);
    }

    public async searchNextPageAsync(nextPageLink: string): Promise<ISearchResult<Project>> {
        const response = await this._httpClient.getAsync(nextPageLink, '/');

        return await this._searchResponseAsync(response);
    }

    private async _searchResponseAsync(response: Response): Promise<ISearchResult<Project>> {
        const result: IServerPaginationResult<Project> = await this._responseHandler
            .handleResponseAsync<IServerPaginationResult<Project>>(response);

        return {
            found: result.result,
            nextLink: result.nextLink,
            hasMoreResults: result.nextLink != null
        };
    }

    protected readonly _httpClient: HttpClient;
    private readonly _responseHandler: IResponseHandler;
}
