import GridItemMixin from 'Framework/Components/mixins/GridItem';
import { ObservableObject } from 'Framework/Components/mixins/ObservableObject';
import { BorderStyle, editorBrowsable, EditorBrowsableState, JsonProperty, PropSyncWithInternal, Serializable, Visibility } from 'Framework/Components/Panel.Commons';
import { Component, Mixins, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';

declare module 'vue/types/vue' {
    interface Vue {
        $debounce(func: Function, wait: number, options?: { leading: boolean, trailing: boolean, maxWait: number });
        $throttle(func: Function, wait: number, options?: { leading: boolean, trailing: boolean });
        $raisePropertyChanged(propertyName: string): boolean;
    }
}

/** @see {@link https://github.com/vuejs/vue-class-component/blob/master/src/reflect.ts} */

@Serializable()
@Component({
    name: 'x-ui-element',
})
class UIElement<T = any> extends Mixins(GridItemMixin, ObservableObject) {
    @Watch('_dataContext', { immediate: true })
    protected _onDataContextChanged(newValue: T, oldValue: T): void {

    }

    public get computedStyle(): any {
        let style = { ...this.gridItemStyle() };

        if (this._zIndex) {
            style['z-index'] = this._zIndex;
        }

        if (this._visibility == Visibility.Hidden) {
            style['visibility'] = 'hidden';
        }
        else if (this._visibility == Visibility.Collapsed) {
            style['display'] = 'none';
        }

        if (this._minWidth) {
            style['min-width'] = `${this._minWidth}px`;
        }
        if (this._width) {
            style['width'] = `${this._width}px`;
        }
        if (this._maxWidth) {
            style['max-width'] = `${this._maxWidth}px`;
        }

        if (this._minHeight) {
            style['min-height'] = `${this._minHeight}px`;
        }
        if (this._height) {
            style['height'] = `${this._height}px`;
        }
        if (this._maxHeight) {
            style['max-height'] = `${this._maxHeight}px`;
        }

        if (this._opacity) {
            style['opacity'] = this._opacity;
        }

        if (this._background) {
            style['background-color'] = this._background;
        }

        if (this._foreground) {
            style['color'] = this._foreground;
        }

        if (this._marginTop) {
            style['margin-top'] = `${this._marginTop}px`;
        }
        if (this._marginBottom) {
            style['margin-bottom'] = `${this._marginBottom}px`;
        }
        if (this._marginLeft) {
            style['margin-left'] = `${this._marginLeft}px`;
        }
        if (this._marginRight) {
            style['margin-right'] = `${this._marginRight}px`;
        }

        if (this._paddingTop) {
            style['padding-top'] = `${this._paddingTop}px`;
        }
        if (this._paddingBottom) {
            style['padding-bottom'] = `${this._paddingBottom}px`;
        }
        if (this._paddingLeft) {
            style['padding-left'] = `${this._paddingLeft}px`;
        }
        if (this._paddingRight) {
            style['padding-right'] = `${this._paddingRight}px`;
        }

        if (this._borderTopSize) {
            style['border-top-width'] = `${this._borderTopSize}px`;
        }
        if (this._borderBottomSize) {
            style['border-bottom-width'] = `${this._borderBottomSize}px`;
        }
        if (this._borderLeftSize) {
            style['border-left-width'] = `${this._borderLeftSize}px`;
        }
        if (this._borderRightSize) {
            style['border-right-width'] = `${this._borderRightSize}px`;
        }

        if (this._borderTopColor) {
            style['border-top-color'] = this._borderTopColor;
        }
        if (this._borderBottomColor) {
            style['border-bottom-color'] = this._borderBottomColor;
        }
        if (this._borderLeftColor) {
            style['border-left-color'] = this._borderLeftColor;
        }
        if (this._borderRightColor) {
            style['border-right-color'] = this._borderRightColor;
        }

        if (this._borderTopStyle) {
            style['border-top-style'] = this._borderTopStyle;
        }
        if (this._borderBottomStyle) {
            style['border-bottom-style'] = this._borderBottomStyle;
        }
        if (this._borderLeftStyle) {
            style['border-left-style'] = this._borderLeftStyle;
        }
        if (this._borderRightStyle) {
            style['border-right-style'] = this._borderRightStyle;
        }

        return style;
    }

    @JsonProperty({ className: 'x-ui-element', name: 'isVisible', type: Boolean })
    @editorBrowsable({ groupName: 'Appearence', template: 'Boolean' })
    public get _isVisible(): boolean {
        return this._visibility == Visibility.Visible;
    }

    public set _isVisible(value: boolean) {
        this._visibility = value 
            ? Visibility.Visible 
            : Visibility.Collapsed;

        this.$emit('update:isVisible', value);
        this.$emit('update:_isVisible', value);
        this.$emit('property-changed', this, 'isVisible', value);
    }

    @Prop()
    public readonly dataContext: T;

    @JsonProperty({ className: 'x-ui-element', name: 'dataContext', type: Object })
    @editorBrowsable({ groupName: 'Commons', editorBrowsableState: EditorBrowsableState.Never })
    public get _dataContext(): T {
        let dc = this.dataContext || this['internals']['dataContext'] || null;

        if (!dc && this.$parent instanceof UIElement) {
            dc = this.$parent._dataContext;
        }

        return dc;
    }

    public set _dataContext(value: T) {
        this.$set(this['internals'], 'dataContext', value);

        this.$emit('update:dataContext', value);
        this.$emit('update:_dataContext', value);
        this.$emit('property-changed', this, 'dataContext', value);
    }

    @Prop({ type: Boolean, default: true })
    public readonly isVisible: boolean;

    @Watch('isVisible')
    private _onIsVisiblePropertyChanged(newValue: boolean, oldValue: boolean): void {
        this._isVisible = newValue;
    }

    @JsonProperty({ className: 'x-ui-element', name: 'name', type: String })
    @editorBrowsable({ groupName: 'Commons'})
    @PropSyncWithInternal('name', { type: String, required: false, default: null })
    public _name: string;

    @JsonProperty({ className: 'x-ui-element', name: 'zIndex', type: Number })
    @editorBrowsable({ groupName: 'Layout', template: 'Number'})
    @PropSyncWithInternal('zIndex', { type: Number, required: false, default: null })
    public _zIndex: number;

    @JsonProperty({ className: 'x-ui-element', name: 'visibility', type: Number })
    @editorBrowsable({ groupName: 'Appearence', template: 'Visibility'})
    @PropSyncWithInternal('visibility', { type: Number, required: false, default: Visibility.Visible })
    public _visibility: Visibility;

    @JsonProperty({ className: 'x-ui-element', name: 'minWidth', type: Number })
    @editorBrowsable({ groupName: 'Layout', template: 'Number'})
    @PropSyncWithInternal('minWidth', { type: Number, required: false, default: null })
    public _minWidth: number;

    @JsonProperty({ className: 'x-ui-element', name: 'width', type: Number })
    @editorBrowsable({ groupName: 'Layout', template: 'Number'})
    @PropSyncWithInternal('width', { type: Number, required: false, default: null })
    public _width: number;

    @JsonProperty({ className: 'x-ui-element', name: 'maxWidth', type: Number })
    @editorBrowsable({ groupName: 'Layout', template: 'Number'})
    @PropSyncWithInternal('maxWidth', { type: Number, required: false, default: null })
    public _maxWidth: number;

    @JsonProperty({ className: 'x-ui-element', name: 'minHeight', type: Number })
    @editorBrowsable({ groupName: 'Layout', template: 'Number'})
    @PropSyncWithInternal('minHeight', { type: Number, required: false, default: null })
    public _minHeight: number;

    @JsonProperty({ className: 'x-ui-element', name: 'height', type: Number })
    @editorBrowsable({ groupName: 'Layout', template: 'Number'})
    @PropSyncWithInternal('height', { type: Number, required: false, default: null })
    public _height: number;

    @JsonProperty({ className: 'x-ui-element', name: 'maxHeight', type: Number })
    @editorBrowsable({ groupName: 'Layout', template: 'Number'})
    @PropSyncWithInternal('maxHeight', { type: Number, required: false, default: null })
    public _maxHeight: number;

    @JsonProperty({ className: 'x-ui-element', name: 'opacity', type: Number })
    @editorBrowsable({ groupName: 'Appearence', template: 'Number'})
    @PropSyncWithInternal('opacity', { type: Number, required: false, default: null })
    public _opacity: number;

    @JsonProperty({ className: 'x-ui-element', name: 'background', type: String })
    @editorBrowsable({ groupName: 'Appearence', template: 'Color'})
    @PropSyncWithInternal('background', { type: String, required: false, default: null })
    public _background: string;

    @JsonProperty({ className: 'x-ui-element', name: 'foreground', type: String })
    @editorBrowsable({ groupName: 'Appearence', template: 'Color'})
    @PropSyncWithInternal('foreground', { type: String, required: false, default: null })
    public _foreground: string;

    @JsonProperty({ className: 'x-ui-element', name: 'marginTop', type: Number })
    @editorBrowsable({ groupName: 'Layout', template: 'Number'})
    @PropSyncWithInternal('marginTop', { type: Number, required: false, default: null })
    public _marginTop: number;

    @JsonProperty({ className: 'x-ui-element', name: 'marginBottom', type: Number })
    @editorBrowsable({ groupName: 'Layout', template: 'Number'})
    @PropSyncWithInternal('marginBottom', { type: Number, required: false, default: null })
    public _marginBottom: number;

    @JsonProperty({ className: 'x-ui-element', name: 'marginLeft', type: Number })
    @editorBrowsable({ groupName: 'Layout', template: 'Number'})
    @PropSyncWithInternal('marginLeft', { type: Number, required: false, default: null })
    public _marginLeft: number;

    @JsonProperty({ className: 'x-ui-element', name: 'marginRight', type: Number })
    @editorBrowsable({ groupName: 'Layout', template: 'Number'})
    @PropSyncWithInternal('marginRight', { type: Number, required: false, default: null })
    public _marginRight: number;

    @JsonProperty({ className: 'x-ui-element', name: 'paddingTop', type: Number })
    @editorBrowsable({ groupName: 'Layout', template: 'Number', editorBrowsableState: EditorBrowsableState.Never })
    @PropSyncWithInternal('paddingTop', { type: Number, required: false, default: null })
    public _paddingTop: number;

    @JsonProperty({ className: 'x-ui-element', name: 'paddingBottom', type: Number })
    @editorBrowsable({ groupName: 'Layout', template: 'Number', editorBrowsableState: EditorBrowsableState.Never })
    @PropSyncWithInternal('paddingBottom', { type: Number, required: false, default: null })
    public _paddingBottom: number;

    @JsonProperty({ className: 'x-ui-element', name: 'paddingLeft', type: Number })
    @editorBrowsable({ groupName: 'Layout', template: 'Number', editorBrowsableState: EditorBrowsableState.Never })
    @PropSyncWithInternal('paddingLeft', { type: Number, required: false, default: null })
    public _paddingLeft: number;

    @JsonProperty({ className: 'x-ui-element', name: 'paddingRight', type: Number })
    @editorBrowsable({ groupName: 'Layout', template: 'Number', editorBrowsableState: EditorBrowsableState.Never })
    @PropSyncWithInternal('paddingRight', { type: Number, required: false, default: null })
    public _paddingRight: number;

    @JsonProperty({ className: 'x-ui-element', name: 'borderTopSize', type: Number })
    @editorBrowsable({ groupName: 'Appearence', template: 'Number'})
    @PropSyncWithInternal('borderTopSize', { type: Number, required: false, default: null })
    public _borderTopSize: number;

    @JsonProperty({ className: 'x-ui-element', name: 'borderTopColor', type: String })
    @editorBrowsable({ groupName: 'Appearence', template: 'Color'})
    @PropSyncWithInternal('borderTopColor', { type: String, required: false, default: null })
    public _borderTopColor: string;

    @JsonProperty({ className: 'x-ui-element', name: 'borderTopStyle', type: String })
    @editorBrowsable({ groupName: 'Appearence', template: 'BorderStyle'})
    @PropSyncWithInternal('borderTopStyle', { type: String, required: false, default: null })
    public _borderTopStyle: BorderStyle;

    @JsonProperty({ className: 'x-ui-element', name: 'borderBottomSize', type: Number })
    @editorBrowsable({ groupName: 'Appearence', template: 'Number'})
    @PropSyncWithInternal('borderBottomSize', { type: Number, required: false, default: null })
    public _borderBottomSize: number;

    @JsonProperty({ className: 'x-ui-element',name: 'borderBottomColor', type: String })
    @editorBrowsable({ groupName: 'Appearence', template: 'Color'})
    @PropSyncWithInternal('borderBottomColor', { type: String, required: false, default: null })
    public _borderBottomColor: string;

    @JsonProperty({ className: 'x-ui-element', name: 'borderBottomStyle', type: String })
    @editorBrowsable({ groupName: 'Appearence', template: 'BorderStyle'})
    @PropSyncWithInternal('borderBottomStyle', { type: String, required: false, default: null })
    public _borderBottomStyle: BorderStyle;

    @JsonProperty({ className: 'x-ui-element',name: 'borderLeftSize', type: Number })
    @editorBrowsable({ groupName: 'Appearence', template: 'Number'})
    @PropSyncWithInternal('borderLeftSize', { type: Number, required: false, default: null })
    public _borderLeftSize: number;

    @JsonProperty({ className: 'x-ui-element', name: 'borderLeftColor', type: String })
    @editorBrowsable({ groupName: 'Appearence', template: 'Color'})
    @PropSyncWithInternal('borderLeftColor', { type: String, required: false, default: null })
    public _borderLeftColor: string;

    @JsonProperty({ className: 'x-ui-element', name: 'borderLeftStyle', type: String })
    @editorBrowsable({ groupName: 'Appearence', template: 'BorderStyle'})
    @PropSyncWithInternal('borderLeftStyle', { type: String, required: false, default: null })
    public _borderLeftStyle: BorderStyle;

    @JsonProperty({ className: 'x-ui-element', name: 'borderRightSize', type: Number })
    @editorBrowsable({ groupName: 'Appearence', template: 'Number'})
    @PropSyncWithInternal('borderRightSize', { type: Number, required: false, default: null })
    public _borderRightSize: number;

    @JsonProperty({ className: 'x-ui-element', name: 'borderRightColor', type: String })
    @editorBrowsable({ groupName: 'Appearence', template: 'Color'})
    @PropSyncWithInternal('borderRightColor', { type: String, required: false, default: null })
    public _borderRightColor: string;

    @JsonProperty({ className: 'x-ui-element', name: 'borderRightStyle', type: String })
    @editorBrowsable({ groupName: 'Appearence', template: 'BorderStyle'})
    @PropSyncWithInternal('borderRightStyle', { type: String, required: false, default: null })
    public _borderRightStyle: BorderStyle;

    public static readonly Bus: Vue = new Vue();
}

export { Component, Mixins, Prop, PropSync, PropSyncWithInternal, Ref, UIElement, Vue, Watch };

