import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'ObservableObject',
})
export class ObservableObject extends Vue {
    public created(): void {
        const recomputed = Object.create(null)
        const watchers = (this as any)._computedWatchers; // Warning: Vue internal

        if (!watchers) {
            return;
        }

        for (const key in watchers) {
            let watcher = watchers[key];
            const original = watcher.getter;
            recomputed[key] = true;
            watcher.getter = (vm: Vue) => (recomputed[key], original.call(vm, vm));
        }

        this.$raisePropertyChanged = (key: string) => recomputed[key] = !recomputed[key];

        Vue.observable(recomputed);
    }
}
