import { Exception, ArgumentNullException } from 'Api/Dto/Exception';

export interface IError {
    code: string;
    target: string;
    message: string;
    details: Array<IError>;
    innerError: IInnerError;
}

export interface IInnerError {
    code: string;
    innerError: IInnerError;
}

export interface IQueryResultBase {
    error?: IError
}

export interface IQueryResult<T> extends IQueryResultBase {
    result: T;
}

export interface IPaginationResult<T> extends IQueryResult<Array<T>> {
    currentPage: number;
    limit: number;
    pageCount: number;
    totalCount: number;
    previousLink: string;
    nextLink: string;
}

export interface IServerPaginationResult<T> extends IQueryResult<Array<T>> {
    nextLink: string;
}

export interface IPagination<T> {
    results: Array<T>;
    totalCount: number;
}

export class QueryException extends Exception {
    constructor(error: IError) {
        if(!error) {
            throw new ArgumentNullException(nameof(error));
        }

        super(error.message);

        this.error = error;
    }

    /**
     * Flattens all error messages
     */
    public get messages(): string {
        const flatMessages: string = this.error.details
            ?.flatMap(d => d.message)
            .reduce((p, c) => `${p}\n${c}`);

        return flatMessages ?? this.message;
    }

    public error: IError;
}

export class UnauthorizedException extends Exception {
    constructor() {
        super('Unauthorized');
    }
}

export class ForbiddenException extends Exception {
    constructor() {
        super('Forbidden');
    }
}

export class NotFoundException extends Exception {
    constructor(message?: string) {
        super(`Not found ${message}`);
    }
}

export interface IProblemDetails {
    title: string,
    detail: string,
    type: string,
    status: number,
    instance: string
}

export class ApiException extends Exception {
    public constructor(statusCode: number, error: IError) {
        super(`Api call returned an error code (${statusCode}). See error for details.`);

        this.statusCode = statusCode;
        this.error = error;
    }

    public readonly statusCode: number;
    public readonly error: IError;
}
