import { ISphericalCoordinates } from 'Api/Dto/Project';
import { AssetPanoramaGroup, AssetPanoramaVersion, FileMap, Navigation } from 'Api/ExploreService';
import { IPlayerContext } from 'App/Areas/Shares/IPlayerContext';
import CoordinatesHelper from 'App/Helpers/CoordinatesHelper';
import { injectTypes } from 'App/injectTypes';
import { lazyInject } from 'App/Inversify.config';
import { IVisonUriService } from 'App/Services/UriServices/Core';
import { ICartesianCoordinates } from 'Framework/Components/Controls/XView/Core';
import { editorBrowsable, JsonProperty, PropSyncWithInternal } from 'Framework/Components/Panel.Commons';
import { Component, UIElement } from 'Framework/Components/UIElement';
import MathHelper from 'Framework/Helpers/MathHelper';

enum BreadcrumbItemType {
    Home,
    Standard,
    VersionsList
}

interface IBreadcrumbItem {
    type: BreadcrumbItemType;
    text?: string;
}

@Component({
    name: 'vo-panorama-breadcrumb'
})
export default class VoPanoramaBreadcrumb extends UIElement<IPlayerContext> {
    private _onSelectedPanoramaVersionChange(newValue: AssetPanoramaVersion) {
        const currentOrientation: ISphericalCoordinates = MathHelper.isSphericalCoordinates(this._dataContext.currentOrientation)
            ? this._dataContext.currentOrientation
            : MathHelper.toSphericalCoordinates(this._dataContext.currentOrientation as ICartesianCoordinates);

        const newOrientation = CoordinatesHelper.changeAzimuthBase(currentOrientation, this._currentPanorama.offset, newValue.offset);
        const fov = this._dataContext.currentFocale;

        this._dataContext.goToPanoramaVersion(newValue, newOrientation, fov);
    }

    public get breadcrumbItems(): Array<IBreadcrumbItem> {
        let items: Array<IBreadcrumbItem> = [{ type: BreadcrumbItemType.Home }];

        if (this._currentMap) {
            items.push({ type: BreadcrumbItemType.Standard, text: this._currentMap.name });
        }

        if (this.currentPanoramaGroup) {
            items.push(
                { type: BreadcrumbItemType.Standard, text: this.currentPanoramaGroup.name },
                { type: BreadcrumbItemType.VersionsList }
            );
        }
        else if (this._currentPanorama) {
            items.push({ type: BreadcrumbItemType.Standard, text: this._currentPanorama.name })
        }

        return items;
    }

    public get currentPanoramaGroup(): AssetPanoramaGroup {
        if (!this._currentPanorama || !this._panoramas) {
            return null;
        }

        return this._panoramas.find(g => g.assetItemId == this._currentPanorama.parentId);
    }

    public BreadcrumbItemType: any = BreadcrumbItemType;

    @JsonProperty({ className: 'vo-panorama-breadcrumb', name: 'itemscurrentPanoramaSource', type: Object })
    @editorBrowsable({ groupName: 'Commons' })
    @PropSyncWithInternal('currentPanorama', { type: Object, required: false, default: null })
    public _currentPanorama: AssetPanoramaVersion;

    @JsonProperty({ className: 'vo-panorama-breadcrumb', name: 'currentNavigation', type: Object })
    @editorBrowsable({ groupName: 'Commons' })
    @PropSyncWithInternal('currentNavigation', { type: Object, required: false, default: null })
    public _currentNavigation: Navigation;

    @JsonProperty({ className: 'vo-panorama-breadcrumb', name: 'currentMap', type: Object })
    @editorBrowsable({ groupName: 'Commons' })
    @PropSyncWithInternal('currentMap', { type: Object, required: false, default: null })
    public _currentMap: FileMap;

    @JsonProperty({ className: 'vo-panorama-breadcrumb', name: 'panoramas', type: Array })
    @editorBrowsable({ groupName: 'Commons' })
    @PropSyncWithInternal('panoramas', { type: Array, required: false, default: () => [] })
    public _panoramas: Array<AssetPanoramaGroup>;

    @lazyInject(injectTypes.IVisonUriService)
    private readonly _uriService: IVisonUriService;
}
