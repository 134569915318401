import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
import BaseDialogBehavior, { IBaseDialogBehavior } from 'App/Services/DialogService/BaseDialogBehavior';
import { DialogResult } from 'Framework/Services/IDialogService';
import { DialogOptions } from 'App/Services/DialogService/Components/DialogOptions';

@Component
export default class VoDialogBase extends Vue {
    public close(result: DialogResult): void {
        this.dialog.onClose(result);
    }

    public maximize(): void {
        this.dialog.onMaximize();
    }

    public restore(): void {
        this.dialog.onRestore();
    }

    @Inject({ from: 'dialogBehavior', default: new BaseDialogBehavior()})
    public readonly dialog: IBaseDialogBehavior;

    @Inject()
    public readonly dialogOptions: DialogOptions;

    @Prop({ type: Boolean, default: false })
    public canMaximize: boolean;

    @Prop({ type: Boolean, default: false })
    public canRestore: boolean;

    @Prop({ type: Boolean, default: false })
    public noPadding: boolean;
}
