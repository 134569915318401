import { UIElement } from 'Framework/Components/UIElement';
import { Component, Vue } from 'vue-property-decorator';

export type ToastType = 'success' | 'error' | 'warning' | 'info' | 'primary' | 'secondary';

export interface IToastArgs {
    text: string,
    buttonText: string,
    type?: ToastType,
    timeout?: number
}

@Component
export class ShowToastMixin extends Vue {
    public showToast(text: string, buttonText: string, type?: ToastType, timeout?: number): void {
        const toastArgs: IToastArgs = {
            text: text,
            buttonText: buttonText,
            type: type,
            timeout: timeout
        };

        UIElement.Bus.$emit('show-toast', toastArgs);
    }
}
