import { IHotspot } from 'Framework/Components/Controls/XView/Core/Hotspots';

export enum TextureTypes {
    OpacityTexture = 1 << 0,
    EmissiveTexture = 1 << 1,
    DiffuseTexture = 1 << 2,
    AmbientTexture = 1 << 3,
    SpecularTexture = 1 << 4
}

export interface IIconInfo {
    url: string;
    textureType: TextureTypes;
    animation?: {
        nRows: number,
        nCols: number,
        startFrame: number,
        endFrame: number,
        frameRate: number
    };
}

export interface IIconProvider {
    getIconInfo(hotspot: IHotspot): IIconInfo;
}
