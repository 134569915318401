import { AppContext } from 'Api/AppContext';
import {
    IAbsoluteUriBehavior,
    IIconService,
    IJsonSchemaService,
    ISmartObjectService
} from 'Api/Contracts/Interfaces';
import { ExploreService } from 'Api/ExploreService';
import { FileUploader } from 'Api/FileUploader';
import { HttpClient } from 'Api/HttpClient';
import { ApiExceptionFactory, ResponseHandler } from 'Api/Infrastructure';
import { IResponseHandler } from 'Api/Infrastructure/Interfaces';
import { LocalizerService, LocalizerServiceStandalone } from 'Api/LocalizerService';
import {
    AccountService,
    AuditService,
    AuthenticationService,
    DriveService,
    IconService,
    IdentityService,
    JsonSchemaService,
    PasswordValidationService,
    PdfService,
    PlatformService,
    ProjectService,
    SettingService,
    SmartObjectService,
    TagService,
    VideoService,
    WorkerService
} from 'Api/Services';
import { VisonHttpClient } from 'App/Services/VisonHttpClient';
import { VDialogService } from 'App/Services/DialogService/DialogService';
import { IconProvider } from 'App/Services/IconProvider';
import { TokenValidator, TokenValidatorFactory } from 'App/Services/TokenValidator';
import { OfflineAbsoluteUriBehavior, OnlineAbsoluteUriBehavior } from 'App/Services/UriServices/AbsoluteUriBehaviors';
import { IVisonUriService } from 'App/Services/UriServices/Core';
import { VisonUriService } from 'App/Services/UriServices/VisonUriService';
import { injectTypes } from 'App/injectTypes';
import manifest from 'App/manifest.json';
import { IIconProvider } from 'Framework/Components/Controls/XView/Core';
import { IDialogService } from 'Framework/Services/IDialogService';
import { Container, interfaces } from 'inversify';
import getDecorators from 'inversify-inject-decorators';
import Vue from 'vue';

const serviceProvider = new Container();
const standaloneOnlineServiceProvider = serviceProvider.createChild();

const { lazyInject, lazyInjectNamed } = getDecorators(serviceProvider);
const { lazyInject: lazyInjectStandaloneOnline } = getDecorators(standaloneOnlineServiceProvider);

serviceProvider
    .bind<AppContext>(AppContext)
    .toDynamicValue((context: interfaces.Context) => {
        //TODO temporary code, find a better way, to process that
        let appContext: AppContext = null;

        let appContextBuilder: (c: AppContext) => void = window['appContextBuilder'];

        if (!appContextBuilder) {
            throw new Error('You must declare a function appContextBuilder: (c: AppContext) => void onto window object.');
        }

        appContext = new AppContext();
        appContextBuilder(appContext);
        appContext.information = manifest;

        return Vue.observable(appContext);
    })
    .inSingletonScope();

serviceProvider
    .bind<SettingService>(SettingService)
    .toSelf()
    .inRequestScope();

serviceProvider
    .bind<PlatformService>(PlatformService)
    .toSelf()
    .inRequestScope();

serviceProvider
    .bind<AuthenticationService>(AuthenticationService)
    .toSelf()
    .inRequestScope();

serviceProvider
    .bind<PdfService>(PdfService)
    .toSelf()
    .inRequestScope();

serviceProvider
    .bind<IVisonUriService>(injectTypes.IVisonUriService)
    .toDynamicValue((context: interfaces.Context) => {
        const appContext: AppContext = context.container.get(AppContext);

        const behavior: IAbsoluteUriBehavior = appContext.isStandalone
            ? new OfflineAbsoluteUriBehavior(appContext.standaloneWrapper.persistentFolder)
            : new OnlineAbsoluteUriBehavior();

        return new VisonUriService(appContext, appContext.baseUri, behavior);
    })
    .inRequestScope();

standaloneOnlineServiceProvider
    .bind<IVisonUriService>(injectTypes.IVisonUriService)
    .toDynamicValue((context: interfaces.Context) => {
        const appContext: AppContext = context.container.get(AppContext);

        return new VisonUriService(
            appContext,
            () => appContext.apiUri,
            new OnlineAbsoluteUriBehavior()
        );
    })
    .inSingletonScope();

serviceProvider
    .bind<PasswordValidationService>(PasswordValidationService)
    .toSelf()
    .inRequestScope();

serviceProvider
    .bind<LocalizerService>(LocalizerService)
    .toDynamicValue((context: interfaces.Context) => {
        const appContext: AppContext = context.container.get(AppContext);
        const httpClient: HttpClient = context.container.get(HttpClient);

        if (appContext.isStandalone) {
            return new LocalizerServiceStandalone(appContext, httpClient);
        }
        else {
            return new LocalizerService(appContext, httpClient);
        }
    })
    .inSingletonScope();

serviceProvider
    .bind<HttpClient>(HttpClient)
    .to(VisonHttpClient)
    .inRequestScope();

serviceProvider
    .bind<ExploreService>(ExploreService)
    .toSelf();

serviceProvider
    .bind<DriveService>(DriveService)
    .toSelf()
    .inRequestScope();

serviceProvider
    .bind<ProjectService>(ProjectService)
    .toSelf();

serviceProvider
    .bind<AccountService>(AccountService)
    .toSelf();

serviceProvider
    .bind<AuditService>(AuditService)
    .toSelf();

serviceProvider
    .bind<IdentityService>(IdentityService)
    .toSelf();

serviceProvider
    .bind<TagService>(TagService)
    .toSelf()
    .inRequestScope();

serviceProvider
    .bind<IDialogService>(injectTypes.IDialogService)
    .toDynamicValue(ctx => Vue.observable(new VDialogService()))
    .inTransientScope();

serviceProvider
    .bind<FileUploader>(FileUploader)
    .toSelf();

serviceProvider
    .bind<VideoService>(VideoService)
    .toSelf();

serviceProvider
    .bind<WorkerService>(WorkerService)
    .toSelf()
    .inRequestScope();

serviceProvider
    .bind<IIconProvider>(injectTypes.IIconProvider)
    .to(IconProvider)
    .inTransientScope();

serviceProvider
    .bind<ISmartObjectService>(injectTypes.ISmartObjectService)
    .to(SmartObjectService)
    .inRequestScope();

serviceProvider
    .bind<ApiExceptionFactory>(ApiExceptionFactory)
    .toSelf()
    .inSingletonScope();

serviceProvider
    .bind<IResponseHandler>(injectTypes.IResponseHandler)
    .to(ResponseHandler)
    .inSingletonScope();

serviceProvider
    .bind<TokenValidatorFactory>(injectTypes.TokenValidatorFactory)
    .toFactory<TokenValidator, [string]>((context: interfaces.Context) => {
        return (token: string) => new TokenValidator(
            context.container.get(injectTypes.IDialogService),
            context.container.get(AuthenticationService),
            token
        );
    });

serviceProvider
    .bind<IIconService>(injectTypes.IIconService)
    .to(IconService)
    .inRequestScope();

serviceProvider
    .bind<IJsonSchemaService>(injectTypes.IJsonSchemaService)
    .to(JsonSchemaService)
    .inRequestScope();

export { lazyInject, lazyInjectNamed, lazyInjectStandaloneOnline, serviceProvider };
