import { ChunkedFile } from 'Api/FileUploader';
import { Component, Prop, UIElement, Watch } from 'Framework/Components/UIElement';
import { FileHelper } from 'Framework/Helpers/FileHelper';

@Component({
    name: 'x-upload-file-entry'
})
export default class UploadFileEntry extends UIElement {
    private _formatFileEntrySize(fileProgress: number, fileSize: number): string {
        return FileHelper.formatSize((fileProgress / 100) * fileSize);
    }

    private _formatTotalSize(bytesFloat: number): string {
        return FileHelper.formatSize(bytesFloat);
    }

    @Watch('file.progress')
    private _onProgressionChanged(newValue: number) {
        if (newValue >= 1 && newValue < 100) {
            this.uploadStatus = UploadStatus.uploading;
        }
        else {
            this.uploadStatus = UploadStatus.uploaded;
        }
    }

    @Watch('file.hasError', { immediate: true })
    private _onErrorChanged(newValue: boolean) {
        if (newValue) {
            this.uploadStatus = UploadStatus.failed;
        }
    }

    public get onStatusChanged(): string {
        switch (this.uploadStatus) {
            case UploadStatus.uploading:
                return 'info'
            case UploadStatus.uploaded:
                return 'success';
            case UploadStatus.failed:
                return 'error';
            default:
                return 'gray'
        }
    }

    public get fileIcon(): string {
        switch (FileHelper.getFileExtension(this.file.name)) {
            case '.xlsx':
                return 'fa-light fa-file-excel';
            case '.pptx':
                return 'fa-light fa-file-powerpoint';
            case '.docx':
                return 'fa-light fa-file-word';
            case '.pdf':
                return 'fa-light fa-file-pdf';
            case '.mp4':
            case '.avi':
            case '.flv':
            case '.wmv':
            case '.mov':
            case '.mpg':
            case '.m1v':
            case '.mpeg':
                return 'fa-light fa-file-video';
            case '.jpg':
            case '.jpeg':
            case '.png':
            case '.bmp':
            case '.tif':
            case '.tiff':
                return 'fa-light fa-file-image';
            default:
                return 'fa-light fa-file';
        }
    }

    public uploadStatusEnumeration: any = UploadStatus;

    public uploadStatus: UploadStatus = UploadStatus.pending;

    @Prop({ type: Boolean, required: true, default: false })
    public isUploading: boolean;

    @Prop({ type: Object, required: true, default: null })
    public file: ChunkedFile;
}

export enum UploadStatus {
    pending,
    uploading,
    uploaded,
    failed
}
