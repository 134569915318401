import { PickingInfo, Vector3 } from '@babylonjs/core';
import { ICartesianCoordinates, ICoordinates, IPlanCoordinates, ISphericalCoordinates } from 'Framework/Components/Controls/XView/Core';

export class MathHelper {
    /**
    * @throws {ArgumentNullException}
    */
    public static toCartesianCoordinates(sphericalCoordinates: ISphericalCoordinates): ICartesianCoordinates {
        if (!sphericalCoordinates) {
            throw new Error('ArgumentNullException');
        }

        let theta = sphericalCoordinates.theta;
        let phi = sphericalCoordinates.phi;

        let x = Math.cos(phi) * Math.cos(theta);
        let y = Math.cos(phi) * Math.sin(theta);
        let z = Math.sin(phi);

        return {
            x: x,
            y: y,
            z: z
        };
    }

    public static toSphericalCoordinates(coordinates: ICartesianCoordinates | PickingInfo): ISphericalCoordinates {
        if (coordinates instanceof PickingInfo) {
            coordinates = MathHelper.leftHandedToRightHandedCoordinates(coordinates.pickedPoint);
        }

        const r = Math.sqrt(coordinates.x ** 2 + coordinates.y ** 2 + coordinates.z ** 2);

        const spherical: ISphericalCoordinates = {
            phi: Math.asin(coordinates.z / r),
            theta: Math.atan2(coordinates.y, coordinates.x)
        };

        return spherical;
    }

    public static leftHandedToRightHandedCoordinates(leftHandedCoordinates: ICartesianCoordinates | Vector3): ICartesianCoordinates {
        return {
            x: leftHandedCoordinates.z,
            y: -leftHandedCoordinates.x,
            z: leftHandedCoordinates.y
        };
    }

    public static isCartesianCoordinates(coordinates: ICoordinates): coordinates is ICartesianCoordinates {
        return coordinates
            && (coordinates as ICartesianCoordinates).x !== undefined
            && (coordinates as ICartesianCoordinates).y !== undefined
            && (coordinates as ICartesianCoordinates).z !== undefined;
    }

    public static isSphericalCoordinates(coordinates: ICoordinates): coordinates is ISphericalCoordinates {
        return coordinates
            && (coordinates as ISphericalCoordinates).phi !== undefined
            && (coordinates as ISphericalCoordinates).theta !== undefined;
    }

    public static isPlanCoordinates(coordinates: ICoordinates): coordinates is IPlanCoordinates {
        return coordinates
            && (coordinates as IPlanCoordinates).x !== undefined
            && (coordinates as IPlanCoordinates).y !== undefined
            && !('z' in coordinates);
    }

    /**
     * Computes `m = a mod b` so that 0 ⩽ m < |b| 
     * @param a
     * @param b
     */
    public static mod(a: number, b: number): number {
        const m = ((a % b) + b) % b;

        return m < 0 ? Math.abs(m) : m;
    }
}
