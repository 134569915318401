import { AppContext } from 'Api/AppContext';
import { MenuStateType } from 'Api/Enums/MenuStateType';
import { LocalizerService } from 'Api/LocalizerService';
import CookieConsent from 'App/Areas/Public/Components/CookieConsent.vue';
import { injectTypes } from 'App/injectTypes';
import { lazyInject } from 'App/Inversify.config';
import { IToastArgs, ToastType } from 'App/Mixins/ShowToastMixin';
import { IVisonUriService } from 'App/Services/UriServices/Core';
import { Component, Prop, UIElement, Vue, Watch } from 'Framework/Components/UIElement';
import { CookieConsentType, CookieHelper, ICookieConfig, ICookieConsentItem } from 'Framework/Helpers/CookieHelper';
import { UrlHelper } from 'Framework/Helpers/UrlHelper';
import { ComponentOptions } from 'vue';
import { Framework } from 'vuetify';

@Component
class BasePage extends UIElement {
    public beforeMount(): void {
        UIElement.Bus.$on('show-toast', this.onShowToastAsync);

        this._initializeCookieConsent();
    }

    public mounted(): void {
        this._logo = this.$refs['app-logo'] as HTMLObjectElement;

        if (this._logo) {
            this._logo.onload = this.onLogoLoaded;
        }
    }

    public destroy(): void {
        UIElement.Bus.$off('show-toast');
    }

    public static async InitializeFromBody<T extends Vue>(vuetifyOption?: any): Promise<T> {
        let mainDiv = document.getElementById('vue-data-context');
        let pageIdentifier = mainDiv.dataset['context'];
        let options: any = {};

        if (pageIdentifier?.startsWith('Spa')) {
            options.data = {};
            options.data.internals = {};
            options.data.internals.page = pageIdentifier.substring(4);
            pageIdentifier = 'Spa';
        }

        let pageType = BasePage._pages[pageIdentifier] || Vue.extend({ extends: BasePage });

        if (pageType) {
            let pageTypeLoader = pageType as any;

            if (typeof (pageTypeLoader) === 'function' && !pageTypeLoader.component) {
                pageTypeLoader = pageTypeLoader();

                if (Promise.resolve(pageTypeLoader) == pageTypeLoader) {
                    const { default: typeLoaded } = await pageTypeLoader;
                    pageType = typeLoaded;
                }
                else {
                    pageType = pageTypeLoader;
                }
            }

            return BasePage.Initialize<T>(mainDiv, pageType, vuetifyOption, options);
        }

        return null;
    }

    public static Initialize<T extends Vue>(element: HTMLElement, c: { new(options?: ComponentOptions<T>): T; }, vuetify?: any, options?: any): T {
        if (element) {
            let vmDefinition = { el: element, ...options };
            vmDefinition['vuetify'] = vuetify;

            let vm = new c(vmDefinition);
            let attributes: any = element.attributes || [];
            if (!attributes[Symbol.iterator]) {
                attributes = Array.from(attributes);
            }

            for (let attr of attributes) {
                let attributeName: string = BasePage.camelize(attr.name);
                if (attributeName[0] === ':') {
                    attributeName = attributeName.slice(1);
                }
                if (attributeName in vm) {
                    if (typeof (vm[attributeName]) == 'number') {
                        Vue.set(vm, attributeName, Number(attr.nodeValue));
                    }
                    else if (typeof (vm[attributeName]) == 'boolean') {
                        Vue.set(vm, attributeName, attr.nodeValue === 'true');
                    }
                    else {
                        if (attr.nodeValue.startsWith('{') || attr.nodeValue.startsWith('[')) {
                            Vue.set(vm, attributeName, JSON.parse(attr.nodeValue));
                            attr.nodeValue = '[object Object]';
                        }
                        else {
                            Vue.set(vm, attributeName, attr.nodeValue);
                        }
                    }
                }
            }
            window['mainViewModel'] = vm
            return vm;
        } else {
            return null;
        }
    }

    /**
    * Camelize a hyphen-delimited string.
    */
    public static camelize(str: string): string {
        return str.replace(BasePage.camelizeRE, (_, c) => { return c ? c.toUpperCase() : ''; });
    }

    /**
    * Hyphenate a camelCase string
    */
    public static hyphenate(str: string): string {
        return str.replace(BasePage.hyphenateRE, '-$1').toLowerCase();
    }

    public static RegisterPage(pageIdentifier: string, pageType: any) {
        BasePage._pages[pageIdentifier] = pageType;
    }

    public static ConfigureVuetifyLocalizer(vuetify: Framework, localizerService: LocalizerService): void {
        vuetify.lang.locales = localizerService.locale;
        vuetify.lang.current = localizerService.codeCulture;
    }

    public getUrlParameter(parameterName: string): string {
        return UrlHelper.getUrlParameter(parameterName);
    }

    public getUrlHashParameter(parameterName: string): string {
        return UrlHelper.getUrlHashParameter(parameterName);
    }

    public getUrlHashParameters(): any {
        return UrlHelper.getUrlHashParameters();
    };

    public async changeCultureAsync(codeCulture: string): Promise<void> {
        if (this.appContext.codeCulture != codeCulture) {
            // replace codeCulture in URl without reloading the page
            window.history.replaceState('', '', window.location.href.replace(this.appContext.codeCulture, codeCulture));
        }

        this.appContext.codeCulture = codeCulture;
        await this.localizerService.loadTranslationAsync(codeCulture);

        BasePage.ConfigureVuetifyLocalizer(this.vuetify, this.localizerService);
    }

    public showToastAsync(text: string, buttonText: string, type: ToastType = 'success', timeout: number = 4000): Promise<void> {
        if (!text || text == '' || !buttonText || buttonText == '') {
            throw new Error('Invalid parameters in showToast() method');
        }

        return new Promise(resolve => {
            this.toastOptions.icon = this._selectToastIcon(type);

            this.toastOptions.content = text;
            this.toastOptions.buttonText = buttonText;
            this.toastOptions.type = type;
            this.toastOptions.timeout = timeout

            this.isToastOpen = true;
            setTimeout(resolve, timeout);

        });
    }

    public async onShowToastAsync(args: IToastArgs): Promise<void> {
        await this.showToastAsync(args.text, args.buttonText, args.type, args.timeout);
    }

    public onLogoLoaded(e: Event): void {
        const logo = e.srcElement as HTMLObjectElement;
        const contentDocument = logo.contentDocument;

        if (contentDocument) {
            let style = getComputedStyle(this.$el);
            const documentElement = contentDocument.documentElement;

            const textColor = this.vuetify.theme.dark
                ? '--v-secondary-lighten4'
                : '--v-secondary-darken1';

            documentElement.style.setProperty('--accent-color', style.getPropertyValue('--v-primary-base'));
            documentElement.style.setProperty('--logo-text-color', style.getPropertyValue(textColor));
        }
    }

    private _initializeCookieConsent(): void {
        UIElement.Bus.$on(CookieConsent.EventName, async (c: CookieConsentType) => {
            this.partnerServices.forEach(ps => ps.value = c);
            await this._updateCookiesAsync();
        });
    }

    protected async _updateCookiesAsync(): Promise<void> {
        CookieHelper.setCookiesAsJson(this.partnerServices, this.cookieConsentConfig);
        this.$raisePropertyChanged(nameof(this.areCookiesAccepted));

        await this.showToastAsync(this.localizer('Views.Public.Cookies.UpdateConfirmation'), this.localizer('Close'));
    }

    private _selectToastIcon(type: ToastType): string {
        switch (type) {
            case 'success':
                return 'fa-circle-check';
            case 'error':
                return 'fa-triangle-exclamation';
            case 'warning':
                return 'fa-circle-exclamation';
            case 'info':
                return 'fa-circle-info';
            default:
                return '';
        }
    }

    protected static camelizeRE: RegExp = /-(\w)/g;
    protected static hyphenateRE: RegExp = /\B([A-Z])/g;

    @Watch('localizerService')
    private async _onLocalizerChanged(newValue: LocalizerService, oldValue: LocalizerService): Promise<void> {
        if (newValue) {
            await this.changeCultureAsync(newValue.codeCulture);
        }
    }

    @Watch('appContext.theme', { immediate: true })
    private async _onThemeChangedAsync(newValue: string, oldValue: string): Promise<void> {
        if (newValue) {
            await this.$nextTick();
            let vuetify = this.vuetify;

            vuetify.theme.dark = newValue.startsWith('dark');

            if (vuetify.theme.dark) {
                vuetify.theme.themes.dark = Object.assign(vuetify.theme.themes.dark, BasePage._themes[newValue]);

            } else {
                vuetify.theme.themes.light = Object.assign(vuetify.theme.themes.light, BasePage._themes[newValue]);
            }

            if (this._logo) {
                //ré-exécutue le onload
                let url = this._logo.data;

                this._logo.removeEventListener('onload', this.onLogoLoaded);
                this._logo.data = '';

                this._logo.addEventListener('onload', this.onLogoLoaded);
                this._logo.data = url;
            }
        }
    }

    @Watch('appContext.menuState', { immediate: true })
    private _onMenuStateChanged(newValue: string, oldValue: string): void {
        this.drawer = this.isDrawerOpenByDefault;
    }

    public get vuetify(): any {
        return (this as any).$vuetify;
    }

    public get isLoggedIn(): boolean {
        return this.appContext.user.isSignedIn;
    }

    public get isDrawerOpenByDefault(): boolean {
        return this.appContext.menuState != MenuStateType.Closed && !this.isMobileOrTabletDevice;
    }

    public get homePageLink(): string {
        let appContext = this.appContext;
        return `${appContext.codeCulture}${appContext.homePage}`;
    }

    public get cookieConsents(): Array<ICookieConsentItem> {
        return CookieHelper.getCookiesAsJson(this.cookieConsentConfig.name);
    }

    public get areCookiesAccepted(): boolean {
        return CookieHelper.isCookieExist(this.cookieConsentConfig.name);
    }

    public get isMobileOrTabletDevice(): boolean {
        return this.$vuetify.breakpoint.mobile;
    }

    @Prop({ type: String, required: false, default: null })
    public antiForgeryToken: string;

    protected static _pages: { [id: string]: any; } = {};

    @lazyInject(AppContext)
    public appContext: AppContext;

    @lazyInject(LocalizerService)
    public localizerService: LocalizerService;

    @lazyInject(injectTypes.IVisonUriService)
    public uriService: IVisonUriService;

    public isToastOpen: boolean = false;
    public toastOptions = {
        type: '',
        icon: '',
        content: '',
        buttonText: '',
        timeout: -1
    }

    public isDrawerEnabled: boolean = true;
    public isGoToHomeEnabled: boolean = true;
    public isFullScreen: boolean = false;
    public isSpa: boolean = false;
    public drawer: boolean = false;

    protected readonly cookieConsentConfig: ICookieConfig = {
        name: 'cookieconsent_status',
        domain: '',
        path: '/',
        expirationInDays: 90,
        sameSite: 'None',
        secure: true
    };

    protected readonly partnerServices: Array<ICookieConsentItem> = [
        //TODO: Get services dynamicaly from dataSource
        // { 
        //     name:'google', 
        //     value: CookieConsentType.None 
        // },
        // { 
        //     name:'bing', 
        //     value: CookieConsentType.None 
        // }
    ];

    protected baseTranslationPath: string = '';
    protected baseViewTranslationPath: string = '';

    private _logo: HTMLObjectElement;

    private static readonly _themes: any = {
        light: {
            primary: '#f18800',
            secondary: '#B0BEC5',
            accent: '#f18800',
            error: '#FF5252',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FFC107',
        },
        dark: {
            primary: '#f18800',
            secondary: '#424242',
            accent: '#f18800',
            error: '#FF5252',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FFC107',
        },
        'dark-cyan': {
            primary: '#00d2d3',
            accent: '#00d2d3',
        },
        'dark-blue': {
            primary: '#0078D7',
            accent: '#0078D7',
        },
        'dark-blue1': {
            primary: '#103494',
            accent: '#103494',
        },
        'dark-green': {
            primary: '#2ecc71',
            accent: '#2ecc71',
        },
        'dark-orange': {
            primary: '#f18800',
            accent: '#f18800',
        },
        'dark-pink': {
            primary: '#fd79a8',
            accent: '#fd79a8',
        },
        'dark-purple': {
            primary: '#8e44ad',
            accent: '#8e44ad',
        },
        'dark-red': {
            primary: '#f63622',
            accent: '#f63622',
        },
        'dark-yellow': {
            primary: '#ebb500',
            accent: '#ebb500',
        },
        'light-cyan': {
            primary: '#00d2d3',
            accent: '#00d2d3',
            secondary: '#B0BEC5',
        },
        'light-blue': {
            primary: '#0078D7',
            accent: '#0078D7',
            secondary: '#B0BEC5',
        },
        'light-blue1': {
            primary: '#103494',
            accent: '#103494',
            secondary: '#B0BEC5',
        },
        'light-green': {
            primary: '#2ecc71',
            accent: '#2ecc71',
            secondary: '#B0BEC5',
        },
        'light-orange': {
            primary: '#f18800',
            accent: '#f18800',
            secondary: '#B0BEC5',
        },
        'light-pink': {
            primary: '#fd79a8',
            accent: '#fd79a8',
            secondary: '#B0BEC5',
        },
        'light-purple': {
            primary: '#8e44ad',
            accent: '#8e44ad',
            secondary: '#B0BEC5',
        },
        'light-red': {
            primary: '#f63622',
            accent: '#f63622',
            secondary: '#B0BEC5',
        },
        'light-yellow': {
            primary: '#ebb500',
            accent: '#ebb500',
            secondary: '#B0BEC5',
        }
    };
}

export { BasePage, Component, Prop, Watch };
