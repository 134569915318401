import { HttpClient } from 'Api/HttpClient';
import { UriService } from 'Api/Services';
import { OnlineAbsoluteUriBehavior } from 'App/Services/UriServices/AbsoluteUriBehaviors';
import { injectable } from 'inversify';

/**
 * Represents a service for generating and downloading PDFs.
*/
@injectable()
export class PdfService {
    public constructor() {
        this._httpClient = new HttpClient(
            new UriService('https://sbspdfservice.azurewebsites.net', new OnlineAbsoluteUriBehavior()));
    }

    /**
     * Generates a PDF using the specified template and data.
     * @param templateName - The name of the template to use for generating the PDF.
     * @param data - The data to be used for generating the PDF.
     * @returns A Promise that resolves to a Blob representing the generated PDF.
     * @throws An error if the PDF generation fails.
     */
    public async generatePdfAsync(templateName: string, data: any): Promise<Blob> {
        const response: Response = await this._httpClient.postAsync(`templates/${templateName}`, data, null, new Headers({
            'Accept': 'application/pdf',
            'Content-Type': 'application/json'
        }));

        if (!response.ok) {
            throw new Error('Failed to generate PDF');
        }

        return await response.blob();
    }

    /**
     * Downloads a PDF from the specified URL with the given file name.
     * @param pdfUrl - The URL of the PDF to download.
     * @param fileName - The desired file name for the downloaded PDF.
     */
    public downloadPdf(pdfUrl: string, fileName: string): void {
        const link: HTMLAnchorElement = document.createElement('a');
        link.href = pdfUrl;
        link.download = fileName;
        link.click();
    }

    /**
     * Generates a PDF using the specified template and data, and then downloads it with the given file name.
     * @param templateName - The name of the template to use for generating the PDF.
     * @param data - The data to be used for generating the PDF.
     * @param fileName - The desired file name for the downloaded PDF.
     * @returns A Promise that resolves when the PDF generation and download are complete.
     */
    public async generateAndDownloadPdfAsync(templateName: string, data: any, fileName: string): Promise<void> {
        const pdf: Blob = await this.generatePdfAsync(templateName, data);
        this.downloadPdf(URL.createObjectURL(pdf), fileName);
    }

    private readonly _httpClient: HttpClient;
}
