import { ApiException, IError, IProblemDetails } from 'Api/Dto/QueryResult';
import { injectable } from 'inversify';

@injectable()
export class ApiExceptionFactory {
    public fromProblemDetails(problemDetails: IProblemDetails): ApiException {
        const error: IError = {
            code: problemDetails.title,
            message: problemDetails.detail,
            details: null,
            innerError: null,
            target: null
        };

        return new ApiException(
            problemDetails.status,
            error
        );
    }
}
