import { UIElement, Component, Prop } from 'Framework/Components/UIElement';

@Component({
    name: 'x-toggle'
})
export default class Toggle extends UIElement {
    @Prop({ type: Boolean, required: false, default: true })
    public defaultValue: boolean;

    @Prop({ type: Boolean, required: false, default: false })
    public closeOnBlur: boolean;

    @Prop({ type: String, required: false, default: 'header' })
    public toggleElementId: string;

    @Prop({ type: Boolean, required: false, default: false })
    public scrollToElementOnOpen: boolean;

    public isOpened: boolean = false;

    public mounted(): void {
        this.isOpened = this.defaultValue;

        let element = this.$el.querySelector(`#${this.toggleElementId}`) || this.$el.firstChild;
        (element as HTMLElement).style.pointerEvents = 'auto';
        if (element) {
            element.addEventListener('click', () => { this.toggleOpening(); });
        }

    }

    public toggleOpening(): void {
        this.isOpened = !this.isOpened;
        if (this.isOpened && this.scrollToElementOnOpen) {
            let element = this.$el.querySelector(`#${this.toggleElementId}`) || this.$el.firstChild;
            if (element) {
                this.$nextTick(() => {
                    (element as HTMLElement).scrollIntoView();
                });
            }
        }
    }

    public onBlur(): void {
        if (this.closeOnBlur) {
            this.isOpened = false;
        }
    }

    public get toggleOpenClass(): string {
        return this.isOpened ? 'opened' : 'closed';
    }

}
