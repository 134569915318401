export enum TooltipPosition { Top, Bottom, Left, Right, Center }
export enum TooltipVisibility { OnHover, Always }

export interface ITooltipStyle {
    position: TooltipPosition,
    color: string,
    size: number,
    visibility: TooltipVisibility
}

export class TooltipStyle implements ITooltipStyle {
    public static get defaultTop(): ITooltipStyle {
        return {
            position: TooltipPosition.Top,
            color: '#dddddd',
            size: 12,
            visibility: TooltipVisibility.OnHover
        };
    }

    public static get defaultCenter(): ITooltipStyle {
        return {
            position: TooltipPosition.Center,
            color: '#dddddd',
            size: 12,
            visibility: TooltipVisibility.OnHover
        };
    }

    public position: TooltipPosition;
    public color: string;
    public size: number;
    public visibility: TooltipVisibility;
}
