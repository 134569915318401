import { AppContext } from 'Api/AppContext';
import { injectTypes } from 'App/injectTypes';
import { IVisonUriService } from 'App/Services/UriServices/Core';
import { IHotspot, IIconInfo, IIconProvider, TextureTypes } from 'Framework/Components/Controls/XView/Core';
import { inject, injectable } from 'inversify';

@injectable()
export class IconProvider implements IIconProvider {
    public constructor(
        @inject(AppContext) appContext: AppContext,
        @inject(injectTypes.IVisonUriService) uriService: IVisonUriService
    ) {
        this._appContext = appContext;
        this._uriService = uriService;
    }

    public getIconInfo(hotspot: IHotspot): IIconInfo {
        const iconParts = (hotspot.content.icon as string).split(' ');

        let iconGroup: string;
        let iconName: string;
        let textureType: TextureTypes;

        if (iconParts[0] == 'sbs') {
            if (iconParts[2]?.startsWith('hs-roav7-')) {
                iconGroup = 'roav7';
                textureType = TextureTypes.OpacityTexture;
                iconName = iconParts[2];
            }
        }
        else {
            iconGroup = iconParts[0];
            iconName = iconParts[1];
            textureType = TextureTypes.OpacityTexture;
        }

        const iconInfo: IIconInfo = {
            url: this._uriService.getAbsoluteUri(`${this._appContext.codeCulture}/asset/icons/${iconGroup}/${iconName}`),
            textureType: textureType
        };

        if (iconParts[0] == 'animated') {
            iconInfo.animation = {
                nRows: 1,
                nCols: 28,
                frameRate: 20,
                startFrame: 0,
                endFrame: 27
            };
        }

        return iconInfo;
    }

    private readonly _appContext: AppContext;
    private readonly _uriService: IVisonUriService;
}
