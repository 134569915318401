import { Component, UIElement } from 'Framework/Components/UIElement';
import { CookieConsentType } from 'Framework/Helpers/CookieHelper';

@Component({
    name: 'cookie-consent'
})
export default class CookieConsent extends UIElement {
    private _onAcceptAll(): void {
        this._updateAllCookies(CookieConsentType.Allow);
    }

    private _onDenyAll(): void {
        this._updateAllCookies(CookieConsentType.Deny);
    }

    private _updateAllCookies(consentType: CookieConsentType): void {
        UIElement.Bus.$emit(CookieConsent.EventName, consentType);
        this.$emit(CookieConsent.EventName, consentType);
    }

    public static readonly EventName = 'cookies-accepted';
}
