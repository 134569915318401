import { ILoginRequest, ILoginResponse, IWebLoginViewModel } from 'Api/Dto/Authentication';
import { Exception } from 'Api/Dto/Exception';
import { ForbiddenException, IQueryResult, IQueryResultBase, QueryException } from 'Api/Dto/QueryResult';
import HttpClient from 'Api/HttpClient';
import { IResponseHandler } from 'Api/Infrastructure/Interfaces';
import { Routes } from 'Api/Routes';
import { injectTypes } from 'App/injectTypes';
import { inject, injectable } from 'inversify';

@injectable()
export class AuthenticationService {
    constructor(
        @inject(HttpClient) httpClient: HttpClient,
        @inject(injectTypes.IResponseHandler) responseHandler: IResponseHandler
    ) {
        this._httpClient = httpClient;
        this._responseHandler = responseHandler;
    }

    public async signInAsync(params: ILoginRequest): Promise<ILoginResponse> {
        let response = await this._httpClient.postAsync(Routes.Api.Authentication.Login, params, null);
        const queryResult = await response.json() as IQueryResult<ILoginResponse>;

        if (response.status !== 200) {
            throw new QueryException(queryResult.error);
        }

        return queryResult.result;
    }

    public async singleSignOnAsync(onRedirect?: () => void): Promise<ILoginResponse> {
        let response = await this._httpClient.getAsync(Routes.Api.Authentication.SingleSignOn);

        if (response.redirected) {
            onRedirect?.();

            return null;
        }

        if (response.status != 200) {
            if (response.status == 403) {
                throw new ForbiddenException();
            }
            else {
                throw new Exception('Connection to the server is not possible');
            }
        }

        const queryResult = await response.json() as IQueryResult<ILoginResponse>;

        return queryResult.result;
    }

    public async webSignInAsync(userName: string, password: string, rememberMe: boolean = false): Promise<void> {
        const response = await this._httpClient.postFormDataAsync(
            'account/login',
            {
                userName: userName,
                password: password,
                rememberMe: rememberMe
            } as IWebLoginViewModel
        )

        if (response.status != 204) {
            const queryException = await response.json() as IQueryResultBase;
            throw new QueryException(queryException.error);
        }
    }

    public async authenticateForShareAsync(token: string, password: string): Promise<string> {
        return await this._responseHandler.handleResponseAsync<string>(
            await this._httpClient.postAsync(Routes.Api.Authentication.Share, {
                token: token,
                password: password }));
    }

    protected readonly _httpClient: HttpClient;
    protected readonly _responseHandler: IResponseHandler;
}
