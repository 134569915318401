export interface ICookieConsentItem {
    name: string,
    value: CookieConsentType
}

export interface ICookieConfig {
    name: string;
    domain?: string;
    path?: string;
    expirationInDays?: number;
    sameSite?: 'Lax' | 'Strict' | 'None';
    secure?: boolean;
}

export enum CookieConsentType {
    None = 0,
    Deny = 1,
    Allow = 2
}

export class CookieHelper {
    public static getCookies(): Array<{key: string, value: string}> {
        return document.cookie
            .split(';')
            .map(part => {
                let [key, value] = part.split('=');
                key = key.trim();

                if(value) {
                    value = decodeURIComponent(value);
                }

                return { key, value };
            });
    }

    public static getCookie(name: string): string {
        let cookie = CookieHelper.getCookies()
            .find(k => k.key == name)

        return cookie ? cookie.value : null;
    }

    public static isCookieExist(name: string): boolean {
        return document.cookie
            .split(';')
            .filter(c => c.trim().startsWith(`${name}=`)).length > 0;
    }

    public static getCookiesAsJson(name: string): Array<ICookieConsentItem> {
        let jsonResult = CookieHelper.getCookie(name);
        let result: Array<ICookieConsentItem> = JSON.parse(jsonResult);

        if(jsonResult == null) {
            result = [];
        }

        return result;
    }

    public static setCookiesAsJson(value: any, config: ICookieConfig): void {
        let jsonValue = encodeURIComponent(JSON.stringify(value));

        let cookie = [
            `${config.name}=${jsonValue}`,
            `path=${(config.path || '/')}`
        ]

        if (config.expirationInDays) {
            let expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + (config.expirationInDays || 365));
            cookie.push(`expires=${expirationDate.toUTCString()}`);
        }

        if (config.domain) {
            cookie.push(`domain=${config.domain}`);
        }

        if (config.sameSite) {
            cookie.push(`SameSite=${config.sameSite}`);
        }

        if (config.secure) {
            cookie.push('Secure');
        }

        document.cookie = cookie.join(';');
    }
}
