import { SmartObjectDto } from 'Api/Contracts/Dtos';
import { CreateSmartObjectInputDto, SmartObjectSearchCriteriaDto, SmartObjectGroupingDto, UpdateSmartObjectInputDto } from 'Api/Contracts/Dtos/SmartObject';
import { ISmartObjectService } from 'Api/Contracts/Interfaces';
import { IPaginationResult } from 'Api/Dto/QueryResult';
import HttpClient from 'Api/HttpClient';
import { IResponseHandler } from 'Api/Infrastructure/Interfaces';
import { Routes } from 'Api/Routes';
import { injectTypes } from 'App/injectTypes';
import { inject, injectable } from 'inversify';
import { String } from 'typescript-string-operations';

@injectable()
export class SmartObjectService implements ISmartObjectService {
    public constructor(
        @inject(HttpClient) httpClient: HttpClient,
        @inject(injectTypes.IResponseHandler) responseHandler: IResponseHandler
    ) {
        this._httpClient = httpClient;
        this._responseHandler = responseHandler;
    }

    public async getObjectAsync(
        smartObjectId: number,
        expand?: string,
        token?: string
    ): Promise<SmartObjectDto> {
        const endpoint: string = String.Format(
            Routes.Api.SmartObjects.SmartObject,
            smartObjectId);

        const queryParameters = expand
            ? { $expand: 'schema', token: token }
            : { token: token };

        const response: Response = await this._httpClient.getAsync(
            endpoint,
            null,
            queryParameters);

        return await this._responseHandler
            .handleResponseAsync<SmartObjectDto>(response);
    }

    public getObjectsAsync(
        smartObjectSearchCriteriaDto?: SmartObjectSearchCriteriaDto,
        token?: string
    ): Promise<IPaginationResult<SmartObjectGroupingDto>>;

    public getObjectsAsync(
        endpoint: string,
        token?: string
    ): Promise<IPaginationResult<SmartObjectGroupingDto>>;

    public async getObjectsAsync(
        arg?: SmartObjectSearchCriteriaDto | string,
        token: string = null
    ): Promise<IPaginationResult<SmartObjectGroupingDto>> {
        const response: Response = typeof arg === 'string'
            ? await this._httpClient.getAsync('', arg, null, new Headers({ token: token }))
            : await this._httpClient.getAsync(Routes.Api.SmartObjects.Base, null, { ...arg, token: token });

        return await this._responseHandler
            .handleResponseAsync<IPaginationResult<SmartObjectGroupingDto>>(response);
    }

    public async createObjectAsync<TResult>(
        smartObjectInput: CreateSmartObjectInputDto
    ): Promise<SmartObjectDto<TResult>> {
        const response: Response = await this._httpClient
            .postAsync(Routes.Api.SmartObjects.Base, smartObjectInput);

        return await this._responseHandler
            .handleResponseAsync<SmartObjectDto<TResult>>(response);
    }

    public async updateObjectAsync(
        smartObjectId: number,
        smartObjectInput: UpdateSmartObjectInputDto
    ): Promise<void> {
        const endpoint: string = String.Format(
            Routes.Api.SmartObjects.SmartObject,
            smartObjectId);

        const response: Response = await this._httpClient.putAsync(endpoint, smartObjectInput);

        await this._responseHandler.handleErrorsAsync(response);
    }

    public async deleteObjectAsync(smartObjectId: number): Promise<void> {
        const endpoint: string = String.Format(
            Routes.Api.SmartObjects.SmartObject,
            smartObjectId);

        const response: Response = await this._httpClient.deleteAsync(endpoint, null);

        await this._responseHandler.handleErrorsAsync(response);
    }

    private readonly _httpClient: HttpClient;
    private readonly _responseHandler: IResponseHandler;
}
