export class Exception extends Error {
    constructor(message: string) {
        super(message);
    }
}

export class ArgumentNullException extends Exception {
    constructor(parameterName: string) {
        super(`The value cannot be null. Parameter Name: ${parameterName}`);
    }
}

export class NotSupportedException extends Exception {
    constructor() {
        super("La méthode spécifiée n'est pas prise en charge");
    }
}

export class NotImplementedException extends Exception {
    constructor(message?: string) {
        super('The method or operation is not implemented. ' + message);
    }
}

export class RangeException extends Exception {
    constructor(parameterName: string, minimum: number, maximum?: number) {
        const constraint = maximum
            ? `between ${minimum} and ${maximum}`
            : `greater than or equal to ${minimum}`;

        super(`Value for parameter ${parameterName} must be ${constraint}.`);
    }
}
