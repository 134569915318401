export class Stack<T> {
    public push(item: T): void {
        if (this._currentItem) {
            this._list.push(this._currentItem);
        }

        this._currentItem = item;
    }

    public pop(): void {
        this._currentItem = this._list.pop() ?? null;
    }

    public get length(): number {
        return this._list.length + (this._currentItem == null ? 0 : 1);
    }

    public get currentItem(): T {
        return this._currentItem;
    }

    private _currentItem: T = null;
    private readonly _list: Array<T> = [];
}
