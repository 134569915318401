import { IAuditLog } from 'Api/Dto/Audit';
import { IPaginationResult } from 'Api/Dto/QueryResult';
import { HttpClient } from 'Api/HttpClient';
import { Routes } from 'Api/Routes';
import { inject, injectable } from 'inversify';

@injectable()
export class AuditService {
    constructor(@inject(HttpClient) httpClient: HttpClient) {
        this._httpClient = httpClient;
    }

    public async getDataAsync(params: any): Promise<IPaginationResult<IAuditLog>> {
        let response = await this._httpClient.getAsync(Routes.Api.Audits.Logs.Base, null, params);

        return await response.json() as IPaginationResult<IAuditLog>;
    }

    protected readonly _httpClient: HttpClient;
}
