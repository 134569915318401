export enum CoordinateSystem { Plane, Spherical, Cartesian }

export const DefaultCoordinates: ICartesianCoordinates = { x: 1, y: 0, z: 0 };

export interface ICoordinates {
}

export interface IPlanCoordinates extends ICoordinates {
    x: number;
    y: number;
}

export interface ISphericalCoordinates extends ICoordinates {
    /** Horizontal angle over [+π;-π] */
    theta: number;
    /** Vertical angle over [+π/2;-π/2] */
    phi: number;
}

export interface ICartesianCoordinates extends ICoordinates {
    x: number;
    y: number;
    z: number;
}