import { Routes } from 'Api/Routes';
import { IPlatform } from 'Api/Dto/Admin/Platform';
import { IQueryResultBase, QueryException, IQueryResult, IPaginationResult } from 'Api/Dto/QueryResult';
import HttpClient from 'Api/HttpClient';
import { inject, injectable } from 'inversify';
import { String } from 'typescript-string-operations';
import { IDriveSettings } from 'Api/Dto/Admin/DriveSettings';
import { AddSubscriptionViewModel } from 'Api/Dto/Admin/ViewModels/Subscription/AddSubscriptionViewModel';
import { EditSubscriptionViewModel } from 'Api/Dto/Admin/ViewModels/Subscription/EditSubscriptionViewModel';
import { ISubscription } from 'Api/Dto/Admin/Subscription';
import { IdentityProviderViewModel } from 'Api/Dto/Admin/ViewModels/IdentityProvider/IdentityProviderViewModel';
import { ICaptchaSettings } from 'Api/Dto/Admin/CaptchaSettings';
import { ILoginSettings } from 'Api/Dto/Admin/LoginSettings';
import { IDomainNameSystemViewModel } from 'Api/Dto/Admin/ViewModels/DomainNameSystem/DomainNameSystemViewModel';
import { IDomainNameSystem } from 'Api/Dto/Admin/DomainNameSystem';
import { IIdentityProvider } from 'Api/Dto/Admin/IdentityProvider';
import { injectTypes } from 'App/injectTypes';
import { IResponseHandler } from 'Api/Infrastructure/Interfaces';

@injectable()
export class PlatformService {
    public constructor(
        @inject(HttpClient) httpClient: HttpClient,
        @inject(injectTypes.IResponseHandler) responseHandler: IResponseHandler
    ) {
        this._httpClient = httpClient;
        this._responseHandler = responseHandler;
    }

    public async getPlatformsAsync(): Promise<Array<IPlatform>> {
        let response = await this._httpClient.getAsync(Routes.Api.Platforms.Base);
        let jsonResponse = await response.json();

        if (response.status == 400) {
            //TODO handle error and use type queryresult. 
        }

        return jsonResponse.result as Array<IPlatform>;
    }

    public async getPlatformByIdAsync(platformId: number): Promise<IPlatform> {
        let response = await this._httpClient.getAsync(String.Format(Routes.Api.Platforms.Get, platformId));
        let jsonResponse = await response.json();

        if (response.status == 400) {
            //TODO handle error and use type queryresult. 
        }

        return jsonResponse.result as IPlatform;
    }

    public async changeLogoAsync(platformId: number, logo: Blob): Promise<void> {
        const response = await this._httpClient.postFormDataAsync(String.Format(Routes.Api.Platforms.Logo, platformId), { logo });

        if (response.status != 204) {
            let queryError = await response.json() as IQueryResultBase;

            throw new QueryException(queryError.error);
        }
    }

    public async deleteLogoAsync(platformId: number): Promise<void> {
        const response = await this._httpClient.deleteAsJsonAsync(null, null, String.Format(Routes.Api.Platforms.Logo, platformId));

        if (response.status == 400) {
            let queryError = await response.json() as IQueryResultBase;

            throw new QueryException(queryError.error);
        }
    }

    public async deletePlatformsAsync(platformIds: number[]): Promise<void> {
        const response = await this._httpClient.deleteAsJsonAsync(platformIds, null, Routes.Api.Platforms.Base);

        if (response.status != 204) {
            let queryError = await response.json() as IQueryResultBase;

            throw new Error(queryError.error.message);
        }
    }

    public async deleteSubscriptionAsync(platformId: number, subscriptionIds: Array<number>): Promise<void> {
        const url = String.Format(Routes.Api.Platforms.Subscriptions.Delete, platformId);
        const response = await this._httpClient.postFormDataAsync(url, { subscriptionIds: subscriptionIds });

        if (response.status != 204) {
            const queryError = await response.json() as IQueryResultBase;

            throw new QueryException(queryError.error);
        }
    }

    public async stopPlatformsAsync(platformIds: number[]): Promise<void> {
        const response = await this._httpClient.postAsJsonAsync(platformIds, null, Routes.Api.Platforms.Stop);

        if (response.status != 204) {
            let queryError = await response.json() as IQueryResultBase;

            throw new Error(queryError.error.message);
        }
    }

    public async runPlatformsAsync(platformIds: number[]): Promise<void> {
        const response = await this._httpClient.postAsJsonAsync(platformIds, null, Routes.Api.Platforms.Run);

        if (response.status != 204) {
            let queryError = await response.json() as IQueryResultBase;

            throw new Error(queryError.error.message);
        }
    }

    public async getCurrentDriveSettingsAsync(): Promise<IDriveSettings> {
        let response = await this._httpClient.getAsync(Routes.Api.Platforms.Settings.Drive);
        let jsonResponse = await response.json();

        if (response.status == 400) {
            let queryError = jsonResponse as IQueryResultBase;
            throw new QueryException(queryError.error);
        }

        return jsonResponse.result as IDriveSettings;
    }

    public async updateDriveSettingsAsync(driveSettings: IDriveSettings): Promise<void> {
        let response = await this._httpClient.postAsJsonAsync(driveSettings, null, Routes.Api.Platforms.Settings.Drive);

        if (response.status == 400) {
            let queryError = await response.json() as IQueryResultBase;
            throw new QueryException(queryError.error);
        }
    }

    public async getCurrentSecuritySettingsAsync(): Promise<ICaptchaSettings> {
        let response = await this._httpClient.getAsync(Routes.Api.Platforms.Settings.Security);
        let jsonResponse = await response.json();

        if (response.status == 400) {
            let queryError = jsonResponse as IQueryResultBase;
            throw new QueryException(queryError.error);
        }

        return (jsonResponse as IQueryResult<ICaptchaSettings>).result;
    }

    public async updateSecuritySettingsAsync(settings: ICaptchaSettings): Promise<void> {
        let response = await this._httpClient.postAsJsonAsync(settings, null, Routes.Api.Platforms.Settings.Security);

        if (response.status == 400) {
            let queryError = await response.json() as IQueryResultBase;
            throw new QueryException(queryError.error);
        }
    }

    public async getCurrentLoginSettingsAsync(): Promise<ILoginSettings> {
        const response = await this._httpClient.getAsync(Routes.Api.Platforms.Settings.Login);
        const queryResult = await response.json() as IQueryResult<ILoginSettings>;

        if (response.status != 200) {
            throw new QueryException(queryResult.error);
        }

        return queryResult.result;
    }

    public async updateLoginSettingsAsync(settings: ILoginSettings): Promise<void> {
        const response = await this._httpClient.postAsJsonAsync(
            settings,
            null,
            Routes.Api.Platforms.Settings.Login
        );

        if (response.status != 204) {
            const queryError = await response.json() as IQueryResultBase;
            throw new QueryException(queryError.error);
        }
    }

    public async getSubscriptionAsync(platformId: number, subscriptionId: number): Promise<ISubscription> {
        const url = String.Format(Routes.Api.Platforms.Subscriptions.Get, platformId, subscriptionId);
        const response = await this._httpClient.getAsync(url);
        const jsonResponse = await response.json();

        if (response.status == 400) {
            const queryError = jsonResponse as IQueryResultBase;

            throw new QueryException(queryError.error);
        }

        return (jsonResponse as IQueryResult<ISubscription>).result;
    }

    public async addSubscriptionAsync(viewModel: AddSubscriptionViewModel): Promise<void> {
        let response = await this._httpClient.postFormDataAsync(Routes.Api.Subscriptions.Base, viewModel);

        if (response.status != 201) {
            let queryError = await response.json() as IQueryResultBase;
            throw new QueryException(queryError.error);
        }
    }

    public async addIdentityProviderAsync(platformId: number, viewModel: IdentityProviderViewModel): Promise<void> {
        let response = await this._httpClient.postFormDataAsync(String.Format(Routes.Api.Platforms.IdentityProviders.Create, platformId), viewModel);

        if (response.status != 204) {
            let queryError = await response.json() as IQueryResultBase;
            throw new QueryException(queryError.error);
        }
    }

    public async editSubscriptionAsync(platformId: number, subscriptionId: number, viewModel: EditSubscriptionViewModel): Promise<void> {
        const url = String.Format(Routes.Api.Platforms.Subscriptions.Get, platformId, subscriptionId);
        //TODO: utiliser Patch
        const response = await this._httpClient.postFormDataAsync(url, viewModel);

        if (response.status != 204) {
            const queryError = await response.json() as IQueryResultBase;
            throw new QueryException(queryError.error);
        }
    }

    public async deleteIdentityProviderAsync(platformId: number, identityProviderIds: Array<number>): Promise<void> {
        const url = String.Format(Routes.Api.Platforms.IdentityProviders.Delete, platformId);
        const response = await this._httpClient.postFormDataAsync(url, { identityProviderIds: identityProviderIds });

        if (response.status != 204) {
            const queryError = await response.json() as IQueryResultBase;
            throw new QueryException(queryError.error);
        }
    }

    public async getIdentityProviderAsync(platformId: number, identityProviderId: number): Promise<IdentityProviderViewModel> {
        const url = String.Format(Routes.Api.Platforms.IdentityProviders.Get, platformId, identityProviderId);
        const response = await this._httpClient.getAsync(url);
        const jsonResponse = await response.json();

        if (response.status != 200) {
            const queryError = jsonResponse as IQueryResultBase;
            throw new QueryException(queryError.error);
        }

        return (jsonResponse as IQueryResult<IdentityProviderViewModel>).result;
    }

    public async getIdentityProvidersAsync(
        searchText?: string,
        sortOrder?: string,
        skip?: number,
        top?: number
    ): Promise<IPaginationResult<IIdentityProvider>> {
        const response: Response = await this._httpClient.getAsync(
            Routes.Api.Platforms.IdentityProviders.Base,
            null,
            {
                searchText,
                sortOrder,
                skip,
                top
            });

        return this._responseHandler
            .handleResponseAsync<IPaginationResult<IIdentityProvider>>(response);
    }

    public async updateIdentityProviderAsync(platformId: number, identityProviderId: number, viewModel: IdentityProviderViewModel): Promise<void> {
        const url = String.Format(Routes.Api.Platforms.IdentityProviders.Get, platformId, identityProviderId);
        const response = await this._httpClient.postFormDataAsync(url, viewModel);

        if (response.status != 204) {
            const queryError = await response.json() as IQueryResultBase;
            throw new QueryException(queryError.error);
        }
    }

    public async deleteDnsAsync(platformId: number, domainNameSystemIds: Array<number>): Promise<void> {
        const url = String.Format(Routes.Api.Platforms.Settings.Dns.Base);
        const response = await this._httpClient.deleteAsJsonAsync(domainNameSystemIds, null, url);

        if (response.status != 204) {
            const queryError = await response.json() as IQueryResultBase;
            throw new QueryException(queryError.error);
        }
    }

    public async createDnsAsync(addDomainViewModel: IDomainNameSystemViewModel): Promise<IDomainNameSystem> {
        const url = Routes.Api.Platforms.Settings.Dns.Base;
        let response = await this._httpClient.postAsync(url, addDomainViewModel);
        let jsonResponse = await response.json();

        if (response.status == 400) {
            const queryError = jsonResponse as IQueryResultBase;
            throw new QueryException(queryError.error);
        }

        return (jsonResponse as IQueryResult<IDomainNameSystem>).result;
    }

    public async editDnsAsync(dnsId: number, editDomainViewModel: IDomainNameSystemViewModel): Promise<void> {
        const url = String.Format(Routes.Api.Platforms.Settings.Dns.Edit, dnsId);
        let response = await this._httpClient.putAsync(url, editDomainViewModel);

        if (response.status != 204) {
            const queryError = await response.json() as IQueryResultBase;
            throw new QueryException(queryError.error);
        }
    }

    protected readonly _httpClient: HttpClient;
    private readonly _responseHandler: IResponseHandler;
}
