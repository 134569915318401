import { AppContext } from 'Api/AppContext';
import { ISymlinkInputDto, Tag } from 'Api/Contracts/Dtos';
import { ITagService } from 'Api/Contracts/Interfaces';
import { IUserFileShares } from 'Api/Dto/Admin/UserFileShares';
import { FileTaskResult, File as FileVison, IFilePermission, IFileValidationResult } from 'Api/Dto/Drive';
import { SearchFilesViewModel } from 'Api/Dto/Drive/ViewModels/SearchFilesViewModel';
import { Exception } from 'Api/Dto/Exception';
import { IPaginationResult, IQueryResultBase, IServerPaginationResult, QueryException } from 'Api/Dto/QueryResult';
import { ISearchResult } from 'Api/Dto/SearchResult';
import { IUserFileShare } from 'Api/Dto/UserFileShare';
import { FileSelectionType } from 'Api/Enums/FileSelectionType';
import { DrivePermissions } from 'Api/Enums/Permissions';
import { ChunkedFile } from 'Api/FileUploader';
import { HttpClient } from 'Api/HttpClient';
import { IResponseHandler } from 'Api/Infrastructure/Interfaces';
import { Routes } from 'Api/Routes';
import { CreateFileResult, GetFileInfoResult, GetFilesResult, MoveFileViewModel, ShareResult } from 'Api/ViewModel/Drive';
import { injectTypes } from 'App/injectTypes';
import { IVisonUriService } from 'App/Services/UriServices/Core';
import { inject, injectable } from 'inversify';
import { String } from 'typescript-string-operations';

export type SortDirection = 'Ascending' | 'Descending';
export type OrderBy = 'Name' | 'CreateDate' | 'UpdateDate' | 'Rank';
export type Filter = 'None' | 'Image' | 'Video' | 'PDF' | 'Other';

@injectable()
export class DriveService implements ITagService<FileVison> {
    protected _appContext: AppContext;

    constructor(
        @inject(AppContext) appContext: AppContext,
        @inject(HttpClient) httpClient: HttpClient,
        @inject(injectTypes.IVisonUriService) uriService: IVisonUriService,
        @inject(injectTypes.IResponseHandler) responseHandler: IResponseHandler
    ) {
        this._appContext = appContext;
        this._httpClient = httpClient;
        this._uriService = uriService;
        this._responseHandler = responseHandler;

        this.codeCulture = appContext.codeCulture;
        this.searchText = null;
        this.orderBy = 'Name';
        this.direction = 'Ascending';
        this.filter = 'None';
    }

    public async validateFilesAsync(parentGuid: string, files: Array<{ name: string, size: number }>): Promise<Array<IFileValidationResult>> {
        let postData = {
            destinationDirectory: parentGuid,
            files: files
        };

        let response = await this._httpClient.postAsync('/drive/home/validatefiles', postData);
        let jsonResult = await response.json();

        return jsonResult as Array<IFileValidationResult>;
    }

    public onCreateFileAfterUploadCallbackFactory(parentDirectory: FileVison): (chunk?: ChunkedFile) => Promise<void> {
        const directoryGuid = parentDirectory.guid;

        return async f => {
            await this.addFileAsync(directoryGuid, f);
        };
    }

    public onReplaceFileAfterUploadCallbackFactory(fileToReplaceGuid: string): (chunk?: ChunkedFile) => Promise<void> {
        return async f => {
            await this.replaceFileAsync(fileToReplaceGuid, f.guid);
        };
    }

    public onValidateServerSideCallbackFactory(parentDirectory: FileVison): (files: Array<File>) => Promise<Array<ChunkedFile>> {
        return async (files: Array<File>) => {
            const validationResults: Array<IFileValidationResult> = await this.validateFilesAsync(
                parentDirectory.guid,
                files.map(f => ({ name: f.name, size: f.size }))
            );

            return validationResults
                .filter(vr => vr.errors.length > 0)
                .map(vr => {
                    const file = files.find(f => f.name == vr.fileName);

                    const chunkedFile = new ChunkedFile(file);
                    chunkedFile.hasError = true;
                    chunkedFile.errors = vr.errors;

                    return chunkedFile;
                });
        }
    }

    public async share(selectedFile: FileVison, identityIds: Array<number>, allowEdit: boolean, expireDateAfterDays: number = 0): Promise<ShareResult> {
        // /fr-fr/drive/share/
        let url = `/drive/home/share`;

        let postData = {
            'identityIds': identityIds,
            'allowEdit': allowEdit,
            'fileId': selectedFile.fileId,
            'expireDateAfterDays': expireDateAfterDays
        };

        const response = await this._httpClient.postAsync(url, postData);
        return await response.json();
    }

    public async getShareLink(selectedFile: FileVison, allowEdit: boolean, expireDateAfterDays: number = 0, password: string = null): Promise<ShareResult> {
        // /fr-fr/drive/getsharelink/
        let url = `/drive/home/getsharelink`;

        let postData = {
            'allowEdit': allowEdit,
            'fileId': selectedFile.fileId,
            'expireDateAfterDays': expireDateAfterDays,
            'password': password
        };

        const response = await this._httpClient.postAsync(url, postData);
        return await response.json();
    }

    public async removeSharesAsync(shareIds: Array<number>): Promise<void> {
        // /fr-fr/drive/removeshares/
        let url = `/drive/home/removeshares`;

        let postData = {
            shareIds: shareIds
        };

        let response = await this._httpClient.postAsync(url, postData);

        if (response.status == 400) {
            let jsonResult = await response.json();
            throw new Error(jsonResult.error.message);
        }
    }

    public async getFileSharesOfGroupAsync(groupId: number, page: number, pageSize: number): Promise<IPaginationResult<IUserFileShare>> {
        let options = {
            page,
            pageSize
        };

        const response = await this._httpClient.getAsync(
            String.Format(Routes.Api.Groups.Shares, groupId),
            null,
            options
        );

        return await this._responseHandler
            .handleResponseAsync<IPaginationResult<IUserFileShare>>(response);
    }

    public async removeSharesOfGroupAsync(groupId: number, shareIds: Array<number>): Promise<void> {
        const response = await this._httpClient.deleteAsync(
            String.Format(Routes.Api.Groups.Shares, groupId),
            shareIds
        );

        if (response.status == 400) {
            let jsonResult = await response.json() as IQueryResultBase;
            throw new QueryException(jsonResult.error);
        }
    }

    public async createFile(directoryGuid: string, fileUploadedGuid: string, fileName: string): Promise<CreateFileResult> {
        // /fr-fr/drive/createfile/
        let url = `/drive/home/createfile`;

        let postData = {
            'currentDirectoryGuid': directoryGuid,
            'fileUploadedGuid': fileUploadedGuid,
            'fileName': fileName,
        };

        let response = await this._httpClient.postAsync(url, postData);
        let jsonResult = await response.json();

        let result: CreateFileResult = {
            file: null,
            error: null
        };

        if (response.ok) {
            result.file = jsonResult as FileVison;
        }
        else {
            result.error = (jsonResult as IQueryResultBase).error.message;
        }

        return result;
    }

    public async replaceFileAsync(fileToReplaceGuid: string, fileUploadedGuid: string): Promise<void> {
        let url = String.Format(Routes.Api.Drive.Items.Item, fileToReplaceGuid);

        let response = await this._httpClient.patchAsJsonAsync(
            [{ op: 'replace', path: 'physicalFile', value: fileUploadedGuid }],
            null,
            url
        );

        if (!response.ok) {
            let queryError = await response.json() as IQueryResultBase;
            throw new QueryException(queryError.error);
        }
    }

    public async addFileAsync(directoryGuid: string, chunckedFile: ChunkedFile): Promise<{ createFileResult: CreateFileResult, chunckedFile: ChunkedFile }> {
        if (chunckedFile.status === 'abort') {
            return null;
        }

        let createFileResult = await this.createFile(directoryGuid, chunckedFile.guid, chunckedFile.name);

        if (!createFileResult.file) {
            chunckedFile.errors.push(createFileResult.error);
            chunckedFile.hasError = true;
        }

        return { createFileResult, chunckedFile };
    }

    public async createDirectoryAsync(directoryName: string, parentDirectory: FileVison): Promise<FileVison> {
        let value = {
            currentDirectoryId: parentDirectory.fileId,
            directoryName: directoryName
        };

        let response = await this._httpClient.postAsync('/drive/home/create', value);
        let jsonResponse = await response.json();

        if (response.status != 201) {
            let queryError = jsonResponse as IQueryResultBase;
            throw new QueryException(queryError.error);
        }

        return jsonResponse as FileVison;
    }

    //NOK
    public searchFiles(parentDirectory: FileVison, inputSearchText: string, pageNumber?: number, resultPerPage?: number, token: string = null): Promise<GetFilesResult> {
        this.searchText = inputSearchText;

        return this.getFiles(parentDirectory, pageNumber, resultPerPage, token);
    }

    public async searchNextPageAsync(nextSearchPageLink: string): Promise<ISearchResult<FileVison>> {
        const response = await this._httpClient.getAsync(nextSearchPageLink, '/');

        return await this._searchResponseAsync(response);
    }

    public async searchFilesAsync(
        parentDirectory: FileVison,
        inputSearchText: string,
        filter: string,
        maxPageSize: number,
        token: string = null)
        : Promise<ISearchResult<FileVison>> {
        const queryData = new SearchFilesViewModel(
            parentDirectory.guid,
            token,
            inputSearchText,
            filter,
            maxPageSize
        );

        const response = await this._httpClient.getAsync('drive/home/searchfiles', null, queryData);

        return await this._searchResponseAsync(response);
    }

    private async _searchResponseAsync(response: Response): Promise<ISearchResult<FileVison>> {
        const paginationResult: IServerPaginationResult<FileVison> = await this._responseHandler
            .handleResponseAsync<IServerPaginationResult<FileVison>>(response);

        return {
            found: paginationResult.result,
            nextLink: paginationResult.nextLink,
            hasMoreResults: paginationResult.nextLink != null
        };
    }

    /**
     * Déplace les fichiers sélectionnés vers un emplacement donné.
     * @param {Array} sourceFiles - Liste des fichiers a déplacer (id)
     * @param {number} folderId - Dossier de destination (id)
     */
    public async moveToAsync(sourceFiles: number[], folderId: number): Promise<void> {
        // /fr-fr/drive/move/
        let url = `/drive/home/move`;

        let postData: MoveFileViewModel = {
            files: sourceFiles,
            destination: folderId
        };

        const response: Response = await this._httpClient.postAsync(url, postData);

        await this._responseHandler.handleErrorsAsync(response);
    }

    /**
     * Copie les fichiers sélectionnés vers un emplacement donné.
     * @param {Array} sourceFiles - Liste des fichiers a déplacer (id)
     * @param {number} folderId - Dossier de destination (id)
     */
    public async copyToAsync(sourceFiles: number[], folderId: number): Promise<void> {
        // /fr-fr/drive/copy/
        let url = `/drive/home/copy`;

        let postData: MoveFileViewModel = {
            files: sourceFiles,
            destination: folderId
        };

        const response: Response = await this._httpClient.postAsync(url, postData);

        if (response.status == 400) {
            throw new Exception('error while copying file');
        }
        else if (response.status == 403) {
            throw new Exception('you do not have permission to perform this copy');
        }
    }

    public async getUserFileSharesAsync(userId: number, platformId: number, subscriptionId: number): Promise<IUserFileShares> {
        const url = String.Format(Routes.Api.Users.shares, userId);

        let options = {
            platformId: platformId,
            subscriptionId: subscriptionId
        };

        let response = await this._httpClient.getAsync(url, null, options);
        let jsonResponse = await response.json();

        if (response.status == 400) {
            //TODO handle error and use type queryresult.
        }

        return jsonResponse as IUserFileShares;
    }

    public async deleteFileSharesOfUserAsync(userId: string, fileSharesIds: Array<number>): Promise<void> {
        const url = String.Format(Routes.Api.Users.shares, userId);
        const response = await this._httpClient.deleteAsync(url, fileSharesIds);

        if (response.status != 204) {
            const queryError = await response.json() as IQueryResultBase;
            throw new QueryException(queryError.error);
        }
    }

    public async getFilePermissionsOfUserAsync(userId: string, page: number, pageSize: number): Promise<IPaginationResult<IFilePermission>> {
        const url = String.Format(Routes.Api.Users.FilePermissions, userId);

        const response: Response = await this._httpClient.getAsync(url, null, {
            page,
            pageSize
        });

        return this._responseHandler
            .handleResponseAsync<IPaginationResult<IFilePermission>>(response);
    }

    public async deleteFilePermissionsOfUserAsync(userId: string, permissionsIds: Array<number>): Promise<void> {
        const url = String.Format(Routes.Api.Users.FilePermissions, userId);
        const response = await this._httpClient.deleteAsync(url, permissionsIds);

        if (response.status != 204) {
            const queryError = await response.json() as IQueryResultBase;
            throw new QueryException(queryError.error);
        }
    }

    //NOK
    public async getChildren(directory: FileVison): Promise<any> {
        // /fr-fr/drive/directories/{currentId}
        let url = `/drive/home/directories`;

        if (directory != null) {
            url += '/?currentId=' + directory.guid;
        }

        const response = await this._httpClient.getAsync(url);
        return await response.json();
    }

    public async getFiles(currentDirectory?: FileVison, pageNumber?: number, resultPerPage?: number, token: string = null, listFileOfType?: FileSelectionType): Promise<GetFilesResult> {
        // /fr-fr/drive/getfiles/
        let url = `/drive/home/getfiles`;

        if (token) {
            const queryString = new URLSearchParams({ token: token });
            url = `${url}?${queryString}`;
        }

        let currentDirectoryGuid: string = currentDirectory ? currentDirectory.guid : null;
        pageNumber = pageNumber || 1;
        resultPerPage = resultPerPage || 20;

        let postData = {
            'currentDirectoryGuid': currentDirectoryGuid,
            'orderBy': this.orderBy,
            'direction': this.direction,
            'searchText': null,
            'pageNumber': pageNumber,
            'resultPerPage': resultPerPage,
            'filter': this.filter,
            'token': token,
            'listFileOfType': listFileOfType ?? FileSelectionType.All
        };

        return await this._responseHandler.handleResponseAsync<GetFilesResult>(
            await this._httpClient.postAsync(url, postData)
        );
    }

    public async getFileInfo(selectedFile: FileVison, token?: string): Promise<GetFileInfoResult> {
        // /fr-fr/drive/getfileinfo/{id}
        const url: string = `/drive/home/getfileinfo/${selectedFile.guid}`;

        const response: Response = await this._httpClient
            .getAsync(url, null, { 'token': token });

        return await this._responseHandler
            .handleResponseAsync<GetFileInfoResult>(response);
    }

    public async getFilesInfo(selectedFile: Array<FileVison>): Promise<GetFileInfoResult> {
        // /fr-fr/drive/getfileinfo/{id}
        let url = `/drive/home/getfilesinfo`;

        const response = await this._httpClient.postAsync(url, selectedFile.map(fv => fv.guid));
        return await response.json();
    }

    public async getFileProcessState(selectedFile: FileVison): Promise<FileTaskResult> {
        // /fr-fr/drive/getfileinfo/{id}
        let url = `/drive/home/getfileprocessstate`;

        const response = await this._httpClient.getAsync(url, null, { ids: selectedFile.guid });
        const jsonResponse = await response.json();

        if (response.status != 200) {
            const queryError = jsonResponse as IQueryResultBase;
            throw new QueryException(queryError.error);
        }

        const [state] = jsonResponse as Array<FileTaskResult>;
        return state || null;
    }

    public async getFilesProcessState(selectedFiles: Array<FileVison>): Promise<Array<FileTaskResult>> {
        let url = `/drive/home/getfileprocessstate`;

        const response = await this._httpClient.postAsync(url, selectedFiles.map(f => f.guid));
        const jsonResponse = await response.json();

        if (response.status != 200) {
            const queryError = jsonResponse as IQueryResultBase;
            throw new QueryException(queryError.error);
        }

        return jsonResponse as Array<FileTaskResult>;
    }

    public async renameFileAsync(newName: string, selectedFile: FileVison): Promise<FileVison> {
        // /fr-fr/drive/rename/
        let url = `/drive/home/rename`;

        let postData = {
            'fileId': selectedFile.fileId,
            'newName': newName
        };

        const response = await this._httpClient.postAsync(url, postData);
        const jsonResponse = await response.json();

        if (response.status != 200) {
            const queryError = jsonResponse as IQueryResultBase;
            throw new QueryException(queryError.error);
        }

        return jsonResponse as FileVison;
    }

    public async softDeleteFilesAsync(fileIds: Array<number>): Promise<void> {
        const response = await this._httpClient.postAsync(`/drive/home/delete`, fileIds);

        if (response.status != 204) {
            let result: IQueryResultBase = await response.json();

            throw new QueryException(result.error);
        }
    }

    public downloadSelectedFiles(selectedFiles: FileVison[], token: string = null): void {
        let form = document.createElement('form');

        form.action = this._uriService.getAbsoluteUri(
            `/${this.codeCulture}/asset/downloadfiles/`,
            {
                token: token,
                ids: selectedFiles.length == 1 ? selectedFiles[0].guid : null
            }
        );

        form.method = 'POST';
        form.setAttribute('download', null);
        form.target = '_blank';

        if (selectedFiles.length > 1) {
            for (let file of selectedFiles) {
                let input = document.createElement('input');
                input.type = 'hidden';
                input.name = 'ids';
                input.value = file.guid;

                form.appendChild(input);
            }
        }

        let body = document.querySelector('body');
        body.appendChild(form);
        form.submit();
        body.removeChild(form);
    }

    public async uploadThumbnailAsync(thumbnail: File, folderId: number): Promise<any> {
        let url = `/drive/home/editthumbnail`;

        let data = {
            thumbnail: thumbnail,
            folderId: folderId
        };

        const response = await this._httpClient.postFormDataAsync(url, data);
        return await response.json();
    }

    public async deleteThumbnail(parentId: number) {
        let url = `/drive/home/deletethumbnail`;

        const response = await this._httpClient.postAsync(url, { FolderId: parentId });
        return await response.json();
    }

    public async searchTagsAsync(search: string): Promise<Array<Tag>> {
        //TODO refactor with import tagservice
        if (!search) {
            throw new Error(`ArgumentNullException: ${search}`);
        }

        const response = await this._httpClient.postAsJsonAsync(search, null, 'api/tags/search');
        let queryResult = await response.json();

        if (queryResult.error) {
            throw new Error((queryResult as IQueryResultBase).error.message);
        }

        return queryResult as Array<Tag>;
    }

    public async saveTagsAsync(item: FileVison, tags: Array<Tag>): Promise<void> {
        let tagNames = tags.map(t => t.name);

        const response = await this._httpClient.putAsJsonAsync(tagNames, null, `api/drive/items/${item.fileId}/tags`);

        if (response.status != 201) {
            let queryResult: IQueryResultBase = await response.json();

            throw new Error(queryResult.error.message);
        }
    }

    public async getImportFolderAsync(userId: number): Promise<FileVison> {
        const url = String.Format(Routes.Api.Users.Drive.Search.Import, userId);

        const response = await this._httpClient.getAsync(url);
        const queryResult = await response.json();

        if (response.status !== 200) {
            throw new QueryException((queryResult as IQueryResultBase).error);
        }

        return queryResult as FileVison;
    }

    public async createSymlinkAsync(symlinkInput: ISymlinkInputDto): Promise<FileVison> {
        return await this._responseHandler.handleResponseAsync<FileVison>(
            await this._httpClient.postAsync(Routes.Api.Drive.Symlinks, symlinkInput));
    }

    public async getPermissionAsync(fileGuid: string, token: string = null): Promise<DrivePermissions> {
        return await this._responseHandler.handleResponseAsync<DrivePermissions>(
            await this._httpClient.getAsync(
                String.Format(Routes.Api.Drive.Items.Permission, fileGuid),
                null,
                { token: token }));
    }

    protected _httpClient: HttpClient;
    protected _uriService: IVisonUriService;
    private readonly _responseHandler: IResponseHandler;

    private searchText: string;
    private orderBy: OrderBy;
    private direction: SortDirection;
    private filter: Filter;
    private codeCulture: string;

    get CodeCulture(): string {
        return this.codeCulture;
    }
    set CodeCulture(value: string) {
        this.codeCulture = value;
    }

    get SearchText(): string {
        return this.searchText;
    }
    set SearchText(value: string) {
        this.searchText = value;
    }

    get OrderBy(): OrderBy {
        return this.orderBy;
    }
    set OrderBy(value: OrderBy) {
        this.orderBy = value;
    }

    get Direction(): SortDirection {
        return this.direction;
    }
    set Direction(value: SortDirection) {
        this.direction = value;
    }

    get Filter(): Filter {
        return this.filter;
    }
    set Filter(value: Filter) {
        this.filter = value;
    }
}

