import { AppContext } from 'Api/AppContext';
import { AssetFile, AssetItemType } from 'Api/Contracts/Dtos';
import { getPreviewUrl } from 'App/Helpers/AssetHelper';
import { injectTypes } from 'App/injectTypes';
import { lazyInject } from 'App/Inversify.config';
import { IVisonUriService } from 'App/Services/UriServices/Core';
import { Component, Prop, UIElement } from 'Framework/Components/UIElement';

@Component({
    name: 'x-file-preview'
})
export default class FilePreview extends UIElement {
    @Prop({ type: Object, required: false, default: null })
    public assetFile: AssetFile;

    @Prop()
    public isSelected: boolean;


    public get HasThumbnail(): boolean {
        switch (this.assetFile.file.extension) {
            case '.pdf':
            case '.mp4':
            case '.avi':
            case '.flv':
            case '.wmv':
            case '.mov':
            case '.mpg':
            case '.m1v':
            case '.mpeg':
            case '.jpg':
            case '.jpeg':
            case '.png':
            case '.bmp':
            case '.tif':
            case '.tiff':
            case '.e57':
                return true;
            default:
                return false;
        }
    }

    public get ThumbnailUrl(): string {
        if (this.HasThumbnail) {
            return getPreviewUrl(this._appContext.codeCulture, this.assetFile.file.guid);
        }
        return '';
    }

    public get FileIcon(): string {
        if (this.assetFile.type == AssetItemType.Directory) {
            return 'fa-light fa-folder';
        }

        switch (this.assetFile.file.extension) {
            case '.xlsx':
                return 'fa-light fa-file-excel';
            case '.pptx':
                return 'fa-light fa-file-powerpoint';
            case '.docx':
                return 'fa-light fa-file-word';
            case '.pdf':
                return 'fa-light fa-file-pdf';
            case '.mp4':
            case '.avi':
            case '.flv':
            case '.wmv':
            case '.mov':
            case '.mpg':
            case '.m1v':
            case '.mpeg':
                return 'fa-light fa-file-video';
            case '.jpg':
            case '.jpeg':
            case '.png':
            case '.bmp':
            case '.tif':
            case '.tiff':
                return 'fa-light fa-file-image';
            default:
                return 'fa-light fa-file';
        }
    }

    public get fileName(): string {
        const nameWithoutExtension = this.assetFile.name || this.assetFile.file.nameWithoutExtension;
        const extension = this.assetFile.file.extension ?? '';

        return `${nameWithoutExtension}${extension}`;
    }

    @lazyInject(AppContext)
    private readonly _appContext: AppContext;

    @lazyInject(injectTypes.IVisonUriService)
    public uriService: IVisonUriService;
}
