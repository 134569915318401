export interface IFileHeader {
    extensions: Array<string>;
    offset: number;
    signatures: Array<Array<number>>;
}

declare global {
    interface File {
        getFileExtension(): string;
        readFileAsync(startingByte: number, endindByte?: number): Promise<ArrayBuffer>;
        isFileHeaderValidAsync(headerOffset: number, allowedTypesHeaders: Array<Array<number>>): Promise<boolean>
        getFormatSize(): string;
    }
}

File.prototype.getFileExtension = function (): string {
    return FileHelper.getFileExtension(this.name);
}

File.prototype.readFileAsync = function (startingByte: number = 0, endindByte?: number): Promise<ArrayBuffer> {
    return FileHelper.readFileAsync(this, startingByte, endindByte);
}

File.prototype.isFileHeaderValidAsync = function (headerOffset: number, allowedTypeSignature: Array<Array<number>>): Promise<boolean> {
    return FileHelper.isFileHeaderValidAsync(this, headerOffset, allowedTypeSignature);
}

File.prototype.getFormatSize = function () {
    return FileHelper.formatSize(this.size);
}

export class FileHelper {
    public static async readFileAsync(file: File, startingByte: number = 0, endindByte?: number): Promise<ArrayBuffer> {
        return new Promise<ArrayBuffer>((resolve, reject) => {
            let reader = new FileReader();

            reader.onload = () => {
                resolve(reader.result as ArrayBuffer);
            };

            reader.onerror = reject;
            reader.readAsArrayBuffer(file.slice(startingByte, endindByte));
        });
    }

    public static async isFileHeaderValidAsync(file: File, headerOffset: number, allowedTypesHeaders: Array<Array<number>>): Promise<boolean> {
        let result = await FileHelper.readFileAsync(file, headerOffset, 16);

        let fileIndex = 0;
        let headerCopy = [...allowedTypesHeaders];

        while (true) {
            if (headerCopy.length <= 0) {
                return false;
            }

            let byteToCompare = new Uint8Array(result.slice(fileIndex, fileIndex + 1))[0];

            for (let i = headerCopy.length - 1; i >= 0; i--) {
                if (headerCopy[i][fileIndex] !== byteToCompare) {
                    headerCopy.splice(i, 1);
                }
            }

            fileIndex++;

            if (headerCopy.length == 1 && headerCopy[0].length == fileIndex) {
                return true;
            }
        }
    }

    public static getFileExtension(fileName: string): string {
        return fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 1) || null;
    }

    public static formatSize(bytesFloat: number): string {
        if (bytesFloat < 0) {
            return '-- Octets';
        }
        else if (bytesFloat > FileHelper.size_Eo) {
            return (bytesFloat / FileHelper.size_Eo).toFixed(2) + ' Eo';
        }
        else if (bytesFloat > FileHelper.size_Po) {
            return (bytesFloat / FileHelper.size_Po).toFixed(2) + ' Po';
        }
        else if (bytesFloat > FileHelper.size_To) {
            return (bytesFloat / FileHelper.size_To).toFixed(2) + ' To';
        }
        else if (bytesFloat > FileHelper.size_Go) {
            return (bytesFloat / FileHelper.size_Go).toFixed(2) + ' Go';
        }
        else if (bytesFloat > FileHelper.size_Mo) {
            return (bytesFloat / FileHelper.size_Mo).toFixed(2) + ' Mo';
        }
        else if (bytesFloat > FileHelper.size_Ko) {
            return (bytesFloat / FileHelper.size_Ko).toFixed(2) + ' Ko';
        }
        else {
            return `${bytesFloat} Octets`;
        }
    }

    public static getTypesSignaturesFromExtensions(extensions: Array<string>): Array<Array<Array<number>>> {
        let areExtensionsNotValid: boolean = extensions.some(extension =>
            !extension.startsWith('.')
        );

        if (areExtensionsNotValid) {
            throw new Error('Extension should be start with dot (eg: .jpg)');
        }

        return FileHelper.fileHeaders.filter(
            fileHeader => extensions.some(extension => fileHeader.extensions.includes(extension))
        ).map(fileHeader =>
            fileHeader.signatures
        );
    }

    public static async isFileTypeSupportedAsync(file: File, supportedExtensions: Array<string>, headerOffset: number): Promise<boolean> {
        if (!file) {
            throw new Error('NullReferenceException: file');
        }

        let allowedTypesSignatures: Array<Array<Array<number>>> = FileHelper.getTypesSignaturesFromExtensions(supportedExtensions);

        if (allowedTypesSignatures.length == 0) {
            return false;
        }

        let signatureVerifications: Array<Promise<boolean>> = allowedTypesSignatures.map(signature => file.isFileHeaderValidAsync(headerOffset, signature));

        let results: Array<boolean> = await Promise.all(signatureVerifications);

        return results.some(result => result == true);
    }

    public static isValidSize(file: File, maxSize: number): boolean {
        if (!file) {
            throw new Error('NullReferenceException: file');
        }

        return file.size <= maxSize;
    }

    //https://www.garykessler.net/library/file_sigs.html
    public static fileHeaders: Array<IFileHeader> = [
        {
            extensions: ['.jpg', '.jpeg'],
            offset: 0,
            signatures: [
                [0xFF, 0xD8]
            ]
        },
        {
            extensions: ['.png'],
            offset: 0,
            signatures: [
                [0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0A, 0x1A, 0x0A]
            ]
        },
        {
            extensions: ['.bmp'],
            offset: 0,
            signatures: [
                [0x42, 0x4D]
            ]
        },
        {
            extensions: ['.tiff', '.tif'],
            offset: 0,
            signatures: [
                [0x49, 0x49, 0x2A, 0x00],
                [0x4D, 0x4D, 0x00, 0x2A],
                [0x4D, 0x4D, 0x00, 0x2B]
            ]
        },
        {
            extensions: ['.e57'],
            offset: 0,
            signatures: [
                [0x41, 0x53, 0x54, 0x4d, 0x2d, 0x45, 0x35, 0x37]
            ]
        },
        {
            extensions: ['.mp4', '.m4a', '.m4p', '.m4b', '.m4r', '.m4v', '.mov'],
            offset: 4,
            signatures: [
                [0x66, 0x74, 0x79, 0x70, 0x4D, 0x53, 0x4E, 0x56],
                [0x66, 0x74, 0x79, 0x70, 0x69, 0x73, 0x6F, 0x6D],
                [0x66, 0x74, 0x79, 0x70, 0x6D, 0x70, 0x34, 0x32],
                [0x66, 0x74, 0x79, 0x70, 0x71, 0x74, 0x20, 0x20]
            ]
        },
        {
            extensions: ['.pdf'],
            offset: 0,
            signatures: [
                [0x25, 0x50, 0x44, 0x46]
            ]
        }
    ]

    public static readonly size_Eo: number = 1152921504606846976.0;
    public static readonly size_Po: number = 1125899906842624.0;
    public static readonly size_To: number = 1099511627776.0;
    public static readonly size_Go: number = 1073741824.0;
    public static readonly size_Mo: number = 1048576.0;
    public static readonly size_Ko: number = 1024.0;
}
