import { Localizer } from 'dock-spawn-ts/lib/js/i18n/Localizer';
import { PluginObject, VueConstructor } from 'vue';

export interface DockSpawnPluginOptions {
    localizer?: Parameters<typeof Localizer.configure>[0];
}

export const dockSpawnPlugin: PluginObject<DockSpawnPluginOptions> = {
    install: function (_Vue: VueConstructor<Vue>, options?: DockSpawnPluginOptions): void {
        if (options?.localizer) {
            Localizer.configure(options.localizer);
        }
    }
};

