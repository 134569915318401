import { Tag, TagCounter } from 'Api/Contracts/Dtos';
import { IQueryResultBase, QueryException } from 'Api/Dto/QueryResult';
import { HttpClient } from 'Api/HttpClient';
import { Routes } from 'Api/Routes';
import { inject, injectable } from 'inversify';

@injectable()
export class TagService {
    public constructor(@inject(HttpClient) httpClient: HttpClient) {
        this._httpClient = httpClient;
    }

    public async listTagsAsync(): Promise<Array<TagCounter>> {
        const response = await this._httpClient.getAsync(Routes.Api.Tags.List);
        const jsonResponse = await response.json();

        if(response.status == 400) {
            let queryError = jsonResponse as IQueryResultBase;

            throw new QueryException(queryError.error);
        }

        return jsonResponse as Array<TagCounter>;
    }

    public async searchTagsAsync(search: string): Promise<Array<Tag>> {
        if (search == null || search == undefined) {
            throw new Error(`ArgumentNullException: ${search}`);
        }

        const response = await this._httpClient.postAsync(Routes.Api.Tags.Search, search);
        let jsonResponse = await response.json();

        if (response.status == 400) {
            let queryError = jsonResponse as IQueryResultBase;

            throw new QueryException(queryError.error);
        }

        return jsonResponse as Array<Tag>;
    }

    public async getTagsAsync(tagIds: Array<number>): Promise<Array<Tag>> {
        const response = await this._httpClient.getAsync(Routes.Api.Tags.Base, null, {
            tagIds: tagIds
        });

        let jsonResponse = await response.json();

        if (response.status == 400) {
            let queryError = jsonResponse as IQueryResultBase;

            throw new QueryException(queryError.error);
        }

        return jsonResponse as Array<Tag>;
    }

    public async createTagsAsync(tagNames: Array<string>): Promise<Array<Tag>> {
        const response = await this._httpClient.postAsync(Routes.Api.Tags.Base, tagNames);
        const jsonResponse = await response.json();

        if(response.status != 201) {
            let queryError = jsonResponse as IQueryResultBase;

            throw new QueryException(queryError.error);
        }

        return jsonResponse as Array<Tag>;
    }

    public async updateTagAsync(tag: Tag): Promise<void> {
        const response = await this._httpClient.putAsync(Routes.Api.Tags.Base, tag);

        if(response.status != 204) {
            const jsonResponse = await response.json();
            let queryError = jsonResponse as IQueryResultBase;

            throw new QueryException(queryError.error);
        }
    }

    public async deleteTagsAsync(tagIds: Array<number>): Promise<void> {
        const response = await this._httpClient.deleteAsync(Routes.Api.Tags.Base, tagIds);

        if(response.status != 204) {
            const jsonResponse = await response.json();
            let queryError = jsonResponse as IQueryResultBase;

            throw new QueryException(queryError.error);
        }
    }

    protected readonly _httpClient: HttpClient;
}
