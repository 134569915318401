import { ApiException } from 'Api/Dto/QueryResult';
import { AuthenticationService } from 'Api/Services';
import { IPasswordDialogContext } from 'App/Areas/Shares/Dialogs/IPasswordDialogContext';
import VoPasswordDialog from 'App/Areas/Shares/Dialogs/VoPasswordDialog.vue';
import { DialogResult, IDialogService } from 'Framework/Services/IDialogService';

export type TokenValidatorFactory = (token: string) => TokenValidator;

export class TokenValidator {
    public constructor(dialogService: IDialogService, authenticationService: AuthenticationService, token: string) {
        this._dialogService = dialogService;
        this._authenticationService = authenticationService;
        this._rawToken = token;
    }

    public async executeAsync<T>(operation: (token: string) => Promise<T>): Promise<T> {
        try {
            return await operation(this.token);
        }
        catch (ex) {
            if (!(ex instanceof ApiException)) {
                throw ex;
            }

            if (ex.error.code == TokenValidator.TokenExpiredErrorCode) {
                this._validatedToken = await this._authenticationService
                    .authenticateForShareAsync(this._rawToken, this._password);
            }
            else if (ex.error.code == TokenValidator.PasswordNeededErrorCode) {
                this._passwordDialogPromise ??= this._askPasswordAsync();

                await this._passwordDialogPromise;
                this._passwordDialogPromise = null;
            }

            return await operation(this.token);
        }
    }

    private async _askPasswordAsync(): Promise<void> {
        const dialogContext: IPasswordDialogContext = {
            token: this._rawToken,
            authenticateForShareAsync: (token, password) => this._authenticationService.authenticateForShareAsync(token, password)
        };

        const dialogResult = await this._dialogService.openAsync(
            "Shares",
            VoPasswordDialog,
            dialogContext,
            { persistent: true }
        );

        if (dialogResult == DialogResult.OK) {
            this._password = dialogContext.password;
            this._validatedToken = dialogContext.validatedToken;
        }
    }

    public get token(): string {
        return this._validatedToken ?? this._rawToken;
    }

    private _validatedToken: string = null;
    private _password: string = null;
    private _passwordDialogPromise: Promise<void> = null;

    private readonly _dialogService: IDialogService;
    private readonly _authenticationService: AuthenticationService;
    private readonly _rawToken: string;

    private static readonly PasswordNeededErrorCode = "E_0x00000009";
    private static readonly TokenExpiredErrorCode = "E_0x00000008";
}
