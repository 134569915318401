import { ExperimentalFeature } from 'Api/AppContext';
import { camelCase, cloneDeep, throttle, upperFirst } from 'lodash';
import 'reflect-metadata';
import Vue, { PropOptions } from 'vue';
import { createDecorator } from 'vue-class-component';
import { ComponentOptions, ComputedOptions, Constructor } from 'vue/types/options';

enum VerticalAlignment {
    Top = 0,
    Center = 1,
    Bottom = 2,
    Stretch = 3
};

enum HorizontalAlignment {
    Left = 0,
    Center = 1,
    Right = 2,
    Stretch = 3
};

enum Orientation {
    Horizontal = 0,
    Vertical = 1
};

enum Visibility {
    Visible = 0,
    Hidden = 1,
    Collapsed = 2
};

export {
    HorizontalAlignment,
    Orientation,
    VerticalAlignment,
    Visibility
};

export const BrowsableMetadataKey: string = 'editor:browsable';

export function getMetadataOverBaseClass(targetConstructor: any, metadataKey: string) {
    let metadata: any = Reflect.getMetadata(metadataKey, targetConstructor);

    if(!metadata) {
        metadata = {};

        let mixins: Array<(ComponentOptions<Vue> | typeof Vue)> = /*targetConstructor.__proto__?.extendOptions?.mixins ||*/ targetConstructor?.extendOptions?.mixins || [];

        mixins?.forEach(mx => metadata = {...metadata, ...getMetadataOverBaseClass(mx, metadataKey)});//mx.constructor ?

        if(targetConstructor.super) {
            metadata = {...metadata, ...getMetadataOverBaseClass(targetConstructor.super, metadataKey)}
        }
    }

    return metadata;
};
export function getMetadataOverBaseClassWithKeyBuilder(targetConstructor: any, metadataKeyBuilder: (targetConstructor: any) => string) {
    let metadataKey = metadataKeyBuilder(targetConstructor);
    let metadata: any = Reflect.getMetadata(metadataKey, targetConstructor);

    if (!metadata) {
        metadata = {};

        let mixins: Array<(ComponentOptions<Vue> | typeof Vue)> = /*targetConstructor.__proto__?.extendOptions?.mixins ||*/ targetConstructor?.extendOptions?.mixins || [];

        mixins?.forEach(mx => metadata = { ...metadata, ...getMetadataOverBaseClassWithKeyBuilder(mx, metadataKeyBuilder) });//mx.constructor ?

        if (targetConstructor.super) {
            metadata = { ...metadata, ...getMetadataOverBaseClassWithKeyBuilder(targetConstructor.super, metadataKeyBuilder) }
        }
    }

    return metadata;
};

export type BorderStyle = 'none' | 'hidden' | 'dotted' | 'dashed' | 'solid' | 'double' | 'groove' | 'ridge' | 'inset' | 'outset';

export const reflectMetadataIsSupported =
    typeof Reflect !== 'undefined' && typeof Reflect.getMetadata !== 'undefined'

export function applyMetadata(
    options: PropOptions | Constructor[] | Constructor,
    target: Vue,
    key: string,
) {
    if (reflectMetadataIsSupported) {
        if (
            !Array.isArray(options) &&
            typeof options !== 'function' &&
            typeof options.type === 'undefined'
        ) {
            const type = Reflect.getMetadata('design:type', target, key)
            if (type !== Object) {
                options.type = type
            }
        }
    }
}
/**
 * decorator of a synced prop
 * @param propName the name to interface with from outside, must be different from decorated property
 * @param options the options for the synced prop
 * @return PropertyDecorator | void
 */
export function PropSyncWithInternal(
    propName: string,
    options: PropOptions | Constructor[] | Constructor = {},
): PropertyDecorator {
    if(!options) {
        options = {};
    }

    // @ts-ignore
    return (target: Vue, key: string) => {
        applyMetadata(options, target, key);

        createDecorator((componentOptions: ComponentOptions<Vue>, k: string, index: number): void => {
            (componentOptions.props || (componentOptions.props = {} as any))[propName] = options;

            (componentOptions.computed || (componentOptions.computed = {}))[k] = {
                get() {
                    let result = this[propName];

                    if (result == void (0)) {
                        result = this['internals'][propName];
                    }
                    if (result == void (0)) {
                        result = null;
                    }

                    return result;
                },
                set: throttle(
                    function (this: ComputedOptions<any> | (() => any), value: any) {
                        this['internals'][propName] = value;

                        // @ts-ignore
                        this.$emit(`update:${k}`, value);
                        // @ts-ignore
                        this.$emit(`update:${propName}`, value);
                        // @ts-ignore
                        this.$emit('property-changed', this, propName, value);
                    },
                    300,
                    {
                        leading: true,
                        trailing: true
                    }
                )
            };

            (componentOptions.mixins || (componentOptions.mixins = [])).push({
                data(this: Vue) {
                    return {
                        internals: {
                            [propName]: this[propName]
                        }
                    }
                }
            });
        })(target, key);
    }
}

export enum EditorBrowsableState {
    Always,
    Never
}

export type EditorTemplateType =
    | 'String'
    | 'Boolean'
    | 'Number'
    | 'Color'
    | 'Visibility'
    | 'BorderStyle'
    | 'HorizontalAlignment'
    | 'VerticalAlignment';

export interface IEditorOptions {
    /** Group properties when they are same group name */
    groupName: string,
    /** Determine if property can be browsable */
    editorBrowsableState?: EditorBrowsableState,
    /** Set order to ordering more than one properties */
    order?: number,
    /** Template used to render field */
    template?: EditorTemplateType
}

/**
 * Specifies that a property or method is viewable in an editor
 */
export function editorBrowsable(editorOptions?: IEditorOptions): PropertyDecorator {
    let decoratorOptions: IEditorOptions = {
        editorBrowsableState: EditorBrowsableState.Always,
        groupName: 'groupName'
    }

    decoratorOptions = {...decoratorOptions, ...editorOptions };

    return (target: Vue, propertyName: string) => {
        if(decoratorOptions.editorBrowsableState == EditorBrowsableState.Never) {
            return;
        }

        createDecorator((options: ComponentOptions<Vue>, propertyKey: string) => {
            let targetConstructor = target.constructor;

            let metadata = cloneDeep(getMetadataOverBaseClass(targetConstructor, BrowsableMetadataKey));
            metadata[decoratorOptions.groupName] = [...(metadata[decoratorOptions.groupName] || [])];
            metadata[decoratorOptions.groupName].push({
                propertyName: propertyKey,
                order: decoratorOptions.order,
                template: decoratorOptions.template
            });

            Reflect.defineMetadata(BrowsableMetadataKey, metadata, targetConstructor);
        })(
            target,
            propertyName.substring(propertyName.startsWith('_') ? 1 : 0)
        );
    }
}

const apiMap = 'api:map:';
const apiMapSerializable = `${apiMap}serializable`;
const designType = 'design:type';
const designParamTypes = 'design:paramtypes';

// Enums
enum Type {
    Array = 'array',
    Boolean = 'boolean',
    Date = 'date',
    Number = 'number',
    String = 'string'
}

// Types
type Args =
    | string
    | {
          name?: string;
          className?: string;
          type?: Function;
          onSerialize?: Function;
          onDeserialize?: Function;
          postDeserialize?: Function;
      }
    | {
          name?: string;
          className?: string;
          predicate?: Function;
          onSerialize?: Function;
          onDeserialize?: Function;
          postDeserialize?: Function;
      }
    | {
          names: Array<string>;
          className?: string;
          type?: Function;
          onSerialize?: Function;
          onDeserialize?: Function;
          postDeserialize?: Function;
      }
    | {
          names: Array<string>;
          className?: string;
          predicate?: Function;
          onSerialize?: Function;
          onDeserialize?: Function;
          postDeserialize?: Function;
      };

type Metadata =
    | {
          name: string;
          type: Function;
          onSerialize: Function;
          onDeserialize: Function;
          postDeserialize: Function;
      }
    | {
          name: string;
          predicate: Function;
          onSerialize: Function;
          onDeserialize: Function;
          postDeserialize: Function;
      }
    | {
          names: Array<string>;
          type: Function;
          onSerialize: Function;
          onDeserialize: Function;
          postDeserialize: Function;
      }
    | {
          names: Array<string>;
          predicate: Function;
          onSerialize: Function;
          onDeserialize: Function;
          postDeserialize: Function;
      };

/**
 * Function to get all base class names recursively
 *
 * @param {Object} target The target class from which the parent classes are extracted
 * @returns {Array<string>} All the base class names
 */
function getBaseClassNames(target: any): Array<string> {
    const names: Array<string> = [];
    const baseClass = target?.super;
    const className = getComponentName(baseClass);

    if (!className) {
        return names;
    }

    names.push(className);
    let mixins: (ComponentOptions<Vue> | typeof Vue)[] = baseClass.extendOptions?.mixins ?? [];

        for (const mixin of mixins) {
            const mixinName = getComponentName(mixin);

            if (mixinName) {
                names.push(mixinName);
            }
        }

    return [...names, ...getBaseClassNames(baseClass)];
}

/**
 * Decorator to take the property in account during the serialize and deserialize function
 * @param {Args} args Arguments to describe the property
 */

export function JsonProperty(args?: Args): PropertyDecorator {
    return (target: Vue, propertyName: string) => {
        createDecorator((options: ComponentOptions<Vue>, propertyKey: string) => {
            let targetConstructor = target.constructor;

            const targetName = args?.['className'] ?? getComponentName(targetConstructor);
            const apiMapTargetName = `${apiMap}${targetName}`;

            let map: { [id: string]: Metadata } = getMetadataOverBaseClassWithKeyBuilder(targetConstructor, (tc) => tc == targetConstructor ? apiMapTargetName :`${apiMap}${getComponentName(tc)}`);

            map[propertyKey] = getJsonPropertyValue(propertyKey, args);
            Reflect.defineMetadata(apiMapTargetName, map, targetConstructor);
        })(target, propertyName);
    };
}

/**
 * Decorator to make a class Serializable
 *
 * BREAKING CHANGE: Since version 2.0.0 the parameter `baseClassName` is not needed anymore
 */
export function Serializable(args?: {name:string}): ClassDecorator {
    return (target: any) => {
        const baseClassNames = getBaseClassNames(target);
        Reflect.defineMetadata(apiMapSerializable, baseClassNames, target);
    };
}

/**
 * Function to retrieve and merge all base class properties
 *
 * @param baseClassNames The base classe names of the instance provided
 * @param {any} instance The instance target from which the parents metadata are extracted
 * @returns {{ [id: string]: Metadata }} All base class metadata properties
 */
function getBaseClassMaps(
    baseClassNames: Array<string>,
    instance: any
): { [id: string]: Metadata } {
    let baseClassMaps: { [id: string]: Metadata } = {};

    baseClassNames.forEach(baseClassName => {
        baseClassMaps = {
            ...baseClassMaps,
            ...getMetadataOverBaseClass(instance.constructor, `${apiMap}${baseClassName}`) //Reflect.getMetadata(`${apiMap}${baseClassName}`, instance.constructor)
        };
    });

    return baseClassMaps;
}

/**
 * Function to deserialize json into a class
 *
 * @param {object} json The json to deserialize
 * @param {new (...params: Array<any>) => T} type The class in which we want to deserialize
 * @returns {T} The instance of the specified type containing all deserialized properties
 */
export function deserialize<T>(json: object, type: new (...params: Array<any>) => T): T {
    const instance: any = new type();
    const instanceName = getComponentName(instance.constructor);
    const baseClassNames: Array<string> = Reflect.getMetadata(apiMapSerializable, type);
    const apiMapInstanceName = `${apiMap}${instanceName}`;
    const hasMap = Reflect.hasMetadata(apiMapInstanceName, instance);

    if (!hasMap && !baseClassNames?.length) {
        return instance;
    }

    let instanceMap: { [id: string]: Metadata } = Reflect.getMetadata(apiMapInstanceName, instance) ?? {};
    instanceMap = { ...instanceMap, ...getBaseClassMaps(baseClassNames, instance) };

    Object.keys(instanceMap).forEach(key => {
        const canConvert = instanceMap[key]['names']
            ? instanceMap[key]['names'].some((name: string) => json[name] !== undefined)
            : json[instanceMap[key]['name']] !== undefined;

        if (canConvert) {
            instance[key] = convertDataToProperty(instance, key, instanceMap[key], json);
        }
    });

    return instance;
}

export function getComponentName(comp: any): string {
    if (!comp) {
        return null;
    }

    if (comp instanceof Vue) {
        return 'vue';
    }

    return comp.extendOptions?.name || comp.name || null;
}

/**
 * Function to serialize a class into json
 *
 * @param {any} instance Instance of the object to deserialize
 * @param {boolean} removeUndefined Indicates if you want to keep or remove undefined values
 * @returns {any} The json object
 */
export function serializeAsJson(instance: any, removeUndefined: boolean = true): any {
    const json: any = {
        type: upperFirst(camelCase(instance.$options.name))
    };

    const constructor = instance.constructor;
    const instanceName = getComponentName(constructor);
    const baseClassNames: Array<string> = Reflect.getMetadata(apiMapSerializable, constructor);
    const apiMapInstanceName = `${apiMap}${instanceName}`;
    const hasMap = Reflect.hasMetadata(apiMapInstanceName, constructor);

    if (!hasMap && !baseClassNames?.length) {
        return json;
    }

    let instanceMap: { [id: string]: Metadata } = Reflect.getMetadata(apiMapInstanceName, constructor) ?? {};
    instanceMap = { ...instanceMap, ...getBaseClassMaps(baseClassNames, instance) };

    Object.keys(instanceMap).forEach(key => {
        const onSerialize: Function = instanceMap[key]['onSerialize'];

        if (key in instance) {
            let data = convertPropertyToData(instance, key, instanceMap[key], removeUndefined);

            if (onSerialize) {
                data = onSerialize(data, instance);
            }

            if (instanceMap[key]['names']) {
                instanceMap[key]['names'].forEach((name: string) => {
                    if (!removeUndefined || (removeUndefined && data[name] !== undefined)) {
                        json[name] = data[name];
                    }
                });
            }
            else if (!removeUndefined || (removeUndefined && data !== undefined)) {
                json[instanceMap[key]['name']] = data;
            }
        }
    });

    return json;
}

/**
 * Function to convert class property to json data
 *
 * @param {Function} instance The instance containing the property to convert
 * @param {string} key The name of the property to convert
 * @param {Metadata} metadata The metadata of the property to convert
 * @param {boolean} removeUndefined Indicates if you want to keep or remove undefined value
 * @returns {any} The converted property
 */
function convertPropertyToData(
    instance: Function,
    key: string,
    metadata: Metadata,
    removeUndefined: boolean
): any {
    const property: any = instance[key];
    const type: any = Reflect.getMetadata(designType, instance, key);
    const isArray = Array.isArray(property);
    const predicate: Function = metadata['predicate'];
    const propertyType: any = metadata['type'] || type;
    const isSerializableProperty = isSerializable(propertyType);

    if (property && (isSerializableProperty || predicate)) {
        if (isArray) {
            const array = [];
            property.forEach((d: any) => {
                array.push(serializeAsJson(d, removeUndefined));
            });

            return array;
        }

        return serializeAsJson(property, removeUndefined);
    }

    if (propertyType.name.toLocaleLowerCase() === Type.Date) {
        return property ? property.toISOString() : property;
    }

    return property;
}

/**
 * Function to convert json data to the class property
 * @param {Function} instance The instance containing the property to convert
 * @param {string} key The name of the property to convert
 * @param {Metadata} metadata The metadata of the property to convert
 * @param {any} json Json containing the values
 */
function convertDataToProperty(instance: Function, key: string, metadata: Metadata, json: any) {
    let data: any;

    if (metadata['names']) {
        const object = {};
        metadata['names'].forEach((name: string) => (object[name] = json[name]));
        data = object;
    } else {
        data = json[metadata['name']];
    }

    const type: any = Reflect.getMetadata(designType, instance, key);
    const isArray = type.name.toLowerCase() === Type.Array;
    const predicate: Function = metadata['predicate'];
    const onDeserialize: Function = metadata['onDeserialize'];
    const postDeserialize: Function = metadata['postDeserialize'];
    let propertyType: any = metadata['type'] || type;
    const isSerializableProperty = isSerializable(propertyType);
    let result: any;

    if (onDeserialize) {
        data = onDeserialize(data, instance);
    }

    if (!isSerializableProperty && !predicate) {
        result = castSimpleData(propertyType.name, data);
    } else if (isArray) {
        const array = [];

        if (!Array.isArray(data)) {
            console.error(`${data} is not and array.`);
            result = undefined;
        } else {
            data.forEach((d: any) => {
                if (predicate) {
                    propertyType = predicate(d);
                }
                array.push(deserialize(d, propertyType));
            });
            result = array;
        }
    } else {
        propertyType = predicate ? predicate(data) : propertyType;
        result = deserialize(data, propertyType);
    }

    if (postDeserialize) {
        result = postDeserialize(result, instance);
    }

    return result;
}

/**
 * Function to test if a class is serializable
 *
 * @param {any} type The type to test
 * @returns {boolean} If the type is serializable or not
 */
function isSerializable(type: any): boolean {
    return Reflect.hasOwnMetadata(apiMapSerializable, type);
}

/**
 * Function to transform the JsonProperty value into Metadata
 *
 * @param {string} key The property name
 * @param {Args} args Arguments to describe the property
 * @returns {Metadata} The metadata object
 */
function getJsonPropertyValue(key: string, args: Args): Metadata {
    if (!args) {
        return {
            name: key.toString(),
            type: undefined,
            onDeserialize: undefined,
            onSerialize: undefined,
            postDeserialize: undefined
        };
    }

    let metadata: any;
    if (typeof args === Type.String) {
        metadata = { name: args };
    }
    else if (args['name']) {
        metadata = { name: args['name'] };
    }
    else if (args?.['names'].length) {
        metadata = { names: args['names'] };
    }
    else {
        metadata = { name: key.toString() };
    }

    return args['predicate']
        ? {
              ...metadata,
              predicate: args['predicate'],
              onDeserialize: args['onDeserialize'],
              onSerialize: args['onSerialize'],
              postDeserialize: args['postDeserialize']
          }
        : {
              ...metadata,
              type: args['type'],
              onDeserialize: args['onDeserialize'],
              onSerialize: args['onSerialize'],
              postDeserialize: args['postDeserialize']
          };
}

/**
 * Function to cast simple type data into the real class property type
 *
 * @param {string} type The type to cast data into
 * @param {any} data The data to cast
 * @returns {any} The casted data
 */
function castSimpleData(type: string, data: any): any {
    type = type.toLowerCase();

    if ((typeof data).toLowerCase() === type) {
        return data;
    }

    switch (type) {
        case Type.String:
            return data ? data.toString() : data;
        case Type.Number:
            if (isNaN(data)) {
                console.error(`${data}: Type ${typeof data} is not assignable to type ${type}.`);
                return undefined;
            }
            return Number(data);
        case Type.Boolean:
            console.error(`${data}: Type ${typeof data} is not assignable to type ${type}.`);
            return undefined;
        case Type.Date:
            if (isNaN(Date.parse(data))) {
                console.error(`${data}: Type ${typeof data} is not assignable to type ${type}.`);
                return undefined;
            }
            return new Date(data);
        default:
            return data;
    }
}

/**
 * Add metadata to classes indicating which experimental feature is required for them
 *
 * @param {ExperimentalFeature} feature expected feature enabled
 */
export function ExperimentalComponent(feature: ExperimentalFeature): ClassDecorator {
    return function (target: Function) {
        Reflect.defineMetadata('experimental:component', feature, target);
    };
}

