import { IWorkerViewModel } from 'Api/Dto/Admin/ViewModels/Workers/WorkerViewModel';
import { IAgent, IService, ITask, IWorker, IWorkerDetail, WorkerTaskStatus } from 'Api/Dto/Admin/Worker';
import { RangeException } from 'Api/Dto/Exception';
import { IPaginationResult, IQueryResultBase, QueryException } from 'Api/Dto/QueryResult';
import { HttpClient } from 'Api/HttpClient';
import { IResponseHandler } from 'Api/Infrastructure/Interfaces';
import { Routes } from 'Api/Routes';
import { injectTypes } from 'App/injectTypes';
import { inject, injectable } from 'inversify';
import { String } from 'typescript-string-operations';

@injectable()
export class WorkerService {
    constructor(
        @inject(HttpClient) httpClient: HttpClient,
        @inject(injectTypes.IResponseHandler) responseHandler: IResponseHandler
    ) {
        this._httpClient = httpClient;
        this._responseHandler = responseHandler;;
    }

    public async getWorkersAsync(search: string, page: number, pageSize: number): Promise<IPaginationResult<IWorker>> {
        const response = await this._httpClient.getAsync(Routes.Api.Workers.Base, null, {
            search,
            page,
            pageSize
        });

        const jsonResponse = await response.json();

        if (response.status != 200) {
            throw new QueryException((jsonResponse as IQueryResultBase).error);
        }

        return jsonResponse as IPaginationResult<IWorker>;
    }

    public async getAgentsAsync(search: string, page: number, pageSize: number): Promise<IPaginationResult<IAgent>> {
        const response = await this._httpClient.getAsync(Routes.Api.Agents.Base, null, {
            search,
            page,
            pageSize
        });

        const jsonResponse = await response.json();

        if (response.status != 200) {
            throw new QueryException((jsonResponse as IQueryResultBase).error);
        }

        return jsonResponse as IPaginationResult<IAgent>;
    }

    public async getWorkerAsync(workerId: number): Promise<IWorkerDetail> {
        const url = String.Format(Routes.Api.Workers.Worker, workerId);
        const response = await this._httpClient.getAsync(url);

        const queryResult = await response.json();

        if (response.status != 200) {
            throw new QueryException((queryResult as IQueryResultBase).error);
        }

        return queryResult as IWorkerDetail;
    }

    public async getWorkerTasksAsync(
        tasksStatus: WorkerTaskStatus[],
        servicesIds: number[],
        page: number,
        pageSize: number,
        orderBy?: string,
        workerId?: number,
        startTime?: Date,
        endTime?: Date,
        fileGuid?: string
    ): Promise<IPaginationResult<ITask>> {
        if (page <= 0) {
            throw new RangeException('page', 1);
        }

        const url = workerId
            ? String.Format(Routes.Api.Workers.Tasks, workerId)
            : Routes.Api.Tasks.Base;

        const response = await this._httpClient.getAsync(
            url,
            null,
            {
                tasksStatus,
                servicesIds,
                fileGuid,
                orderBy,
                page,
                pageSize,
                startTime,
                endTime
            }
        );

        const jsonResponse = await response.json();

        if (response.status != 200) {
            throw new QueryException((jsonResponse as IQueryResultBase).error);
        }

        return jsonResponse as IPaginationResult<ITask>;
    }

    public async getServicesAsync(search: string, page: number, pageSize: number): Promise<IPaginationResult<IService>> {
        const response = await this._httpClient.getAsync(Routes.Api.Services.Base, null, {
            search,
            page,
            pageSize
        });

        const jsonResponse = await response.json();

        if (response.status != 200) {
            throw new QueryException((jsonResponse as IQueryResultBase).error);
        }

        return jsonResponse as IPaginationResult<IService>;
    }

    public async turnOnWorkerAsync(workerId: number): Promise<void> {
        const url = String.Format(Routes.Api.Workers.WorkerState, workerId);
        const response = await this._httpClient.putAsync(url, null);

        if (response.status != 204) {
            let queryError = await response.json() as IQueryResultBase;

            throw new QueryException(queryError.error);
        }
    }

    public async turnOffWorkerAsync(workerId: number): Promise<void> {
        const url = String.Format(Routes.Api.Workers.WorkerState, workerId);
        const response = await this._httpClient.deleteAsync(url, null);

        if (response.status != 204) {
            let queryError = await response.json() as IQueryResultBase;

            throw new QueryException(queryError.error);
        }
    }

    public async enableAllServices(workerId: number): Promise<void> {
        const url = String.Format(Routes.Api.Workers.ServicesState, workerId);
        const response = await this._httpClient.putAsync(url, null);

        if (response.status != 204) {
            let queryError = await response.json() as IQueryResultBase;

            throw new QueryException(queryError.error);
        }
    }

    public async disableAllServices(workerId: number): Promise<void> {
        const url = String.Format(Routes.Api.Workers.ServicesState, workerId);
        const response = await this._httpClient.deleteAsync(url, null);

        if (response.status != 204) {
            let queryError = await response.json() as IQueryResultBase;

            throw new QueryException(queryError.error);
        }
    }

    public async enableService(workerId: number, serviceId: number): Promise<void> {
        const url = String.Format(Routes.Api.Workers.ServiceState, workerId, serviceId);
        const response = await this._httpClient.putAsync(url, null);

        if (response.status != 204) {
            let queryError = await response.json() as IQueryResultBase;

            throw new QueryException(queryError.error);
        }
    }

    public async disableService(workerId: number, serviceId: number): Promise<void> {
        const url = String.Format(Routes.Api.Workers.ServiceState, workerId, serviceId);
        const response = await this._httpClient.deleteAsync(url, null);

        if (response.status != 204) {
            let queryError = await response.json() as IQueryResultBase;

            throw new QueryException(queryError.error);
        }
    }

    public async restartTaskAsync(taskId: number): Promise<void> {
        const url = String.Format(Routes.Api.Tasks.Restart, taskId);
        const response = await this._httpClient.putAsync(url, null);

        if (response.status != 204) {
            let queryError = await response.json() as IQueryResultBase;

            throw new QueryException(queryError.error);
        }
    }

    public async createWorkerAsync(addWorkerViewModel: IWorkerViewModel): Promise<IWorker> {
        const url = Routes.Api.Workers.Base;
        const response = await this._httpClient.postAsync(url, addWorkerViewModel);
        const jsonResponse = await response.json();

        if (response.status == 400) {
            const queryError = jsonResponse as IQueryResultBase;
            throw new QueryException(queryError.error);
        }

        return jsonResponse as IWorker;
    }

    public async deleteAgentAsync(agentId: number): Promise<void> {
        const endpoint: string = String.Format(Routes.Api.Agents.Agent, agentId);

        const response: Response = await this._httpClient.deleteAsync(endpoint, null);

        await this._responseHandler.handleErrorsAsync(response);
    }

    protected readonly _responseHandler: IResponseHandler;
    protected readonly _httpClient: HttpClient;
}
