import UIElement, { Prop } from 'Framework/Components/UIElement';
import Component from 'vue-class-component';
import { editorBrowsable, PropSyncWithInternal, JsonProperty } from 'Framework/Components/Panel.Commons';

@Component({ name: 'x-media-player' })
export default class XMediaPlayer extends UIElement {
    @JsonProperty({ className: 'x-media-player', name: 'showControls', type: Boolean })
    @editorBrowsable({ groupName: 'Commons', template: 'Boolean' })
    @PropSyncWithInternal('showControls', { type: Boolean, required: false, default: false })
    public _showControls: boolean;

    @JsonProperty({ className: 'x-media-player', name: 'source', type: String })
    @editorBrowsable({ groupName: 'Commons' })
    @PropSyncWithInternal('source', { type: String, required: false, default: null })
    public _source: string;

    @JsonProperty({ className: 'x-media-player', name: 'preview', type: String })
    @editorBrowsable({ groupName: 'Commons' })
    @PropSyncWithInternal('preview', { type: String, required: false, default: null })
    public _preview: string;

    @JsonProperty({ className: 'x-media-player', name: 'autoPlay', type: Boolean })
    @editorBrowsable({ groupName: 'Commons', template: 'Boolean' })
    @PropSyncWithInternal('autoPlay', { type: Boolean, required: false, default: true })
    public _autoPlay: boolean;

    @JsonProperty({ className: 'x-media-player', name: 'muted', type: Boolean })
    @editorBrowsable({ groupName: 'Commons', template: 'Boolean' })
    @PropSyncWithInternal('muted', { type: Boolean, required: false, default: true })
    public _muted: boolean;
}
