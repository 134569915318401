import { IQueryParameters } from 'Api/Contracts/Dtos/QueryParameters';
import { JsonSchemaDto, JsonSchemaInputDto } from 'Api/Contracts/Dtos/SmartObject';
import { IJsonSchemaService } from 'Api/Contracts/Interfaces';
import { IPaginationResult } from 'Api/Dto/QueryResult';
import { HttpClient } from 'Api/HttpClient';
import { IResponseHandler } from 'Api/Infrastructure/Interfaces';
import { Routes } from 'Api/Routes';
import { injectTypes } from 'App/injectTypes';
import { inject, injectable } from 'inversify';
import { String } from 'typescript-string-operations';

@injectable()
export class JsonSchemaService implements IJsonSchemaService {
    public constructor(
        @inject(HttpClient) httpClient: HttpClient,
        @inject(injectTypes.IResponseHandler) responseHandler: IResponseHandler
    ) {
        this._httpClient = httpClient;
        this._responseHandler = responseHandler;
    }

    public async getJsonSchemaAsync(jsonSchemaId: number): Promise<JsonSchemaDto> {
        const endpoint: string = String.Format(
            Routes.Api.JsonSchema.JsonSchema,
            jsonSchemaId);

        const response: Response = await this._httpClient.getAsync(endpoint);

        return await this._responseHandler
            .handleResponseAsync<JsonSchemaDto>(response);
    }

    public async getJsonSchemasAsync(queryParameters: IQueryParameters): Promise<IPaginationResult<JsonSchemaDto>> {
        const response: Response = await this._httpClient
            .getAsync(
                Routes.Api.JsonSchema.Base,
                null,
                queryParameters);

        return await this._responseHandler
            .handleResponseAsync<IPaginationResult<JsonSchemaDto>>(response);
    }

    public async createJsonSchemaAsync(inputSchema: JsonSchemaInputDto): Promise<JsonSchemaDto> {
        const response: Response = await this._httpClient
            .postAsync(
                Routes.Api.JsonSchema.Base,
                inputSchema);

        return await this._responseHandler
            .handleResponseAsync<JsonSchemaDto>(response);
    }

    public async updateJsonSchemaAsync(jsonSchemaId: number, inputSchema: JsonSchemaInputDto): Promise<void> {
        const endpoint: string = String.Format(
            Routes.Api.JsonSchema.JsonSchema,
            jsonSchemaId);

        const response: Response = await this._httpClient.putAsync(endpoint, inputSchema);

        await this._responseHandler.handleErrorsAsync(response);
    }

    public async deleteJsonSchemaAsync(jsonSchemaId: number): Promise<void> {
        const endpoint: string = String.Format(
            Routes.Api.JsonSchema.JsonSchema,
            jsonSchemaId);

        const response: Response = await this._httpClient.deleteAsync(endpoint, null);

        await this._responseHandler.handleErrorsAsync(response);
    }

    private readonly _httpClient: HttpClient;
    private readonly _responseHandler: IResponseHandler;
}
