import { IDialogBehavior } from 'Framework/Components/ModalDialog/DialogBehavior';
import { DialogResult, ModalDialog } from 'Framework/Components/ModalDialog/ModalDialog';
import { VueClass } from 'vue-class-component/lib/declarations';
import { Component, Mixins, Provide } from 'vue-property-decorator';

export interface IBaseDialogBehavior extends IDialogBehavior {
    onClose: (result?: DialogResult) => void;
    onMaximize: () => void;
    onRestore: () => void;
    canConfirm: () => boolean;
}

@Component
export class BaseDialogBehavior<T = any> extends ModalDialog<T> implements IDialogBehavior {
    public async beforeOkAsync(): Promise<boolean> {
        return true;
    }

    public async beforeCancelAsync(): Promise<boolean> {
        return true;
    }

    public async beforeYesAsync(): Promise<boolean> {
        return true;
    }

    public async beforeNoAsync(): Promise<boolean> {
        return true;
    }

    public async beforeCloseAsync(): Promise<boolean> {
        return true;
    }

    public canConfirm(): boolean {
        return true;
    }

    @Provide()
    private readonly dialogBehavior: IBaseDialogBehavior = {
        beforeCancelAsync: this.beforeCancelAsync,
        beforeCloseAsync: this.beforeCloseAsync,
        beforeNoAsync: this.beforeNoAsync,
        beforeOkAsync: this.beforeOkAsync,
        beforeYesAsync: this.beforeYesAsync,
        canConfirm: this.canConfirm,
        onClose: this.onClose,
        onMaximize: this.onMaximize,
        onRestore: this.onRestore
    };
}

export function BaseDialogBehaviorMixin<T = any>(): VueClass<BaseDialogBehavior<T>> {
    return Mixins<BaseDialogBehavior<T>>(BaseDialogBehavior);
}
