export class EnumHelper {
    public static hasFlag(enumToVerify: number, flagMask: number): boolean {
        return (enumToVerify & flagMask) == flagMask;
    }

    public static hasOneOfFlags(enumToVerify: number, flagMasks: number): boolean {
        return (enumToVerify & flagMasks) > 0;
    }

    public static enumKeysOfType<T>(e: T): (keyof T)[] {
        return Object.keys(e).filter(key => Number.isNaN(Number(key))) as (keyof T)[];
    }

    public static extractFlags<TEnum>(enumType: TEnum, value: number): Array<number> {
        return EnumHelper.getValues(enumType, false)
            .filter(flag => EnumHelper.hasFlag(value, flag));
    }

    public static mergeFlags(values: Array<number>): number {
        return values.reduce((p, c) => c | p, 0);
    }

    public static getValues<TEnum>(enumType: TEnum, includeZeroValue = true): Array<number> {
        return Object.values(enumType).filter(value => !isNaN(value) && (includeZeroValue || value != 0));
    }
}
