import { AppContext } from 'Api/AppContext';
import { getPreviewUrl } from 'App/Helpers/AssetHelper';
import { injectTypes } from 'App/injectTypes';
import { lazyInject } from 'App/Inversify.config';
import { IVisonUriService } from 'App/Services/UriServices/Core';
import { Component, Prop, UIElement } from 'Framework/Components/UIElement';

@Component({
    name: 'x-panorama-preview'
})
export default class PanoramaPreview extends UIElement {
    public get formatUrl(): string {
        return getPreviewUrl(this._appContext.codeCulture, this.guid);
    }

    @Prop()
    public name: string;

    @Prop()
    public guid: string;

    @Prop()
    public isSelected: boolean;

    @lazyInject(AppContext)
    private _appContext: AppContext;

    @lazyInject(injectTypes.IVisonUriService)
    public uriService: IVisonUriService;
}
