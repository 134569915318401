import { AppContext } from 'Api/AppContext';
import { IAbsoluteUriBehavior } from 'Api/Contracts/Interfaces';
import { IVisonUriService } from 'App/Services/UriServices/Core';
import { UriServiceReactive } from 'App/Services/UriServices/UriServiceReactive';
import { injectable } from 'inversify';
import { String } from 'typescript-string-operations';
import urlJoin from 'url-join';

@injectable()
export class VisonUriService extends UriServiceReactive implements IVisonUriService {
    public constructor(appContext: AppContext, baseUri: string | (() => string), behavior: IAbsoluteUriBehavior) {
        super(baseUri, behavior);
        this._appContext = appContext;
    }

    public getWebUri(uriSchema: string, organization?: string, ...parameters: Array<string | number>): string {
        return this._getRelativeUri(uriSchema, organization, null, ...parameters);
    }

    public getApiUri(uriSchema: string, organization?: string, ...parameters: Array<string | number>): string {
        return this._getRelativeUri(uriSchema, organization, '', ...parameters);
    }

    private _getRelativeUri(uriSchema: string, organization?: string, codeCulture?: string, ...parameters: Array<string | number>): string {
        let uri = parameters.length > 0
            ? String.Format(uriSchema, parameters)
            : uriSchema;

        codeCulture ??= this._appContext.codeCulture;
        uri = urlJoin(codeCulture, uri);

        if (!this._appContext.isDedicatedPlatform) {
            organization ??= this._appContext.organizationName;

            return urlJoin(organization, uri);
        }

        return uri;
    }

    private readonly _appContext: AppContext;
}
